import { forwardRef, useEffect, useState } from 'react';

import Tooltip from '../Tooltip';
import Typography, { TypographyVariant } from '../Typography';

import { Styles, StyledAvatarGroup, OverflowCountWrapperDiv } from './AvatarGroup.theme';
import { AvatarGroupProps, Spacing } from './AvatarGroup.types';

const resolveAvatars = (data: Array<any>, max: number) => {
  const sliceCount = data.length > max ? max - 1 : max;

  return {
    inRange: data?.slice(0, sliceCount),
    overflow: data?.slice(sliceCount),
  };
};

const AvatarGroupComponent = forwardRef<HTMLDivElement, AvatarGroupProps>((props, ref) => {
  const {
    id, className, style,
    max = 5, spacing = 8,
    data, item, getAvatarNode,
    overflowCountStyle,
    overflowCountHidden,
    overflowCountHasTooltip, getOverflowCountTolltipContent,
  } = props;
  const [resolvedAvatars, setResolvedAvatars] = useState(resolveAvatars(data, max));

  useEffect(() => {
    setResolvedAvatars(resolveAvatars(data, max))
  }, [data, max]);

  const overflowItems = resolvedAvatars.overflow;
  const overflowItemsAvailable = overflowItems.length > 0;
  const mergedClassName =  { ...Styles.avatarGroup, ...className };

  const shouldShowOverflowTooltip = overflowCountHasTooltip && getOverflowCountTolltipContent;
  const OverflowCountNode = (
    <OverflowCountWrapperDiv
      id={`${id}-OverflowCount`}
      overflowCountHidden={overflowCountHidden}
      dataLength={data?.length | 0}
      style={overflowCountStyle}
    >
      <Typography.H6 style={{ fontSize: '12px' }} variant={TypographyVariant.light}>+{overflowItems.length}</Typography.H6>
    </OverflowCountWrapperDiv>
  );

  return (
    <StyledAvatarGroup
      id={id}
      ref={ref}
      avatarGroupClass={mergedClassName}
      style={style}
      max={max}
      spacing={spacing}
    >
      {resolvedAvatars.inRange.map((nodeData, index) => getAvatarNode(nodeData, { zIndex: index + 1 }, item) )}

      {/* Showing the overflow count without tooltip */}
      {!overflowCountHidden && overflowItemsAvailable && !shouldShowOverflowTooltip && OverflowCountNode}

      {/* Showing the overflow count with tooltip */}
      {!overflowCountHidden && overflowItemsAvailable && overflowCountHasTooltip && getOverflowCountTolltipContent && (
        <Tooltip
          id={`${id}-OverflowCount-Tooltip-Wrapper`}
          placement="bottom"
          content={getOverflowCountTolltipContent(resolvedAvatars.overflow)}
          arrow
        >
          {OverflowCountNode}
        </Tooltip>
      )}
    </StyledAvatarGroup>
  );
});

AvatarGroupComponent.defaultProps = {
  className: undefined,
  style: undefined,
  max: 5,
  spacing: 8,
  item: undefined,
};

export type AvatarGroupSpacing = Spacing;
export default AvatarGroupComponent;
