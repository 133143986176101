import { Modal, styled } from "@mui/material";

export const StyledModal = styled(Modal)({
  Padding: 16,
})

export const StyledModalContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const StyledTitle = styled('div')({
  marginBottom: 24,
});

export const StyledFooter = styled('div')({
  textAlign: 'right',
  marginTop: 30,
});

export const StyledProceedButton = {
  backgroundColor: 'rgba(21, 139, 234, 1)',

  '&:hover': {
    backgroundColor: 'rgba(2, 87, 215, 1)',
  },
  '&:active': {
    backgroundColor: 'rgba(0, 64, 159, 1)',
  },
  '&:focus': {
    backgroundColor: 'rgba(0, 64, 159, 1)',
  },
  '&:disabled': {
    backgroundColor: 'rgba(185, 220, 249, 1) !important',
    color: 'rgba(255, 255, 255, 0.5)',
  },
};
