import React, { useCallback, useState, useEffect } from 'react';
import debounce from 'debounce';

import CallHistoryRowLoading from '../Private/CallHistoryRowLoading';
import CallHistoryRow, { CallHistoryRowTheme } from '../Private/CallHistoryRow';

import { CallHistoryInfiniteListProps } from './CallHistoryInfiniteList.types';
import { CallHistoryInfiniteListDiv } from './CallHistoryInfiniteList.theme';

const DefaultPlaceholderItemsCount = 3;

const CallHistoryInfiniteListComponent: React.FC<CallHistoryInfiniteListProps<any, any>> = ({
  id,
  data, dataLoading,
  bottomHitThreshold, onNextPageRequested,
  getId, getAvatarUrl, getAvatarFallbackLabel, isFailedCall,
  getCallDirection, getCallMedium, getDate, getTime, getDuration,
  getAvatarClassName, onCall, onHistoryRowClick, onAvatarClick,
  getActions, getActionNode, showActionsOverflowTooltip, getActionsOverflowCountTooltipContent,
  getNavigationNode, menuListStyle, getMenuItems, getCallStatusBadgeTooltip, isUnwrapped,

  // optional props
  style = undefined,
  showNavigationNode = false,
  showCallActionButton = false,
  showNavNodeOnHover = false,
  placeholderCount = DefaultPlaceholderItemsCount,
  miniVersion = false
}) => {

  const placeholderItemsCount = placeholderCount || DefaultPlaceholderItemsCount;

  const scrollResolver = useCallback(debounce((event:any) => {
    const { target } = event;
    const bottomHit = target.scrollHeight - target.scrollTop - (bottomHitThreshold || DefaultPlaceholderItemsCount) <= target.clientHeight;
    // Can't add `hasMoreData` logic in here because the scroll resolver gets attached only once

    if (bottomHit) onNextPageRequested();
  }, 400), []);
  
  const scrollHandler = useCallback((event:any) => {
    scrollResolver(event);
  }, []);

  const [scrollContainerRef, setScrollContainerRef] = useState<HTMLDivElement | null>(null);
  const handleScrollContainerRef = useCallback((ref: HTMLDivElement) => {
    if (ref) {
      setScrollContainerRef(ref);
      ref.addEventListener('scroll', scrollHandler);
    }
  }, []);

  useEffect(() => {
    return () => scrollContainerRef?.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <CallHistoryInfiniteListDiv id={id} key={id} ref={handleScrollContainerRef} style={style}>
      {/* Rendering the items count */}
      {data.map((item, index) => (
        <CallHistoryRow
          id={`${id}-Row-${getId(item)}`}
          key={`${id}-Row-${getId(item)}`}
          style={style}
          avatarUrl={getAvatarUrl(item)}
          avatarFallbackLabel={getAvatarFallbackLabel(item)}
          avatarClassName={getAvatarClassName?.(item)}
          callFailed={isFailedCall(item)}
          callUnwrapped={isUnwrapped(item)}
          callDirection={getCallDirection(item)}
          callMedium={getCallMedium(item)}
          date={getDate(item)}
          time={getTime(item)}
          duration={getDuration(item)}
          actions={getActions(item)}
          getActionNode={getActionNode}
          showActionsOverflowTooltip={showActionsOverflowTooltip}
          getActionsOverflowCountTooltipContent={getActionsOverflowCountTooltipContent}
          showNavigationNode={showNavigationNode}
          showCallActionButton={showCallActionButton}
          showNavNodeOnHover={showNavNodeOnHover}
          navigationNode={getNavigationNode?.(item)}
          theme={index % 2 === 0 ? CallHistoryRowTheme.normal : CallHistoryRowTheme.inverse}
          miniVersion={miniVersion}
          item={item}
          menuListStyle={menuListStyle}
          getMenuItems={getMenuItems}
          onCall={onCall}
          onHistoryRowClick={onHistoryRowClick}
          onAvatarClick={onAvatarClick}
          getCallStatusBadgeTooltip={getCallStatusBadgeTooltip}
        />
      ))}

      {/* TODO: Not sure how to make this responsive between */}
      {dataLoading && Array.from(Array(placeholderItemsCount).keys()).map((item, index) => (
        <CallHistoryRowLoading
          id={`${id}-Row-Placeholder-${index}`}
          key={`${id}-Row-Placeholder-${index}`}
          style={style}
          showNavigationNode={showNavigationNode}
          showCallActionButton={showCallActionButton}
          navigationNode={getNavigationNode?.(item)}
          theme={(data.length + index) % 2 === 0 ? CallHistoryRowTheme.normal : CallHistoryRowTheme.inverse}
          miniVersion={miniVersion}
        />
      ))}
    </CallHistoryInfiniteListDiv>
  );
};

export default CallHistoryInfiniteListComponent;
