import { styled } from '@mui/material/styles';

import Avatar, { AvatarVariant, AvatarSize } from "../../../Avatar";
import Buttons, {
  CallButtonProps,
  CallButtonTheme,
  CallIconButtonSize,
} from "../../private/Buttons";
import { CallState } from '../../../../Enums/Call.types';
import Colours from '../../../../Theme/Colours';

interface CallControlButtonProps extends CallButtonProps {
  theme?: CallButtonTheme;
  size?: CallIconButtonSize;
}

const ContactViewContainer =  styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ContactAvatar =  styled(Avatar)({
  width: 54,
  height: 54,
  marginTop: 36,
});
const ContactNameContainer =  styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: 18,
});

const CallTimeContainerCommon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: 15,
});

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginTop: 48,
  justifyContent: 'center',
});

const CallControlButtons = styled(Buttons.CallControlButton, {
  shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'size',
})<CallControlButtonProps>(({ theme: buttonTheme = CallButtonTheme.default, size = CallIconButtonSize.large }) => ({
  marginLeft: 20,
}));

const useStyles = {
  default: {},
  // contactViewContainer: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // },
  // contactAvatar: {
  //   width: 54,
  //   height: 54,
  //   marginTop: 36,
  // },
  avatarFallbackLabel: {
    fontSize: 20,
  },
  // contactName: {
  //   maxWidth: 228,
  // },
  // contactNameContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   marginTop: 18,
  // },
  // callTimeContainerCommon: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   marginTop: 15,
  // },
  // callStateIndicator: {
  //   fontSize: 12,
  // },
  // timeText: {
  //   marginLeft: 8,
  // },
  // buttonContainer: {
  //   width: '100%',
  //   display: 'flex',
  //   alignItems: 'center',
  //   marginTop: 48,
  //   justifyContent: 'center',
  // },
  // callControlButtons: {
  //   marginLeft: 20,
  // },
};

const CallTimeIconColourMap = {
  [CallState.dialing]: Colours.Backgrounds.tooltip,
  [CallState.connecting]: Colours.Backgrounds.tooltip,
  [CallState.ringing]: Colours.Backgrounds.tooltip,
  [CallState.answered]: Colours.PrimaryB.normal,
  [CallState.ended]: Colours.Backgrounds.tooltip,
  [CallState.disconnected]: Colours.Backgrounds.tooltip,
};

export default { useStyles, ContactViewContainer, ContactAvatar, ContactNameContainer,
  CallTimeContainerCommon, ButtonContainer, CallControlButtons, CallTimeIconColourMap };
