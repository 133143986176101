import { styled } from '@mui/material';
import Card from '@mui/material/Card';

import DiallerButton from '../../NavigationDialler/private/DiallerButton';

const StyledCard = styled(Card)({
  minHeight: '384px',
  minWidth: '418px',
  backgroundColor: '#001932',
  boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.24)',
  borderRadius: '24px'
});

const ButtonArea = styled('div')({
  margin: '12px 24px',
});

export const StyledDiallerButton = styled(DiallerButton)({
  margin: '12px',
});

const useStyles = {
  card: {
    minHeight: '384px',
    backgroundColor: '#001932',
    boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.24)',
    borderRadius: '24px'
  },
  numberPadContainer : {
    backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
    borderRadius: 0,
    height: '72px',
    minHeight: 72,
    maxHeight: 100,
    padding: 0,
    minWidth: '418px',
    width: '100%',
  },
  diallerButton: {
    margin: '12px'
  },
  buttonArea: {
    margin: '12px 24px'
  }
};

export default { useStyles, StyledCard, ButtonArea, StyledDiallerButton };
