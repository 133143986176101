import { styled } from '@mui/material';
import Colours from '../../../Theme/Colours';

// import { ScheduledCallCardProps } from './ScheduledCallCard.types';

export const Styles = {
  contact: {
    display: 'flex',
    marginLeft: 12,
  },
  contactNameText: {
    maxWidth: 140,
    display: 'flex',
    padding: '0px !important',
    minHeight: '20px !important',
    height: '0px !important',
    border: 0,
    '&:hover': {
      border: `0px !important`,
    },
    '& > span': {
      fontSize: 14,
      display: 'flex',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      justifyContent: 'flex-start',
    },
  },
  contactCompanyText: {
    maxWidth: 140,
    color: '#001932',
    display: 'flex',
  },
  timeText: {
    color: '#4C4C4C',
  },
  avatar: {
    height: 32,
    width: 32,
    color: 'white !important',
  },
};

export const ContainerDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 9,
  paddingBottom: 9,
  paddingRight: 18,
  paddingLeft: 18,
  height: 36,

  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(240, 248, 255, 1)',
  },
  '&:hover': {
    cursor: 'pointer',
    '& > .scheduledTimeDiv': {
      display: 'none',
    },
    '& > .callButtonDiv': {
      display: 'flex',
    },
  },
});

export const ContactAvtarDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: 'white !important',
});

export const ContactDiv = styled('div')({
  display: 'flex',
  marginLeft: 12,
});

export const ToolTipContainerDiv = styled('div')({
  height: 24,
  '& > span:nth-of-type(1)': {
    height: 24,
    display: 'flex',
  },
});

export const CampaignContainerDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  marginLeft: 6,
  borderRadius: '50%',
  // backgroundColor: (props: ScheduledCallCardProps<any>) => `${props.getCampaignColour(props.data)}33` ?? Colours.PrimaryB.hoverBackground,
  backgroundColor: Colours.PrimaryB.hoverBackground,
  backgroundOpacity: '50%',
});

export const ScheduledTimeDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: 32,
  alignItems: 'center',
});

export const CallButtonDiv = styled('div')({
  display: 'none',
});
