import React, { forwardRef, useCallback, useRef, useEffect, useState } from 'react';
// import classnames from 'classnames';
// import Card from '@mui/material/Card';
// import InputBase from '@mui/material/InputBase';
// import { styled } from '@mui/material/styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDeleteLeft } from '@fortawesome/pro-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonVariant } from '../../../Button';
import Typography, { TypographyVariant } from '../../../Typography';
import FontFamily from '../../../../Theme/FontFamily';
import SaleforceService from "../../../../Services/SaleforceService";

import { NumberPadProps, KeyboardKeys } from './NumberPad.types';
import { StyledTextField, StyledCard, StyledBackspaceIcon,
  StyledIconElement, StyledMainContainer, StyledInputContainer
} from './NumberPad.theme';

// const ThemedTextField = styled(InputBase)(StyledTextField);

const NumberPadCardComponent = forwardRef<HTMLDivElement, NumberPadProps>((props, ref) => {
  const {
    id,
    className,
    style,
    number,
    onBackspaceClick,
    callbackValue,
    showBackSpace,
    onNumberChange,
    disableFocus,
    onEscape,
    contactName,
    contactCount = 0,
    showContactName,
    onCursorPositionChange,
  } = props;

  const inputRef: React.MutableRefObject<any> = useRef(null);

  const [escClickedOnce, setEscClickedOnce] = useState(false);

  useEffect(() => {
    const handleEsc = (nativeEvent: any) => {
      const event = nativeEvent;
      if (event.key === KeyboardKeys.esc && escClickedOnce) onEscape?.();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [escClickedOnce, onEscape]);

  // const cardClassName = classnames(StyledCard, className);
  // const backSpaceClassName = classnames(StyledBackspaceIcon);
  // const iconClassName = classnames(StyledIconElement);

  const handleClick = useCallback(
    (e: any) => {
      onBackspaceClick?.(callbackValue, e);
    },
    [onBackspaceClick, callbackValue]
  );

  const updateSelectionStart = useCallback(() => {
    onCursorPositionChange?.(inputRef.current.selectionStart);
  }, [onCursorPositionChange]);

  const handleOnKeyDown = useCallback(
    (e: any) => {
      const key = e.key || '';
      const keysToInclude =
        key === KeyboardKeys.plus ||
        key === KeyboardKeys.backSpace ||
        key === KeyboardKeys.hash ||
        key === KeyboardKeys.asterisk ||
        key === KeyboardKeys.arrowLeft ||
        key === KeyboardKeys.arrowRight;

      //Prevent default action, which is inserting character
      if (disableFocus) {
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      } else if (key === KeyboardKeys.esc) {
        if (e.preventDefault) e.preventDefault();
        e.target.blur();
        setEscClickedOnce(true);
      } else if (key === KeyboardKeys.spaceBar) {
        // Not the most elegant way to do it
        if (e.repeat) {
          e.target.value = `${e.target.value}+`;
        } else {
          e.target.value += '0';
        }
      } else if (isNaN(key) && !keysToInclude  && key !== 'v') { // allowing "v" for pasting by using [ctrl + v]
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      }
    },
    [disableFocus]
  );

  const handleOnPaste = (e: any) => {
    const pasteData = e.clipboardData.getData('text');
    const allowedCharacters = /[0-9+#*]/g;
    const numericData = pasteData.match(allowedCharacters)?.join('') || '';
    const hasUnallowedCharacters = /[a-zA-Z,.<>'";:?/{}()=`~!@$%^&\\|_\-[\]]/.test(pasteData);
  
    if (hasUnallowedCharacters || !numericData) {
      e.preventDefault(); // Prevent the paste if it contains non-numeric characters
      return;
    } else {
      setTimeout(() => {
        onCursorPositionChange?.(inputRef.current.selectionStart);
      }, 0);
    }
  };

  const handleOnFocus = useCallback(() => {
    setEscClickedOnce(false);
  }, [setEscClickedOnce]);

  const didPressedMoreContacts = () => {
    if (number) {
      SaleforceService.callingObjectNavigation(number, '', true)
    }
  };
  const backspaceIcon = (
    <StyledBackspaceIcon onClick={handleClick}
    //  className={backSpaceClassName}
    >
      <StyledIconElement icon={faDeleteLeft as IconProp} style={{ color: "#ffffff" }}
      //  className={iconClassName} 
      />
    </StyledBackspaceIcon>
  );
  const numberField = (
    <StyledTextField
      id={`${id}-Number-Field`}
      inputRef={inputRef}
      type="tel"
      value={number}
      fullWidth
      autoFocus
      onChange={onNumberChange}
      onPaste={handleOnPaste}
      onSelect={updateSelectionStart}
      onKeyDown={handleOnKeyDown}
      onFocus={handleOnFocus}
      inputProps={{ autoComplete: 'off' }}
      autoComplete="off"
    />
  );

  const contactNameNode = (
    <div style={{ marginRight: '30px'}}>
      <Typography.P1 id={`${id}-Contact-Name`} variant={TypographyVariant.light}
        style={{ fontWeight: 600, fontSize: 12, fontFamily: FontFamily }}
      // // className={classnames(StyledContactName, {[StyledContactNameWithMargin as any]: contactCount <= 1})}
       >
        {contactName}
      </Typography.P1>
      {contactCount > 1 && (
        <Button
          id={`${id}-Contact-Count`}
          style={{ color: '#FFFFFF', marginRight: 16, fontWeight: 600, fontSize: 12}}
          // // className={StyledContactName}
          variant={ButtonVariant.text}
          onClick={didPressedMoreContacts}
        >
          {`(${contactCount - 1} other contacts)`}
        </Button>
      )}
    </div>
  );

  return (
    <StyledCard id={id} ref={ref}
      className={className} style={style}
    >
      <StyledMainContainer>
        <StyledInputContainer>
          {numberField}
          {showBackSpace && backspaceIcon}
        </StyledInputContainer>
        {(showContactName && !!contactName) && contactNameNode}
      </StyledMainContainer>
    </StyledCard>
  );
});

NumberPadCardComponent.defaultProps = {
  className: undefined,
  numberTextClassName: undefined,
  style: undefined,
  number: undefined,
  onBackspaceClick: () => null,
  callbackValue: undefined,
  showBackSpace: false,
  disableFocus: false,
  onEscape: () => {},
  contactName: '',
  contactCount: 0,
  showContactName: false,
};

export default NumberPadCardComponent;
