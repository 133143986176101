import React from 'react';

import { Size } from '../../../IconButton/IconButton.types';

export interface ButtonsProps {
  id: string;
  children: React.ReactNode;
  className?: string;
  style?: any;
  disabled?: boolean;
  size?: Size;
  theme?: Theme;
  onClick?: (callbackValue?: any, event?: any) => any;
  onMouseDown?: (event?: any) => any;
  onTouchStart?: (event?: any) => any;
  onMouseUp?: (event?: any) => any;
  onMouseLeave?: (event?: any) => any;
  onTouchEnd?: (event?: any) => any;
  callbackValue?: any;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  type?: 'button' | 'reset' | 'submit';
  buttonWidth?: ButtonWidth;
  fullWidth?: boolean;
}

export enum Theme {
  default = 'default',
  defaultAlternative = 'defaultAlternative',
  primary = 'primary',
  secondary = 'secondary',
  secondaryAlternative = 'secondaryAlternative',
  danger = 'danger',
  dark = 'dark',
}

export enum ButtonTypes {
  callControlButton = 'CallControlButton',
  callActionButton = 'CallActionButton',
  diallerCallActionButton = 'DiallerCallActionButton',
}

export enum ButtonWidth {
  full = 'full',
  halfLeft = 'halfLeft',
  halfRight = 'halfRight',
  middle = 'middle',
}
