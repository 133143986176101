export const Styles = {
  historyContainer: {
    minWidth: '200px !important',
    maxWidth: 260,
    '& > div:nth-of-type(1)': {
      height: '100%',
      width: 386,
    },
  },
  summaryHistoryBody: {
    paddingLeft: 0,
  },
  historyFooter: {
    justifyContent: 'center',
  },
  title: {
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 600 
  },
};
