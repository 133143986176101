import { styled } from '@mui/material';
import FontFamily from '../../Theme/FontFamily';

export const Styles = {
  menu: {
    '& > div:nth-of-type(3)': {
      '&::-webkit-scrollbar': {
        background: "transparent",
        width: "6px",
      },
      '&::-webkit-scrollbar-thumb': {
        background: "rgba(0, 0, 0, 0.2)",
        borderRadius: "1.875rem",
      },
    },
  },
  menuItemTitle: {
    opacity: '1 !important',
    paddingLeft: '13px !important',
  },
  listItemIcon: {
    minWidth: '30px !important',
  },
  topNavBar: {
    display: 'flex',
    height: 64,
    backgroundColor: '#001932'
  },
  navContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "4px",
  },
  rpLogo:{
    height: "28px",
    cursor: 'pointer',
  },
  phoneSetupTitle: {
    paddingLeft: '52px !important',
    paddingRight: '16px !important',
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
  },

  userAvatarBadge: {
    '& >span': {
      // backgroundColor: (props: TopNavBarProps) => props.test ? '#2e7d32' : '#bdbdbd',
      backgroundColor: '#2e7d32',
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },

  menuListItem: {
    height: '24px !important',
    maxWidth: 'none !important',
    // paddingLeft: '18px !important',

    '&:hover': {
      backgroundColor: '#e7f3fc !important',
    },
    '&:active': {
      backgroundColor: '#e7f3fc !important',
    }
  },

  listIconWrapper: {
    minWidth: 'unset !important',
  },

  listItemTextWrapper: {
    flex: 'unset !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',

    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  listItemText: {
    maxWidth: 200,
  },
  availabilityStatusIcon: {
    fontSize: '10px',
    marginRight: '14px',
  },

  divertLabel: {
    marginLeft: '6px !important',
    marginRight: '6px !important',
    fontStyle: 'italic',
  },

  checkIcon: {
    color: '#0ba360',
    fontSize: '14px',
    marginLeft: '14px',
    marginRight: '10px',
  },

  firstThemeListItem: {
    paddingTop: '3px !important',
    maxWidth: '100% !important',
    maxHeight: '100% !important',
    height: '100% !important',
  },
  
  listItemContentTextWrapper: {
    paddingLeft: '30px',
    marginTop: '0px !important',
    marginBottom: '0px !important',

    '& > p': {
      color: '#001932'
    },
  },

  dropdownListItemText: {
    color: '#001932',
    fontFamily: FontFamily,
    fontSize: '12px !important',
    lineHeight: '18px !important',
  },

  dropdownListItemPrimaryText: {
  color: '#001932',
  fontWeight: 600,
  fontFamily: FontFamily,
  fontSize: '12px',
  lineHeight: '18px !important',

    '& > span': {
      color: '#6B6B6B',
      fontSize: '12px',
      fontWeight: 400,
      paddingLeft: '6px',
      fontStyle: 'italic',
    },
  },

  dropdownListItemSecondaryText: {
    fontWeight: 600,
  },

  dropdownListItemSpan: {
    color: '#6B6B6B',
    fontFamily: FontFamily,
    fontSize: '12px',
    lineHeight: '21px',
    paddingLeft: '4px',
    fontStyle: 'italic',
    fontWeight: 400,
  },

  orderedList: {
    paddingLeft: 20,
    fontSize: 14,
    fontWeight: 400,
    color: '#001932',
  },
  memberLabel: {
    marginLeft: '8px !important',
    fontStyle: 'italic',
  },

  statusDotWrapper: {
    position: 'absolute',
    zIndex: 2,
    left: 32,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  statusDot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  volumeIcon: {
    color: '#fff',
    fontSize: 6,
    transform: 'rotate(-44deg)',
  },

  listItemTextH5: {
    fontSize: 14,
    fontWeight: 500,
  },
};

export const StyledDropdownListItemPrimaryText = styled('div')({
  color: '#001932',
  fontWeight: 600,
  fontFamily: FontFamily,
  fontSize: '12px',
  lineHeight: '18px !important',

  '& > span': {
    color: '#6B6B6B',
    fontSize: '12px',
    fontWeight: 400,
    paddingLeft: '6px',
    fontStyle: 'italic',
  },
});

export const StyledStatusDotWrapper = styled('span')({
  position: 'absolute',
  zIndex: 2,
  left: 28,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledStatusDot = styled('span')({
  width: 12,
  height: 12,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
