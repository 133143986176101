import React from 'react';
import { BadgeOrigin } from '@mui/material/Badge';

export interface BadgeProps {
  id: string;
  classes?: string;
  className?: string;
  style?: React.CSSProperties;
  theme?: Theme;
  content?: React.ReactNode;
  children: React.ReactNode;
  max?: number;
  invisible?: boolean;
  placement?: Placement;
};

export enum Theme {
  default = "default",
  primary = "primary",
  action = "action",
  danger = "danger",
};

export const ThemeMuiColourMap = {
  [Theme.default]: 'default',
  [Theme.primary]: 'primary',
  [Theme.action]: 'secondary',
  [Theme.danger]: 'error',
};

export enum Placement {
  default = 'default',
  topRight = 'top-right',
  bottomRight = 'bottom-right',
  bottomLeft = 'bottom-left',
  topLeft = 'top-left',
};

export interface PlacementMuiAnchorOriginMap {
  [Placement.default]: BadgeOrigin,
  [Placement.topRight]: BadgeOrigin,
  [Placement.bottomRight]: BadgeOrigin,
  [Placement.bottomLeft]: BadgeOrigin,
  [Placement.topLeft]: BadgeOrigin,
};
