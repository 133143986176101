import { Paper } from '@mui/material';

import { hideAlertModal } from '../../../slices/alertModal';
import { updateCallBarState } from '../../../slices/callCentre';
import { CallBarState } from '../../../constants/CallBarState';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Typography, { TypographyVariant } from '../../Typography';
import Button, { ButtonTheme, ButtonVariant } from '../../Button';

import { StyledModal, StyledModalContainer, StyledTitle, StyledFooter, StyledProceedButton } from './AlertModal.theme';

const AlertModal = () => {
  const dispatch = useAppDispatch();
  const alertModalData = useAppSelector((state: any) => state.alertModal);

  const handleClose = () => {
    dispatch(hideAlertModal());
  };

  const handleConfirmButtonClick = () => {
    dispatch(hideAlertModal());
    dispatch(updateCallBarState(CallBarState.WRAP_UP))
  };

  return (
    <StyledModal sx={StyledModalContainer} open={alertModalData?.open} onClose={handleClose}>
      <Paper sx={{ padding: '14px', margin: '12px', borderRadius: '18px !important' }}>
        <StyledTitle><Typography.H2 style={{ fontSize: 18, fontWeight: 500 }} variant={TypographyVariant.title}>{alertModalData.modalData.title}</Typography.H2></StyledTitle>
        <div><Typography.P1 style={{ fontSize: 14 }} variant={TypographyVariant.title}>{alertModalData.modalData.primaryText}</Typography.P1></div>
        <br />
        <StyledFooter>
          <Button id="Back-Button" theme={ButtonTheme.primary} variant={ButtonVariant.text} onClick={handleClose} disabled={false}>
            <Typography.H4 style={{ fontSize: 18, fontWeight: 500 }} variant={TypographyVariant.primary}>{alertModalData.modalData.cancelButtonText}</Typography.H4>
          </Button>
          {!!(alertModalData.modalData.confirmButtonText) && (
            <Button
              id="Proceed-Button"
              theme={ButtonTheme.primary}
              variant={ButtonVariant.normal}
              className={StyledProceedButton}
              onClick={handleConfirmButtonClick}
              disabled={false}
            >
              <Typography.H4 style={{ fontSize: 18, fontWeight: 500 }} variant={TypographyVariant.light}>{alertModalData.modalData.confirmButtonText}</Typography.H4>
            </Button>
          )}
        </StyledFooter>
      </Paper>
    </StyledModal>
  );
};

export default AlertModal;
