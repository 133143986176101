import React, { forwardRef, useCallback } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faHashtag } from '@fortawesome/pro-regular-svg-icons';

// import DiallerButton from '../DiallerButton';
import { LongPressHooks } from '../../../../Hooks';

import { DialpadProps } from './Dialpad.types';
import { DialpadContainer, ButtonsContainer, StyledDiallerButton } from './Dialpad.theme';

const itemPlaceholders = Array.from(new Array(9));

const defaultOptions = {
  shouldPreventDefault: true,
  delay: 500,
};

const DialpadComponent = forwardRef<HTMLDivElement, DialpadProps>((props, ref) => {
  const { id, onNumberChange, dialpadButtonDisabled} = props;

  // const classes = DialpadThemer.useStyles;

  const handleNumberChange = useCallback(
    (value: any, e: any) => {
      e.persist();
      onNumberChange?.(value, e);
    },
    [onNumberChange]
  );

  const longPressEvent = LongPressHooks.useLongPress(handleNumberChange, handleNumberChange, defaultOptions, '0', '+');

  return (
    <DialpadContainer ref={ref}
    //  className={classes.dialpadContainer as any}
    >
      <ButtonsContainer
      //  className={classes.buttonsContainer as any}
      >
        {itemPlaceholders.map((item, index) => (
          <StyledDiallerButton
            id={`${id}-DialButton-${index + 1}`}
            key={`${id}-DialButton-${index + 1}`}
            // className={classes.diallerButton as any}
            callbackValue={`${index + 1}`}
            onClick={handleNumberChange}
            isDisabled={dialpadButtonDisabled}
          >
            {index + 1}
          </StyledDiallerButton>
        ))}
        <StyledDiallerButton id={`${id}-DialButton-*`}
        //  className={classes.diallerButton as any}
         callbackValue="*" onClick={handleNumberChange} isDisabled={dialpadButtonDisabled}>
          <FontAwesomeIcon icon={faAsterisk as IconProp} />
        </StyledDiallerButton>
        <StyledDiallerButton id={`${id}-DialButton-0`} isSubscript
        //  className={classes.diallerButton as any}
          callbackValue="0" {...longPressEvent} isDisabled={dialpadButtonDisabled}>
          0
        </StyledDiallerButton>
        <StyledDiallerButton id={`${id}-DialButton-#`}
        //  className={classes.diallerButton as any} 
         callbackValue="#" onClick={handleNumberChange} isDisabled={dialpadButtonDisabled}>
          <FontAwesomeIcon icon={faHashtag as IconProp} />
        </StyledDiallerButton>
      </ButtonsContainer>
    </DialpadContainer>
  );
});

DialpadComponent.defaultProps = {
  dialpadButtonDisabled: false,
};

export default DialpadComponent;
