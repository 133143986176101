import { styled } from '@mui/material';

import Colours from '../../Theme/Colours'

interface StyledDivProps {
  scheduledCallClass?: React.CSSProperties;
};

export const ContainerDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'scheduledCallClass',
})<StyledDivProps>(({ scheduledCallClass }) => ({
  width: 396,
  backgroundColor: Colours.Backgrounds.white,
  ...scheduledCallClass,
}));