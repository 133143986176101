import { styled } from '@mui/material/styles';

const StyledContactList = styled('div')(({ theme }) => ({
  paddingLeft: '24px',
  maxHeight: '320px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    background: 'transparent',
    width: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '30px'
  }
}));

const StyledContactRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
    paddingLeft: '24px',
    marginLeft: '-24px',
    cursor: 'pointer'
  }
}));

// const useStyles = {
//   contactList: {
//     paddingLeft: '24px',
//     maxHeight: '320px',
//     overflow: 'auto',
//     '&::-webkit-scrollbar': {
//       background: 'transparent',
//       width: '6px'
//     },
//     '&::-webkit-scrollbar-thumb': {
//       background: 'rgba(255, 255, 255, 0.2)',
//       borderRadius: '30px'
//     }
//   },
//   contactRow: {
//     display: 'flex',
//     justifyContent: 'center',
//     '&:hover': {
//       background: 'rgba(255, 255, 255, 0.1)',
//       paddingLeft: '24px',
//       marginLeft: '-24px',
//       cursor: 'pointer'
//     }
//   }
// };

export default { StyledContactList, StyledContactRow };