import { forwardRef } from 'react';
import Avatar from '@mui/material/Avatar';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CallMadeIcon from '@mui/icons-material/CallMade';

import Tooltip from '../Tooltip';
import Colours from '../../Theme/Colours';
import Typography, { TypographyVariant } from '../Typography';

import {
  CallStatusBadgeProps, StatusType,
  Theme, ThemeMuiColourMap,
} from './CallStatusBadge.types';
import { StyledBadge, Styles } from './CallStatusBadge.theme';

const StatusTypeIconMap = {
  [StatusType.inboundMissed]: <CallMissedIcon sx={{ fontSize: '12px' }} />,
  [StatusType.inboundReceived]: <CallReceivedIcon sx={{ fontSize: '12px' }} />,
  [StatusType.outboundMissed]: <CallMissedOutgoingIcon sx={{ fontSize: '12px' }} />,
  [StatusType.outboundReceived]: <CallMadeIcon sx={{ fontSize: '12px' }} />,
};
const StatusTypeThemeMap = {
  [StatusType.inboundMissed]: Theme.danger,
  [StatusType.inboundReceived]: Theme.action,
  [StatusType.outboundMissed]: Theme.danger,
  [StatusType.outboundReceived]: Theme.action,
};

const colourMap = {
  [StatusType.inboundMissed]: Colours.Danger.normal,
  [StatusType.inboundReceived]: Colours.PrimaryB.normal,
  [StatusType.outboundMissed]: Colours.Danger.normal,
  [StatusType.outboundReceived]: Colours.PrimaryB.normal,
};

const CallStatusBadgeComponent = forwardRef<HTMLDivElement, CallStatusBadgeProps>((props, ref) => {
  const { id, classes: muiClasses, className, style, children, statusType, callStatusTooltip } = props;
  const muiColor = ThemeMuiColourMap[StatusTypeThemeMap[statusType]];
  const iconMap = StatusTypeIconMap[statusType];
  const iconNode = (
    <Tooltip
      id={`${id}-CallStatus-Tooltip-Wrapper`}
      content={<Typography.P2 variant={TypographyVariant.light}>{callStatusTooltip}</Typography.P2>}
      placement="bottom"
      arrow
    >
      <Avatar sx={Styles.avatar} style={{ backgroundColor: colourMap[statusType] ?? Colours.PrimaryB.normal }}>
        {iconMap}
      </Avatar>
    </Tooltip>
    
  );
  const iconNodeWithoutTooltip = (
    <Avatar sx={Styles.avatar} style={{ backgroundColor: colourMap[statusType] ?? Colours.PrimaryB.normal }}>
      {iconMap}
    </Avatar>
  );

  return (
    <StyledBadge
      id={id}
      ref={ref}
      badgeClassName={className}
      style={style}
      overlap="circular"
      badgeContent={!callStatusTooltip ? iconNodeWithoutTooltip : iconNode}
      color={muiColor as any}
      classes={muiClasses}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </StyledBadge>
  );
});

CallStatusBadgeComponent.defaultProps = {
  classes: undefined,
  className: undefined,
  style: undefined,
  callStatusTooltip: undefined,
};

export const CallStatusBadgeStatusType = StatusType;
export default CallStatusBadgeComponent;