import { styled } from '@mui/material';

import { Size } from './CallActionAvatar.types';

interface CallActionAvatarDivProps {
  callActionAvatarClass?: any;
  avatarSize?: Size;
};

const SizeMap = {
  default: 24,
  [Size.small]: 24,
  [Size.medium]: 36,
  [Size.large]: 48,
};

export const Styles = {
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    height: '80%',
    color: '#FFFFFF',
  },
  hotTransferToolTip: {
    display: 'block',
  }
};

export const WrapperDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'callActionAvatarClass' && prop !== 'avatarSize',
})<CallActionAvatarDivProps>(({ callActionAvatarClass, avatarSize }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: SizeMap[avatarSize ?? Size.small],
  height: SizeMap[avatarSize ?? Size.small],
  borderRadius: '50%',
  ...callActionAvatarClass,
}));
