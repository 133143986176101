import React, { forwardRef, useCallback } from 'react';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { PopperProps } from './Popper.types';
import { StyledPopper, StyledMenuList } from './Popper.theme';

const PopperComponent = forwardRef<HTMLDivElement, PopperProps>((props, ref) => {
  const {
    id,
    className, style,
    anchorEl,
    callbackValue,
    open, onClose,
    // placement,
    skipMenuList,
    menuListStyle, menuListClassName,
    menuItems,
    disablePortal,
    transition,
  } = props;

  const handleClose = useCallback(() => {
    onClose(callbackValue);
  }, [callbackValue, onClose])

  // The children of ClickAwayListener must be one valid child
  // Can't be bool, can't be undefined, can't be null
  const content = (skipMenuList && menuItems) ? (menuItems) : (
    <StyledMenuList
      id={`${id}-Menu-List`}
      menuListclass={menuListClassName}
      style={menuListStyle}
      onClick={handleClose}
    >
      {menuItems}
    </StyledMenuList>
  );

  return (
    <StyledPopper
      id={id}
      ref={ref}
      popperClassName={className}
      style={style}
      anchorEl={anchorEl as any}
      open={open}
      onClick={handleClose}
      placement="bottom-end"
      transition={transition}
      disablePortal={disablePortal}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: false,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              {content as React.ReactElement}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </StyledPopper>
  );
});

PopperComponent.defaultProps = {
  callbackValue: undefined,
  skipMenuList: false,
  menuListClassName: undefined,
  menuListStyle: undefined,
  disablePortal: false,
  transition: false,
  placement: 'bottom',
};

export default PopperComponent;
