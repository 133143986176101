import { styled } from "@mui/material";

interface StyledDivProps {
  summaryHeaderClass?: React.CSSProperties;
  summaryBodyClass?: React.CSSProperties;
  summaryFooterClass?: React.CSSProperties;
};

export const SummaryCardDiv = styled('div')<StyledDivProps>({
  width: 386,
  boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
  backgroundColor: '#FFFFFF',
  borderRadius: 20,
});

export const SummaryHeaderDiv = styled('div')<StyledDivProps>({
  minHeight: 20,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#FCFBFA',
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  paddingLeft: 18,
  paddingTop: 6,
  paddingBottom: 6,
});

export const SummaryBodyDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'summaryBodyClass',
})<StyledDivProps>(({ summaryBodyClass }) => ({
  padding: 0,
  ...summaryBodyClass,
}));

export const SummaryFooterDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'summaryFooterClass',
})<StyledDivProps>(({ summaryFooterClass }) => ({
  minHeight: 1,
  height: 1,
  display: 'flex',
  alignItems: 'center',
  borderBottomLeftRadius: 20,
  borderBottomRightRadius: 20,
  padding: 2,
  ...summaryFooterClass,
}));