import { forwardRef } from 'react';

import Typography from '../Typography';
import emptyStateImg from '../../assets/emptystates/empty-0.png';

import { SummaryCardEmptyStateProps } from './SummaryCardEmptyState.types';
import { Styles, SummaryEmptyBodyDiv, EmptyStateImageContainerDiv } from './SummaryCardEmptyState.theme';

const SummaryCardEmptyStateComponent = forwardRef<HTMLDivElement, SummaryCardEmptyStateProps>((props, ref) => {
  const {
    id, style, title,
    paragraph,
  } = props;

  return (
    <div id={id} ref={ref} style={style}>      
      <Typography.H6 className={Styles.title}>
        {title ?? 'Take it Breezy!'}
      </Typography.H6>
      <SummaryEmptyBodyDiv>
        <Typography.P1 style={{ color: '#222222' }}>
           {paragraph}
        </Typography.P1>
        <EmptyStateImageContainerDiv>
          <img src={emptyStateImg} alt='Empty' style={{ width: '80px' }} />
        </EmptyStateImageContainerDiv>
      </SummaryEmptyBodyDiv>
    </div>
  );
});

SummaryCardEmptyStateComponent.defaultProps = {
  style: undefined,
  children: undefined,
  title: undefined,
  paragraph: undefined,
  width: 396,
  height: undefined,
  backgroundImage: undefined,
};

export default SummaryCardEmptyStateComponent;
