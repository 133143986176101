import React, { forwardRef, useCallback } from 'react';
import Divider from '@mui/material/Divider';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons';

import Typography, { TypographyVariant } from '../Typography';

import { SelectionCardProps } from './CallOutcomeCard.types';
import { Styles, StyledCardButton, StyledCardContent, StyledRadio, StyledFormControlLabel } from './CallOutcomeCard.theme';

const renderFormLabel = (label: any, isDeletedDisposition: boolean = false) => {
  const dispositionTextLabel = !isDeletedDisposition 
    ? <Typography.P1 id={`${label}-Form-Control-label`}>{label}</Typography.P1> 
    : <>
      <Typography.P1 id={`${label}-Form-Control-label`}>{label}</Typography.P1>
      <Typography.P2 id={`${label}-Form-Control-label`} style={{ paddingLeft: '5px' }}>(Deleted)</Typography.P2>
    </>
  return dispositionTextLabel;
};

const SelectionCardComponent = forwardRef<HTMLDivElement, SelectionCardProps<any>>((props, ref) => {
  const {
    id,
    className, style,
    icon, showIcon,
    cardTitle,
    selectionItems, getItemLabel, getItemValue, onItemSelect,
    showDivider, callOutcome,
    readOnly,
    dividerIndex,
    warningText
  } = props;

  const [value, setValue] = React.useState(callOutcome);

  const handleChange = useCallback((event: any) => {
    setValue(event.target.value);
    onItemSelect?.(event.target.value);
  }, [onItemSelect]);

  const cardClassName = {...Styles.card, ...className};

  return (
    <StyledCardButton id={id} ref={ref} styledCardClass={cardClassName} style={style}>
      <StyledCardContent id={`${id}-Card-Content`}>
        <div style={Styles.titleContainer}>
          {showIcon && <div style={Styles.iconContainer}>{icon}</div>}
          <div style={Styles.textContainer}>
            <Typography.H3 variant={TypographyVariant.active} className={Styles.titleText}>
              {cardTitle}
            </Typography.H3>
            <Typography.P1 variant={TypographyVariant.active}>(select one)</Typography.P1>
          </div>
        </div>

        {warningText && 
          <div style={Styles.wariningTextContainer}>
            <Typography.P2 id={`${id}-Warning-Text`} variant={TypographyVariant.error}>{warningText}</Typography.P2>
          </div>
        }
        
        <div style={Styles.bodyContainer}>
          <FormControl id={`${id}-Form-Control`} component="fieldset" sx={{ width: '100%' }}
           >
            <RadioGroup aria-label="selectionItems" name="selectionItems-radios" value={value} onChange={handleChange}>
              {selectionItems.map((item, index) => (
                <div key={`${id}-Selection-Item-${index + 1}`} style={{ width: '100%', textAlign: 'left' }}>
                  <StyledFormControlLabel
                    value={getItemValue(item)}
                    control={
                      <StyledRadio
                        disableRipple
                        icon={<FontAwesomeIcon icon={faCircle as IconProp} style={Styles.icon} />}
                        checkedIcon={<FontAwesomeIcon icon={faDotCircle as IconProp} style={Styles.iconChecked} />}
                        disabled={!!readOnly}
                      />
                    }
                    label={renderFormLabel(getItemLabel(item), item?.isDeletedDisposition)}
                  />
                  {showDivider && index === dividerIndex && <Divider />}
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </StyledCardContent>
    </StyledCardButton>
  );
});

SelectionCardComponent.defaultProps = {
  className: undefined,
  style: undefined,
  cardTitle: 'Selection Card',
  showIcon: false,
  selectionItems: [],
  getItemLabel: () => {},
  getItemValue: () => {},
  onItemSelect: () => {},
  showDivider: false,
  callOutcome: '',
  dividerIndex: 0,
  readOnly: false,
};

export default SelectionCardComponent;
