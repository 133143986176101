export const Styles = {
  title: {
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 600 
  },
  historyContainer: {
    minWidth: '300px !important',
    maxWidth: 300,
    '& > div:nth-of-type(1)': {
      height: '100%',
      width: 386,
      marginRight: '2px !important'
    },
  },
  summaryScheduledBody: {
    padding: 0,
  },
  historyFooter: {
    justifyContent: 'center',
  },
  historyButton: {
    color: '#0339C4',
    fontSize: 14,
  },
};
