import { styled } from '@mui/material';
import FontFamily from '../../Theme/FontFamily';

import { } from './MessageBanner.types';

interface StyledDivProps {
  bannerClassName?: React.CSSProperties;
  isBarVisible?: boolean;
};

export const ParentBannerDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'isBarVisible',
})<StyledDivProps>(({ isBarVisible }) => ({
  minHeight: '34px',
  transition: 'all 5s ease',
  opacity: 1,
  width: '100%',
  ...(!isBarVisible && { opacity: 0, display: 'none' }),
}));

export const MessageContainerDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'bannerClassName',
})<StyledDivProps>(({ bannerClassName }) => ({
  zIndex: 1201,
  display: 'flex',
  minHeight: '35px',
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#FF5B60',
  ...bannerClassName,
}));

export const RightSecDiv =  styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: '65px',
  zIndex: 1,
  button: {
    marginLeft: '1.2rem',
    minWidth: '1.2rem',
    width: '1.2rem',
    padding: 0,
    '&:hover': {
      background: 'transparent',
    }
  },
  svg: {
    color: '#ffffff',
    fontSize: '1.2rem',
  },
});

export const BtnTextDiv =  styled('div')({
  color: '#ffffff',
  fontFamily: FontFamily,
  fontSize: '1.4rem',
  fontWeight: 'bold',
  letterSpacing: '0.023rem',
  linHeight: '1.7rem',
});
