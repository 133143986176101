import Colours from '../../../../../Theme/Colours';
import { Theme } from '../Buttons.types';

const classes = {};
const useStyles = {
  common: {
    fontSize: 16,
    fontWeight: 600,
  },
  [Theme.primary]: {
    backgroundColor: Colours.PrimaryA.normal,
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryA.hovered,
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryA.pressed,
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryA.pressed,
    },
  },
  [Theme.secondary]: {
    backgroundColor: Colours.PrimaryB.normal,
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryB.hovered,
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryB.pressed,
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryB.pressed,
    },
  },
  [Theme.danger]: {
    backgroundColor: Colours.Secondary.Red.normal,
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: '#EA3E3F',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: '#EA3E3F',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: '#EA3E3F',
    },
  },
  [Theme.defaultAlternative]: {
    color: Colours.Typography.light,
    backgroundColor: Colours.Typography.active,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: Colours.Typography.active,
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: Colours.Typography.active,
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: Colours.Typography.active,
    },
  },
  [Theme.secondaryAlternative]: {
    color: Colours.Typography.light,
    backgroundColor: Colours.Typography.active,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: Colours.Typography.active,
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: Colours.Typography.active,
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: Colours.Typography.active,
    },
  },
  [Theme.default]: {
    color: Colours.Typography.label,
    backgroundColor: 'unset',
    border: `1px solid transparent`,

    '&:hover': {
      color: Colours.Typography.label,
      backgroundColor: Colours.Backgrounds.button,
      border: `1px solid ${Colours.Borders.dark}`,
    },
    '&:active': {
      color: Colours.Typography.label,
      backgroundColor: Colours.Backgrounds.button,
      border: `1px solid ${Colours.Borders.dark}`,
    },
    '&:focus': {
      color: Colours.Typography.label,
      backgroundColor: Colours.Backgrounds.button,
      border: `1px solid ${Colours.Borders.dark}`,
    },
  },
  [Theme.dark]: {
    color: Colours.Typography.light,
    backgroundColor: Colours.Auxiliary.navigation,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(0, 25, 50, 0.9)',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(0, 25, 50, 0.85)',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(0, 25, 50, 0.85)',
    },
  },
};

export default { classes, useStyles };
