import FontFamily from '../../../../Theme/FontFamily';
import Colours from '../../../../Theme/Colours';

import Card from '@mui/material/Card';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledTextField = styled(InputBase)({
  input: {
    cursor: 'default',
    fontFamily: FontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '27px',
    color: Colours.Typography.light,
    textAlign: 'center',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

export const StyledCard = styled(Card)({
  minWidth: 264,
  minHeight: 60,
  maxWidth: 264,
  maxHeight: 72,
  textAlign: 'center',
  boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
  borderRadius: '24px 24px 0px 0px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  display: 'flex',
  alignItems: 'center',
});

export const StyledBackspaceIcon = styled('div')({
  marginRight: 12,
  '&:hover': {
    cursor: 'pointer',
  },
});

export const StyledIconElement = styled(FontAwesomeIcon)({
  '&:hover': {
    color: '#DADADA',
  },
  '&:active': {
    color: '#DADADA',
  },
  '&:focus': {
    color: '#DADADA',
  },
});

export const StyledMainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const StyledInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: 25,

  '& > div': {
    height: '100%',
    color: '#FFFFFF',
  },
});

// export const StyledContactName = {
//   fontFamily: FontFamily,
//   fontWeight: 600,
//   fontSize: 12,
// };

// export const StyledContactNameWithMargin = {
//   marginRight: 30,
// };
