import { Badge, BadgeProps, styled } from '@mui/material';
import Colours from '../../Theme/Colours';

const CallStatusColours = { received: 'rgba(80, 207, 151, 1)', missed: 'rgba(243, 96, 90, 1)' };

interface StyledBadgeProps extends BadgeProps {
  badgeClassName?: React.CSSProperties;
};

export const Styles = {
  icon: { fontSize: 10 },
  avatar: {
    width: '15px !important',    
    height: '15px !important',
  }
};

export const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'badgeClassName',
})<StyledBadgeProps>(({ badgeClassName }) => ({
  '& .MuiBadge-badge': {
    fontSize: 8,
    fontWeight: 500,
    color: Colours.Typography.light,
    width: 15,
    height: 15,
    minWidth: 15,
  },
  colorSecondary: { backgroundColor: CallStatusColours.received },
  colorError: { backgroundColor: CallStatusColours.missed },
  ...badgeClassName,
}));
