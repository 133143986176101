import { forwardRef } from 'react';

import CallHistoryInfiniteList from './CallHistoryInfiniteList';

import { CallHistoryProps } from './CallHistory.types';
import { ContainerDiv } from './CallHistory.theme';

const CallHistoryComponent = forwardRef<HTMLDivElement, CallHistoryProps<any, any>>((props, ref) => {
  const {
    id, className, style, listStyle,
    data, dataTotalCount, dataLoading,
    bottomHitThreshold, onNextPageRequested,
    getId, getAvatarUrl, getAvatarFallbackLabel, isFailedCall,
    getCallDirection, getCallMedium, getDate, getTime, getDuration, getAvatarClassName, onCall,
    getActions, getActionNode, showActionsOverflowTooltip, getActionsOverflowCountTooltipContent,
    showNavigationNode, showNavNodeOnHover, getNavigationNode,
    miniVersion, isUnwrapped,
    emptyCallHistoryNode, loaderCallHisoryNode,
    showCallActionButton, menuListStyle, getMenuItems, onHistoryRowClick, onAvatarClick,
    getCallStatusBadgeTooltip,
    callHistoryInitialLoader,
  } = props;
  const emptyData = dataTotalCount === 0;

  return (
    <ContainerDiv id={id} ref={ref} callHistoryClassName={className} style={style}>
      {(emptyData && callHistoryInitialLoader) && (
        loaderCallHisoryNode
      )}

      {(emptyData && !callHistoryInitialLoader) && (
        emptyCallHistoryNode
      )}

      {(!emptyData && !callHistoryInitialLoader) && (
        <CallHistoryInfiniteList
          id={`${id}-List`}
          style={listStyle}
          data={data}
          dataTotalCount={dataTotalCount}
          dataLoading={dataLoading}
          bottomHitThreshold={bottomHitThreshold}
          onNextPageRequested={onNextPageRequested}
          getId={getId}
          getAvatarUrl={getAvatarUrl}
          getAvatarFallbackLabel={getAvatarFallbackLabel}
          getAvatarClassName={getAvatarClassName}
          isFailedCall={isFailedCall}
          isUnwrapped={isUnwrapped}
          getCallDirection={getCallDirection}
          getCallMedium={getCallMedium}
          getDate={getDate}
          getTime={getTime}
          getDuration={getDuration}
          getActions={getActions}
          getActionNode={getActionNode}
          onCall={onCall}
          showActionsOverflowTooltip={showActionsOverflowTooltip}
          getActionsOverflowCountTooltipContent={getActionsOverflowCountTooltipContent}
          showNavigationNode={showNavigationNode}
          showCallActionButton={showCallActionButton}
          showNavNodeOnHover={showNavNodeOnHover}
          getNavigationNode={getNavigationNode}
          miniVersion={miniVersion}
          menuListStyle={menuListStyle}
          getMenuItems={getMenuItems}
          onHistoryRowClick={onHistoryRowClick}
          onAvatarClick={onAvatarClick}
          getCallStatusBadgeTooltip={getCallStatusBadgeTooltip}
        />
      )}
    </ContainerDiv>
  );
});

CallHistoryComponent.defaultProps = {
  className: undefined,
  style: undefined,
  listClassName: undefined,
  listStyle: undefined,
  bottomHitThreshold: 300,
  showActionsOverflowTooltip: false,
  showNavigationNode: false,
  showCallActionButton: false,
  showNavNodeOnHover: false,
  miniVersion: false,
  emptyCallHistoryNode: undefined,
  loaderCallHisoryNode: undefined,
  callHistoryInitialLoader: false,
};

export { CallHistoryCallDirection } from './CallHistory.types';
export default CallHistoryComponent;
