import React, { forwardRef, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DialpadIcon from '@mui/icons-material/Dialpad';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';
import Avatar, { AvatarSize } from '../Avatar';
import CallConstants from '../../constants/Call';
import { updateUserStatus } from '../../slices/auth';
import { showSnackbar } from '../../slices/snackbar';
import Typography, { TypographyVariant } from '../Typography';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ReactComponent as RocketIcon } from '../../assets/viq-new-logo-white.svg';
import { DefaultUserStatuses, DefaultUserStatusesColours } from '../../constants/UserStatusesEnum';

import { TopNavBarProps } from './TopNavBar.types';
import { Styles, StyledDropdownListItemPrimaryText, StyledStatusDotWrapper, StyledStatusDot } from './TopNavBar.theme';

// const ThemedListItem = styled(ListItem)(() => (
//   {
//     selected: { backgroundColor: '#e7f3fc !important' },
//     gutters: { paddingLeft: '12px', paddingTop: '7px', paddingBottom: '7px' },
//   }
// ));

const TopNavBarComponent = forwardRef<HTMLDivElement, TopNavBarProps>((props: any, ref) => {
  const { id, disableDialpad, onDialPadClicked, onHomeClicked } = props;
  const authInfo = useAppSelector((state: any) => state.auth);
  const tenantSettingsData = useAppSelector((state: any) => state.settings.tenantSettings);
  const currentActiveCallRecordId = useAppSelector((state: any) => state.callCentre.currentActiveCallRecordId);
  const dispatch = useAppDispatch();
  
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorElUser);

  // User status variable
  const availableStatus = tenantSettingsData?.userStatuses?.filter((item: any) => item?.name === DefaultUserStatuses.Available);
  const availableId = availableStatus?.[0]?.id;
  const selectedStatusId = !!authInfo?.user?.userStatus?.id ? authInfo?.user?.userStatus?.id : availableId;

  useEffect(() => {
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(!anchorElUser ? event.currentTarget : null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleStatusItemAvailabilityClick = (status: any) => {
    // const onCallUserStatus = userStatues?.filter((item: any) => item?.name === DefaultUserStatuses.OnCall)?.[0];
    // || authInfo?.user?.userStatus?.id === onCallUserStatus?.id
    if (currentActiveCallRecordId) {
      const snackbarData = { message: 'Can not change user status while on a call'};
      dispatch(showSnackbar({ snackbarData }));
    } else {
      const userStatues = tenantSettingsData?.userStatuses;
      const availableStatusResult = userStatues?.filter((item: any) => item?.id === status?.id);
      const userStatuses = {
        UserStatusId: status?.id,
        Available: availableStatusResult?.[0]?.isAvailable,
        AwayFromDesk: availableStatusResult?.[0]?.isAwayFromDesk,
      };

      dispatch(updateUserStatus(userStatuses));
    }

    handleCloseUserMenu();
  };

  const renderAdminUserStatuses = (index: number, status: any, selectedStatusId: string) => {
    const selected = selectedStatusId === status?.id;

    return (
      <ListItem
        id={`Admin-UserStatus-${index}`}
        key={`Admin-UserStatus-${index}`}
        sx={Styles.menuListItem}
        onClick={() => handleStatusItemAvailabilityClick(status)}
        // disabled={!!this.props.userInCall} // We have to disable while user in call
        // selected={selected}
        disableGutters
      >
        <ListItemButton>
          <ListItemIcon sx={Styles.listIconWrapper}>
            <FontAwesomeIcon
              icon={faCircle as IconProp}
              style={{ fontSize: '10px', marginRight: '14px', }}
              color={status.colour}
            />
          </ListItemIcon>
          <ListItemText
            sx={Styles.listItemTextWrapper}
            primary={
              <Typography.P1 style={{ maxWidth: 200 }} variant={TypographyVariant.title} noWrap>{status.name}</Typography.P1>
            }
          />
          {
            status?.isAwayFromDesk && <Typography.P2 variant={TypographyVariant.label} className={Styles.divertLabel}>Divert</Typography.P2>
          }
          {
            selected && (
            <ListItemIcon sx={Styles.listIconWrapper}>
              <FontAwesomeIcon icon={faCheck as IconProp} style={Styles.checkIcon} />
            </ListItemIcon>
            )
          }
        </ListItemButton>
      </ListItem>
    );
  };

  const renderStatuses = () => {
    const userPreferences = authInfo?.user?.preferences;
    const { userStatuses } = tenantSettingsData;
    const callDivertAvailable = !!(userPreferences?.callDivertingNumber)
                                && !!userPreferences?.callDivertingNumber?.isActive
                                && !!userPreferences?.callDivertingNumber?.number;

    const activeUserStatus = callDivertAvailable ? 
                             userStatuses?.filter((item: any) => item?.isSelectable && !item?.archived) || []
                            : userStatuses?.filter((item: any) => item?.isSelectable && !item?.archived && !item.isAwayFromDesk) || [];

    const result = activeUserStatus?.map((status: any, index: number) => renderAdminUserStatuses(index, status, selectedStatusId));

    return result;
  };

  const getNumberSelectedStatus = (numberState: {numberId: string, callerIdType: string }) => {

    return numberState.numberId === authInfo?.user?.preferences?.outgoingCallIdPreference?.phoneNumberId &&
    numberState.callerIdType === authInfo?.user?.preferences?.outgoingCallIdPreference?.callerIdType;
  };

  const renderTeamHuntgroups = (team: any) => {
    const { huntGroups } = team;
    const teamHuntGroups = (
      <ol
       style={Styles.orderedList}
       >
        {huntGroups.map((huntGroup: any) => {
          const isMember = !!huntGroup?.members?.find((member: any) => member?.userId === authInfo?.user?.id);

          return (
            <li id={`${huntGroup?.id}-Team-HuntGroups-List`} key={`${huntGroup?.id}-Team-HuntGroups-List`}>
              <Typography.P1 variant={TypographyVariant.navigation}>{huntGroup?.name}</Typography.P1>
              {isMember && (
                <Typography.P2 variant={TypographyVariant.label}
                 className={Styles.memberLabel}
                 >(member)</Typography.P2>
              )}
            </li>
          );
        })}
      </ol>
    );

    return teamHuntGroups;
  };

  const companyCallNumberState = {
    numberId: tenantSettingsData?.phoneNumber?.id,
    callerIdType: CallConstants.OutgoingCallIdEnums.Organisation,
  };
  const teamNumberState = {
    numberId: authInfo?.userTeam?.number?.id,
    callerIdType: CallConstants.OutgoingCallIdEnums.Team,
  };

  const activeUserStatus = tenantSettingsData?.userStatuses?.filter((status: any) => status?.id === selectedStatusId) || [];
  const activeUserStatusColor = (tenantSettingsData?.userStatuses?.length > 0) ? activeUserStatus?.[0]?.colour : DefaultUserStatusesColours.Available;
  const activeUserStatusName = (tenantSettingsData?.userStatuses?.length > 0) ? activeUserStatus?.[0]?.name : 'Available';
  const isUserOnCall = !!currentActiveCallRecordId;

  return (
      <AppBar position="static" sx={Styles.topNavBar}>
        <Container>
          <Toolbar disableGutters sx={Styles.navContainer}>
            <RocketIcon
              style={{ height: "28px", cursor: 'pointer' }}
              onClick={disableDialpad ? undefined : onHomeClicked}
            />
            <div>
              <IconButton
                size="large"
                sx={{ mr: '12px', position: 'relative', color: 'whitesmoke' }}
                disabled={disableDialpad}
                onClick={onDialPadClicked}
              >
                <DialpadIcon 
                  sx={{display: 'inline-block', float: 'right'}} 
                />
              </IconButton>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, mr: '12px', position: 'relative' }}
              >
                <div>
                  <Avatar
                    id={`${id}-UserDropdown-Avatar`}
                    alt='User-Avatar'
                    url={authInfo?.user?.photoDetails?.photoUrl || null}
                    fallbackLabel={authInfo?.user?.displayName}
                    size={AvatarSize.medium}
                    disableToolTip
                  />
                  <Tooltip
                    id={`${id}-StatusDotWrapper-Tooltip`}
                    content={<Typography.P2 variant={TypographyVariant.light}>{isUserOnCall ?  'On call' : activeUserStatusName}</Typography.P2>}
                    placement="bottom"
                    arrow
                  >
                    <StyledStatusDotWrapper>
                      <StyledStatusDot style={{ backgroundColor: isUserOnCall ?  DefaultUserStatusesColours.OnCall : activeUserStatusColor }}>
                        {
                          isUserOnCall && (
                            <FontAwesomeIcon
                              icon={faPhoneVolume as IconProp}
                              style={Styles.volumeIcon}
                            />
                          )
                        }
                      </StyledStatusDot>
                    </StyledStatusDotWrapper>
                  </Tooltip>
                </div>
                <Menu
                  id="account-menu"
                  anchorEl={anchorElUser}
                  sx={Styles.menu}
                  open={open}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        position:'fixed',
                        overflowColumn: 'scroll',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 0.8,
                        display: 'flex',
                        flexDirection: 'column',                     
                        '& .MuiMenuItem-root': {
                          minHeight: '30px',
                          ml: 0,
                          mr: 0,
                          fontSize:'14px',
                          fontWeight: 'bold',
                        },
                        '& .MuiListItem-root': {
                          maxWidth: '42px',
                          height: '12px',
                          maxHeight: '42px',
                          ml: 0,
                          mr: 0,
                          p: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem sx={Styles.menuItemTitle} disabled disableGutters>
                    <ListItemIcon sx={Styles.listItemIcon}>
                      <PersonSearchIcon sx={{ color: 'black' }} fontSize="small" />
                    </ListItemIcon>
                    Your Status
                  </MenuItem>
                  {renderStatuses()}
                  <Divider />
                  <MenuItem sx={Styles.menuItemTitle} disabled disableGutters>
                    <ListItemIcon sx={Styles.listItemIcon}>
                      <ManageAccountsIcon fontSize="small" sx={{ color: 'black' }} />
                    </ListItemIcon>
                    Your Phone Setup
                  </MenuItem>
                  <ListItem
                    id={`${id}-Personal-Setting-Name`}
                    key="Personal-Setting-Name"
                    sx={Styles.firstThemeListItem}
                  >
                    <ListItemButton>
                      <ListItemText
                        sx={Styles.listItemContentTextWrapper}
                        primary={
                          <Typography.H5 className={Styles.listItemTextH5} variant={TypographyVariant.title}>{authInfo?.user?.displayName || '-'}</Typography.H5>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    id={`${id}-Personal-Setting-DirectLine`}
                    key="Personal-Setting-DirectLine"
                    sx={Styles.firstThemeListItem}
                  >
                    <ListItemButton>
                      <ListItemText
                        sx={Styles.listItemContentTextWrapper}
                        primary={
                          <StyledDropdownListItemPrimaryText>
                            Direct Line
                            {
                              getNumberSelectedStatus({
                                numberId: authInfo?.user?.phone?.id,
                                callerIdType: CallConstants.OutgoingCallIdEnums.Personal,
                              }) && authInfo?.user?.phone?.allocationType !== 'RC' && (
                                <span>(default dial out)</span>
                              )
                            }
                          </StyledDropdownListItemPrimaryText>
                        }
                        secondary={`${authInfo?.user?.phone?.number || '-'} ${authInfo?.user?.phone?.allocationType === 'RC' ? '(RC)': ''}`}
                      />
                    </ListItemButton>
                  </ListItem>
                  {authInfo?.user?.mobilePhone?.number && 
                    <ListItem
                      id={`${id}-Personal-Setting-mobilePhone`}
                      key="Personal-Setting-mobilePhone"
                      sx={Styles.firstThemeListItem}
                    >
                      <ListItemButton>
                        <ListItemText
                          sx={Styles.listItemContentTextWrapper}
                          primary={
                            <StyledDropdownListItemPrimaryText>
                              Mobile Phone
                              {
                                getNumberSelectedStatus({
                                  numberId: authInfo?.user?.mobilePhone?.id,
                                  callerIdType: CallConstants.OutgoingCallIdEnums.Personal,
                                }) && authInfo?.user?.mobilePhone?.allocationType !== 'RC' && (
                                  <span>(default dial out)</span>
                                )
                              }
                            </StyledDropdownListItemPrimaryText>
                          }
                          secondary={`${authInfo?.user?.mobilePhone?.number || '-'} ${authInfo?.user?.mobilePhone?.allocationType === 'RC' ? ' (RC)': ''}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  }
                  <ListItem
                    id={`${id}-Personal-Setting-TeamNumber`}
                    key="Personal-Setting-TeamNumber"
                    sx={Styles.firstThemeListItem}
                  >
                    <ListItemButton>
                      <ListItemText
                        sx={Styles.listItemContentTextWrapper}
                        primary={
                          <StyledDropdownListItemPrimaryText>
                            Team Number
                            {
                              getNumberSelectedStatus(teamNumberState) && (
                                <span>(default dial out)</span>
                              )
                            }
                          </StyledDropdownListItemPrimaryText>
                        }
                        secondary={`${authInfo?.userTeam?.number?.number || '-'}`}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    id={`${id}-Personal-Setting-OrganisationNumber`}
                    key="Personal-Setting-OrganisationNumber"
                    sx={Styles.firstThemeListItem}
                  >
                    <ListItemButton>
                      <ListItemText
                        sx={Styles.listItemContentTextWrapper}
                        primary={
                          <StyledDropdownListItemPrimaryText>
                            Organisation Number
                            {
                              getNumberSelectedStatus(companyCallNumberState) && (
                                <span>(default dial out)</span>
                              )
                            }
                          </StyledDropdownListItemPrimaryText>
                        }
                        secondary={`${tenantSettingsData?.phoneNumber?.number || '-'}`}
                      />
                    </ListItemButton>
                  </ListItem>
                  {!!(authInfo?.userTeam?.huntGroups?.length) && (
                    <ListItem
                      id={`${authInfo?.userTeam?.id}-User-Team-HuntGroups`}
                      key={`${authInfo?.userTeam?.id}-User-Team-HuntGroups`}
                      sx={Styles.firstThemeListItem}
                    >
                      <ListItemButton>
                        <ListItemText
                          sx={Styles.listItemContentTextWrapper}
                          primary={<StyledDropdownListItemPrimaryText>Hunt Groups</StyledDropdownListItemPrimaryText>}
                          secondary={renderTeamHuntgroups(authInfo?.userTeam)}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                </Menu>
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
  );
});

TopNavBarComponent.propTypes = {
  id: PropTypes.string.isRequired,
  disableDialpad: PropTypes.bool,
};
TopNavBarComponent.defaultProps = {
  disableDialpad: false,
};

export default TopNavBarComponent;
