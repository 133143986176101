import { styled } from '@mui/material';

export const StyledHome = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
  paddingLeft: '14px',
  paddingRight: '14px',
  backgroundColor: '#001932',
});

export const StyledParagraph = styled('p')({
  color: "white", 
  fontSize: '18px'
});