import { Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledModal = styled(Modal)({
  Padding: 16,
});

export const StyledModalContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const StyledTitle = styled('div')({
  marginBottom: 24,
});

export const StyledFooter = styled('div')({
  textAlign: 'right',
  marginTop: 30,
});

export const StyledDangerButton = {
  backgroundColor: 'rgba(222, 30, 23, 1)',

  '&:hover': {
    backgroundColor: 'rgba(207, 16, 12, 1)',
  },
  '&:active': {
    backgroundColor: 'rgba(180, 1, 1, 1)',
  },
  '&:focus': {
    backgroundColor: 'rgba(180, 1, 1, 1)',
  },
  '&:disabled': {
    backgroundColor: 'rgba(245, 187, 185, 1) !important',
    color: 'rgba(255, 255, 255, 0.5)',
  },
};
