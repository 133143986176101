import React, { useCallback, useState, useEffect } from 'react';
import debounce from 'debounce';

import ScheduledCallsCardRowLoading from '../../ScheduledCallsCardRowLoading';
import ScheduledCallCard from '../../ScheduledCallCard';

import { ScheduledCallInfiniteListProps } from './ScheduledCallInfiniteList.types';
import { ListDiv } from './ScheduledCallInfiniteList.theme';

const DefaultPlaceholderItemsCount = 3;

const ScheduledCallInfiniteListComponent: React.FC<ScheduledCallInfiniteListProps<any>> = ({
  id,
  dataLoading,
  bottomHitThreshold, onNextPageRequested,
  getId, getPhotoUrl, getPhotoFallbackLabel,
  getName, getCompanyName, showTime, getTime,
  getMenuItems, menuListStyle, onCall,
  getCampaignColour, getCampaignName, showCallButton,
  onContactClick, onScheduleRowClick, callButtonDisabled, isUnknownContact,

  // optional props
  style = undefined,
  placeholderCount = 5,
  getDate = undefined,
  data = [],
}) => {

  const placeholderItemsCount = placeholderCount || DefaultPlaceholderItemsCount;

  const scrollResolver = useCallback(debounce((event: any) => {
    const { target } = event;
    const bottomHit = target.scrollHeight - target.scrollTop - (bottomHitThreshold || DefaultPlaceholderItemsCount) <= target.clientHeight;

    if (bottomHit) onNextPageRequested();
  }, 400), []);

  const scrollHandler = useCallback((event: any) => {
    scrollResolver(event);
  }, []);

  const [scrollContainerRef, setScrollContainerRef] = useState<HTMLDivElement | null>(null);
  const handleScrollContainerRef = useCallback((ref: HTMLDivElement) => {
    if (ref) {
      setScrollContainerRef(ref);
      ref.addEventListener('scroll', scrollHandler);
    }
  }, []);

  useEffect(() => {
    return () => scrollContainerRef?.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <ListDiv
      id={id}
      key={id}
      ref={handleScrollContainerRef}
      style={style}
    >
      {/* Rendering the items count */}
      {!dataLoading && data?.map((item, index) => (
        <ScheduledCallCard
          getId={getId}
          data={item}
          getPhotoUrl={getPhotoUrl}
          getPhotoFallbackLabel={getPhotoFallbackLabel}
          getName={getName}
          getCompanyName={getCompanyName}
          getCampaignColour={getCampaignColour}
          getCampaignName={getCampaignName}
          getTime={getTime}
          getDate={getDate}
          onCall={onCall}
          callButtonDisabled={callButtonDisabled}
          onContactClick={onContactClick}
          onScheduleRowClick={onScheduleRowClick}
          key={`${id}-${index}-ScheduledCallCard-${getId(item)}`}
          menuListStyle={menuListStyle}
          showTime={showTime}
          getMenuItems={getMenuItems}
          showCallButton={showCallButton}
          isUnknownContact={isUnknownContact}
        />
      ))}

      {dataLoading && Array.from(Array(placeholderItemsCount).keys()).map((index) => (
        <ScheduledCallsCardRowLoading
          id={`${id}-Row-Placeholder-${index}`}
          key={`${id}-Row-Placeholder-${index}`}
          style={style}
        />
      ))}
    </ListDiv>
  );
};

export default ScheduledCallInfiniteListComponent;
