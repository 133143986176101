import { forwardRef, useCallback } from 'react';

import Avatar from '../../../Avatar';
import AvatarGroup from '../../../AvatarGroup';
// import { CallMedium } from '../../../../Enums/ActivityEnum';
import Typography, { TypographyVariant } from '../../../Typography';
import DropdownButton, { DropdownButtonSplitTheme } from '../../../Split';
import CallStatusBadge, { CallStatusBadgeStatusType } from '../../../CallStatusBadge';
import CallActionAvatar, { CallActionAvatarType, CallActionAvatarVariant, CallActionAvatarSize } from '../../../CallActionAvatar'

import {
  Styles, CallHistoryRowDiv, UseCallStatusBadgeStyles,
  ColumnTimestampDiv, ColumnActionsDiv, DurationDiv, DropdownButtonDiv
} from './CallHistoryRow.theme';
import { CallHistoryRowProps, CallDirection, Theme } from './CallHistoryRow.types';

const CallHistoryRowComponent = forwardRef<HTMLDivElement, CallHistoryRowProps<any>>((props, ref) => {
  const {
    id,
    style,
    avatarUrl,
    avatarFallbackLabel,
    callFailed,
    callDirection,
    // callMedium,
    date, time,
    duration,
    // avatarClassName,
    actions,
    getActionNode,
    showActionsOverflowTooltip,
    getActionsOverflowCountTooltipContent,
    showCallActionButton,
    callUnwrapped,
    theme,
    item,
    menuListStyle,
    callButtonDisabled,
    getMenuItems, onCall, onHistoryRowClick, onAvatarClick,
    getCallStatusBadgeTooltip,
  } = props;

  const callIsInbound = callDirection === CallDirection.inbound;
  // const isSalesforceCall = callMedium === CallMedium.salesForce;
  // const isRocketCellCall = callMedium === CallMedium.rocketCell;

  let callStatusType = CallStatusBadgeStatusType.inboundReceived;
  if (callFailed) callStatusType = callIsInbound ? CallStatusBadgeStatusType.inboundMissed : CallStatusBadgeStatusType.outboundMissed;
  else callStatusType = callIsInbound ? CallStatusBadgeStatusType.inboundReceived : CallStatusBadgeStatusType.outboundReceived;
  
  const handleDefaultCallClick = useCallback((cb:any, e:any) => {
    e.stopPropagation();
    onCall(item);
  }, [onCall, item]);

  const handleHistoryRowClick = () => {
    onHistoryRowClick?.(item);
  };
  const handleAvatarClick = (e:any) => {
    e.stopPropagation();
    onAvatarClick?.(item);
  };

  return (
    <CallHistoryRowDiv
      id={id}
      ref={ref}
      rowClassTheme={theme}
      style={style}
      onClick={handleHistoryRowClick}
    >
      <div style={{ width: 36 }} onClick={handleAvatarClick}>
        <CallStatusBadge
          id={`${id}-StatusBadge`}
          classes={UseCallStatusBadgeStyles}
          statusType={callStatusType}
          callStatusTooltip={getCallStatusBadgeTooltip?.(item)}
        >
          <Avatar
            id={`${id}-Avatar`}
            alt="Contact Avatar"
            url={avatarUrl || null}
            fallbackLabel={avatarFallbackLabel}
          />
          {/* {isSalesforceCall && (
            <Avatar
              id={`${id}-Avatar`}
              style={avatarStyle}
              fallbackTextStyle={fallbackTextStyle}  
              className={avatarClassName}
              url={avatarUrl}
              fallbackLabel={avatarFallbackLabel}
              alt="Contact Avatar"
            />
          )}
          {!isSalesforceCall && (
            <Tooltip
              id={`${id}-CallStatus-Tooltip-Wrapper`}
              content={<Typography.P2 variant={TypographyVariant.light}>{avatarFallbackLabel}</Typography.P2>}
              placement="bottom"
              arrow
            >
              <div className={Styles.callMediumIcon}>
                <FontAwesomeIcon icon={isRocketCellCall ? faPhone : faRocket } />
              </div>
            </Tooltip>
          )} */}
        </CallStatusBadge>
      </div>

      <ColumnTimestampDiv>
        <Typography.P1
          id={`${id}-Timestamp-Date`}
          className={Styles.timestampText}
          variant={TypographyVariant.active}
          noWrap
        >
          {date}
        </Typography.P1>
        <Typography.P1
          id={`${id}-Timestamp-Time`}
          className={Styles.timestampText}
          variant={TypographyVariant.active}
          noWrap
        >
          {time}
        </Typography.P1>
      </ColumnTimestampDiv>

      <ColumnActionsDiv>
        {!callFailed && callUnwrapped && (
          <CallActionAvatar
            id={`${id}-ActionIncompleted`}
            key={`${id}-ActionIncompleted`}
            className={Styles.flagIcon}
            size={CallActionAvatarSize.small}
            type={CallActionAvatarType.incomplete}
            variant={CallActionAvatarVariant.normal}
          />
        )}

        {actions && (
          <AvatarGroup
            id={`${id}-ActionsGroup`}
            key={`${id}-ActionsGroup`}
            data={actions}
            item={item}
            getAvatarNode={getActionNode}
            max={!callFailed && callUnwrapped ? 2 : 3}
            overflowCountHasTooltip={showActionsOverflowTooltip}
            getOverflowCountTolltipContent={getActionsOverflowCountTooltipContent}
          />
        )}
      </ColumnActionsDiv>
      <DurationDiv className="durationDiv"  durationWithCallButtonClass={showCallActionButton ? Styles.columnDurationWithCallButton : {}}>
        <Typography.P1
          id={`${id}-Duration`}
          className={Styles.durationText}
          variant={TypographyVariant.active}
          noWrap
        >
          {duration}
        </Typography.P1>
      </DurationDiv>

      <DropdownButtonDiv className="dropdownButtonDiv"  showCallActionButton={showCallActionButton}>
        <DropdownButton
          id={`${id}-CallActionButton`}
          menuListStyle={menuListStyle}
          theme={DropdownButtonSplitTheme.secondary}
          disabled={callButtonDisabled}
          menuItems={getMenuItems(item)}
          onPrimaryButtonClick={handleDefaultCallClick}
        >
          Call
        </DropdownButton>
      </DropdownButtonDiv>
    </CallHistoryRowDiv>
  );
});

CallHistoryRowComponent.defaultProps = {
  style: undefined,
  callFailed: false,
  callUnwrapped: false,
  showNavigationNode: false,
  showCallActionButton: false,
  showNavNodeOnHover: false,
  navigationNode: undefined,  //may cause problem   () => null
  theme: Theme.normal,
  callButtonDisabled: false,
  onCall: () => null,
  onHistoryRowClick: () => null,
  onAvatarClick: () => null,
  getCallStatusBadgeTooltip: () => '',
};

export {
  Theme as CallHistoryRowTheme,
  CallDirection as CallHistoryCallDirection,
} from './CallHistoryRow.types';
export default CallHistoryRowComponent;
