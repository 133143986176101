import React, { forwardRef } from 'react';

import ScheduledCallInfiniteList from './Private/ScheduledCallInfiniteList';

import { ScheduledCallProps } from './ScheduledCall.types';
import { ContainerDiv } from './ScheduledCall.theme';

const ScheduledCallComponent = forwardRef<HTMLDivElement, ScheduledCallProps<any>>((props, ref) => {
  const {
    id, className, style,
    data, dataTotalCount, dataLoading,
    bottomHitThreshold, onNextPageRequested,
    getId, getPhotoUrl, getPhotoFallbackLabel,
    getName, getCompanyName, getCampaignColour,
    getCampaignName, getTime, getDate, onCall, onContactClick,onScheduleRowClick,
    menuListStyle, showTime,
    getMenuItems, showCallButton, placeholderCount,
    callButtonDisabled, isUnknownContact
  } = props;

  return (
    <ContainerDiv
      id={id}
      ref={ref}
      scheduledCallClass={className}
      style={style}
    >
      <ScheduledCallInfiniteList
        id={`${id}-ScheduledCall`}
        dataTotalCount={dataTotalCount}
        style={style}
        getId={getId}
        data={data}
        getPhotoUrl={getPhotoUrl}
        getPhotoFallbackLabel={getPhotoFallbackLabel}
        getName={getName}
        getCompanyName={getCompanyName}
        getCampaignColour={getCampaignColour}
        getCampaignName={getCampaignName}
        getTime={getTime}
        getDate={getDate}
        onCall={onCall}
        callButtonDisabled={callButtonDisabled}
        onContactClick={onContactClick}
        onScheduleRowClick={onScheduleRowClick}
        menuListStyle={menuListStyle}
        showTime={showTime}
        getMenuItems={getMenuItems}
        showCallButton={showCallButton}
        dataLoading={dataLoading}
        bottomHitThreshold={bottomHitThreshold}
        onNextPageRequested={onNextPageRequested}
        placeholderCount={placeholderCount}
        isUnknownContact={isUnknownContact}
      />
    </ContainerDiv>
  );
});

ScheduledCallComponent.defaultProps = {
  className: undefined,
  style: undefined,
  bottomHitThreshold: 300,
  placeholderCount: 5,
  getDate: undefined,
  data: [],
};

export default ScheduledCallComponent;
