import React, { forwardRef, useCallback } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFireAlt, faCircleUser, faPhone } from '@fortawesome/pro-solid-svg-icons';

import Avatar from '../../Avatar';
import Tooltip from '../../Tooltip';
import { AvatarService } from '../../../Services/Avatar';
import Typography, { TypographyVariant } from '../../Typography';
import Button, { ButtonTheme, ButtonVariant } from '../../Button';
import DropdownButton, { DropdownButtonSplitTheme } from '../../Split';

import { 
  Styles, ContainerDiv, ContactAvtarDiv, ContactDiv, ToolTipContainerDiv, CampaignContainerDiv,
  ScheduledTimeDiv, CallButtonDiv,
 } from './ScheduledCallCard.theme';
import { ScheduledCallCardProps } from './ScheduledCallCard.types';

const PhoneIcon = (
    <FontAwesomeIcon icon={faPhone as IconProp} color='white' fontSize='20px' display='inline-block' />
);

const ScheduledCallCardComponent = forwardRef<HTMLDivElement, ScheduledCallCardProps<any>>((props, ref) => {
  const {
    style, data,
    getId, getPhotoUrl, getPhotoFallbackLabel,
    getName, getCompanyName, getTime, getDate,
    menuListStyle,
    onCall, getCampaignColour, getCampaignName,
    getMenuItems, showTime, showCallButton, onContactClick, onScheduleRowClick,
    callButtonDisabled, isUnknownContact
  } = props;

  const numbersNotAvailable = !getMenuItems(data);
  const campaignName = getCampaignName(data);
  const companyName = getCompanyName(data);
  const id = getId(data);
  const unknownContact = isUnknownContact(data);

  const handleScheduleRowClick = useCallback((e: any) => {
    e?.stopPropagation();
    onScheduleRowClick?.(data);
  }, [onScheduleRowClick, data]);

  const handleDefaultCallClick = useCallback((e: any) => {
    e?.stopPropagation();
    onCall(data);
  }, [onCall, data]);

  const handleContactClick = useCallback((e: any) => {
    e?.stopPropagation();
    onContactClick(data);
  }, [data, onContactClick]);

  const avatarStyle = {
    backgroundColor: AvatarService.getBackgroundColour(getPhotoFallbackLabel(data), null),
  };
  const fallbackTextStyle = {
    color: AvatarService.getTextColour(getPhotoFallbackLabel(data), null),
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 24,
  };

  return (
    <ContainerDiv
      id={id}
      ref={ref}
      style={style}
      onClick={handleScheduleRowClick}
    >
      <ContactAvtarDiv>
        {unknownContact && <FontAwesomeIcon
          icon={faCircleUser as IconProp}
          color="#08104D"
          style={{ fontSize: 32 }} 
        />}
        {!unknownContact && (<Avatar
            id={`${id}-Avatar`}
            style={avatarStyle}
            className={Styles.avatar}
            fallbackTextStyle={fallbackTextStyle}
            url={getPhotoUrl(data)}
            fallbackLabel={getPhotoFallbackLabel(data)}
            alt='Contact Photo'
          />
        )}
        <ContactDiv>
          <div>
            <Button
              id={`${id}-ContactName`}
              className={Styles.contactNameText}
              variant={ButtonVariant.text}
              theme={ButtonTheme.primary}
              onClick={handleContactClick}
            >
              {getName(data)}
            </Button>
            {companyName && (
              <Typography.P2
                id={`${id}-ContactTitle`}
                className={Styles.contactCompanyText}
                variant={TypographyVariant.system}
                noWrap
              >
                {companyName}
              </Typography.P2>
            )}
          </div>
          {campaignName && (
            <ToolTipContainerDiv>
            <Tooltip
              id={`${id}-Campaign-Tooltip`}
              content={<Typography.P2 variant={TypographyVariant.light}>{getCampaignName(data)}</Typography.P2>}
              placement="bottom"
              arrow
              force
            >
              <CampaignContainerDiv id={`${id}-IndustryType`}>
                <FontAwesomeIcon icon={faFireAlt as IconProp} color={getCampaignColour(data)} style={{ fontSize: 12 }} />
              </CampaignContainerDiv>
            </Tooltip>
            </ToolTipContainerDiv>
          )}
        </ContactDiv>
      </ContactAvtarDiv>
      <ScheduledTimeDiv className="scheduledTimeDiv">
        {showTime && (
          <>
            <Typography.P1
              id={`${id}-Date`}
              className={Styles.timeText}
              variant={TypographyVariant.system}
              noWrap
            >
              {getDate?.(data) ?? '-'}
            </Typography.P1>
            <Typography.P1
              id={`${id}-Time`}
              className={Styles.timeText}
              variant={TypographyVariant.system}
              noWrap
            >
              {getTime?.(data) ?? '-'}
            </Typography.P1>
          </>
        )}
      </ScheduledTimeDiv>
      <CallButtonDiv className="callButtonDiv">
        {
          showCallButton && (
            <DropdownButton
              id={`${id}-CallActionButton`}
              menuListStyle={menuListStyle}
              theme={DropdownButtonSplitTheme.secondary}
              startIcon={PhoneIcon}
              disabled={numbersNotAvailable || callButtonDisabled}
              menuItems={getMenuItems(data)}
              onPrimaryButtonClick={handleDefaultCallClick}
            >
              {getTime?.(data) ?? '-'}
            </DropdownButton>
          )
        }
      </CallButtonDiv>
    </ContainerDiv>
  );
});

ScheduledCallCardComponent.defaultProps = {
  style: undefined,
  showTime: true,
  showCallButton: true,
  onCall: () => null,
  onContactClick: () => null,
  callButtonDisabled: false,
};

export default ScheduledCallCardComponent;
