import React, { forwardRef } from 'react';

import Typography, { TypographyVariant } from '../Typography';

import { IconTitleProps } from './IconTitle.types';
import { Styles, IconTitleWrapperDiv, IconWrapperDiv } from './IconTitle.theme';

const IconTitleComponent = forwardRef<HTMLDivElement, IconTitleProps>((props, ref) => {
  const {
    className, style,
    iconWrapperClassName,
    children, subChildren,
    icon, iconOnClick,
  } = props;

  return (
    <IconTitleWrapperDiv
      ref={ref}
      iconTitleWrapperClass={className}
      style={style}
    >
      <IconWrapperDiv iconWrapperClass={iconWrapperClassName} onClick={iconOnClick}>
        {icon}
      </IconWrapperDiv>
      <div>
        {children}
        <Typography.H4 variant={TypographyVariant.active} className={Styles.subChildren}>
          {subChildren}
        </Typography.H4>
      </div>
    </IconTitleWrapperDiv>
  );
});

IconTitleComponent.defaultProps = {
  className: undefined,
  style: undefined,
  iconWrapperClassName: undefined,
  icon: undefined,
  children: undefined,
  subChildren: undefined,
  iconMarginRight: undefined,
  iconBackgroundColor: undefined,
};

export default IconTitleComponent;
