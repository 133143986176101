import { forwardRef } from 'react';
import Skeleton from '@mui/material/Skeleton';

import { ScheduledCallsCardRowLoadingProps } from './ScheduledCallsCardRowLoading.types';
import { RowDiv, ColumnContactDetailsDiv, ColumnTimestampDiv } from './ScheduledCallsCardRowLoading.theme';

const ScheduledCallsCardRowLoadingComponent = forwardRef<HTMLDivElement, ScheduledCallsCardRowLoadingProps>((props, ref) => {
  const { id, className, style, theme } = props;

  return (
    <RowDiv
      id={id}
      ref={ref}
      style={style}
      rowClass={className}
      rowTheme={theme}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 40 }}>
          <Skeleton variant="circular" width={40} height={40} />
        </div>
        <ColumnContactDetailsDiv>
          <Skeleton variant="rectangular" width={110} height={21} />
        </ColumnContactDetailsDiv>
      </div>
      <ColumnTimestampDiv>
        <Skeleton variant="rectangular" width={78} height={24} />
      </ColumnTimestampDiv>
    </RowDiv>
  );
});

ScheduledCallsCardRowLoadingComponent.defaultProps = {
  className: undefined,
  style: undefined,
};

export default ScheduledCallsCardRowLoadingComponent;
