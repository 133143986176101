import { forwardRef, useCallback } from 'react';
import { Button } from '@mui/material';
import DialpadIcon from '@mui/icons-material/Dialpad';

import Typography, { TypographyVariant } from '../Typography';
import Icon, { IconSize } from '../Icon';
import IconButton, { IconButtonTheme } from '../IconButton';
import SearchInput, { SearchInputTheme } from '../SearchInput';

import { ContactListPersonStatus } from './private/ContactRowList';

import { CallForwardCardProps, Type } from './CallForwardCard.types';
import CallForwardCardThemer from './CallForwardCard.theme';

const CallForwardCardComponent = forwardRef<HTMLDivElement, CallForwardCardProps<any>>((props, ref) => {
  const {
    id,
    // className,
    style,
    hideHeader,
    title,
    onClose,
    onProceed,
    onInputChange,
    proceedButtonLabel,
    proceedButtonDisabled,
    inputValue,
    onSelect,
    data,
    dataTotalCounts,
    dataLoading,
    miniSkeleton,
    getId,
    getAvatarUrl,
    getAvatarFallbackLabel,
    getContactName,
    getContactStatus,
    isActive,
    isSelected,
    dialpadOpen,
    callForwardDialpadClassName,
    contentClassName,
    inputWrapperClassName,
    dialpadButtonContainerClassName,
    contactRowListClassName,
    externalNumber,
    externalNumberCallbackValue,
    showBackSpace,
    dialpadProceedButtonDisabled,
    type,
    onDialpadClicked,
    onNumberChange,
    onDialpadNumberPressed,
    onBackSpaceClicked,
    onBackButtonClicked,
    onDialpadProceed,
  } = props;

  // const classes = CallForwardCardThemer.useStyles;
  // const cardClassName = classnames(classes.card, className);
  // const headerClassName = classnames(classes.header);
  // const footerClassName = classnames(classes.footer);
  // const iconClassname = classnames(classes.closeIcon);
  // const contentClassNames = classnames(classes.contactSearchArea, contentClassName);
  // const buttonClassName = classnames(classes.button);
  // const buttonTypoGraphyClassName = classnames(classes.buttonTypography);
  // const inputClassName = classnames(classes.input);
  // const callForwardDialpadClassNames = classnames(classes.dialpad, callForwardDialpadClassName);

  const handleClose = useCallback(
    (e: any) => {
      onClose?.(e);
    },
    [onClose]
  );

  const handleProceed = useCallback(
    (e: any) => {
      onProceed?.(e);
    },
    [onProceed]
  );

  const handleDialpadProceed = useCallback(
    (e: any) => {
      onDialpadProceed?.(e);
    },
    [onDialpadProceed]
  );

  const handleSelect = useCallback(
    (value: any) => {
      onSelect?.(value);
    },
    [onSelect]
  );

  const handleChange = useCallback(
    (e: any) => {
      onInputChange?.(e);
    },
    [onInputChange]
  );

  const handleDialpadClick = useCallback(() => {
    onDialpadClicked?.();
  }, [onDialpadClicked]);

  const handleBackButtonClicked = useCallback(() => {
    onBackButtonClicked?.();
  }, [onBackButtonClicked]);

  const header = (
    <CallForwardCardThemer.CardHeader id={`${id}-CallForwardCard-Header}`} 
    // className={headerClassName}
    >
      <Typography.H1 variant={TypographyVariant.light}>{title}</Typography.H1>
      <IconButton
        id={`${id}-CallForwardCard-Header-close-icon}`}
        theme={IconButtonTheme.light}
        style={{ position: 'relative',
          float: 'right',
          top: '-40px',
          color: '#BBBBBB',
        }}
        // className={iconClassname}
        onClick={handleClose}
      >
        <Icon size={IconSize.extraLarge}>close</Icon>
      </IconButton>
    </CallForwardCardThemer.CardHeader>
  );

  const footer = (
    <CallForwardCardThemer.CardFooter id={`${id}-CallForwardCard-Footer}`} 
    // className={footerClassName}
    >
      {!dialpadOpen && (
        <>
          {/* <Buttons.DiallerCallActionButton
            id={`${id}-CallForwardCard-Footer-button-cancel}`}
            theme={CallButtonTheme.dark}
            style={classes.cancelButton}
            // className={classes.button}
            onClick={handleClose}
            buttonWidth={DiallerButtonWidth.halfLeft}
          >
            <Typography.H3 variant={TypographyVariant.light}
            style={{ color: '#FFFFFF',
              fontWeight: 600,
              fontSize: 16,
              lineHeight: '24px',
              '&:hover': {
                color: '#DADADA',
              },
              '&:active': {
                color: '#DADADA',
              },
              '&:focus': {
                color: '#DADADA',
              },}}
            // className={buttonTypoGraphyClassName}
            >
              Cancel
            </Typography.H3>
          </Buttons.DiallerCallActionButton>
          <Buttons.DiallerCallActionButton
            id={`${id}-CallForwardCard-Footer-button-proceed}`}
            theme={CallButtonTheme.dark}
            style={classes.button}
            // className={classes.button}
            onClick={handleProceed}
            disabled={proceedButtonDisabled}
            buttonWidth={DiallerButtonWidth.halfRight}
          >
            <Typography.H3 variant={TypographyVariant.light}
            style={{ color: '#FFFFFF',
              fontWeight: 600,
              fontSize: 16,
              lineHeight: '24px',
              '&:hover': {
                color: '#DADADA',
              },
              '&:active': {
                color: '#DADADA',
              },
              '&:focus': {
                color: '#DADADA',
              },}}
            // className={buttonTypoGraphyClassName}
            >
              {proceedButtonLabel}
            </Typography.H3>
          </Buttons.DiallerCallActionButton> */}


          {/* // Above Buttons.DiallerCallActionButton style fix is wrong.
          //giving a temporary fix below */}
          <Button
            sx={{
              width: '50%',
              height: '100%',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }
            }}
            onClick={handleClose}
          >
            <Typography.H6 style={{ fontSize: '16px', fontWeight: 500 }} variant={TypographyVariant.light}>Cancel</Typography.H6>
          </Button>
          <Button 
            sx={{
              width: '50%',
              height: '100%',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              '&.Mui-disabled': {
                opacity: 0.4,
              }
            }}
            onClick={handleProceed} 
            disabled={proceedButtonDisabled}
          >
            <Typography.H6 style={{ fontSize: '16px', fontWeight: 500 }} variant={TypographyVariant.light}>{proceedButtonLabel}</Typography.H6>
          </Button>
        </>
      )}
      {dialpadOpen && (
        <>
          {/* <Buttons.DiallerCallActionButton
            id={`${id}-CallForwardCard-Footer-button-back}`}
            theme={CallButtonTheme.dark}
            style={classes.cancelButton}
            // className={classes.button}
            onClick={handleBackButtonClicked}
            buttonWidth={DiallerButtonWidth.halfLeft}
          >
            <Typography.H3 variant={TypographyVariant.light}
            style={{ color: '#FFFFFF',
              fontWeight: 600,
              fontSize: 16,
              lineHeight: '24px',
              '&:hover': {
                color: '#DADADA',
              },
              '&:active': {
                color: '#DADADA',
              },
              '&:focus': {
                color: '#DADADA',
              },}}
            // className={buttonTypoGraphyClassName}
            >
              Back
            </Typography.H3>
          </Buttons.DiallerCallActionButton>
          <Buttons.DiallerCallActionButton
            id={`${id}-CallForwardCard-Footer-button-proceed}`}
            theme={CallButtonTheme.dark}
            style={classes.button}
            // className={classes.button}
            onClick={handleDialpadProceed}
            disabled={dialpadProceedButtonDisabled}
            buttonWidth={DiallerButtonWidth.halfRight}
          >
            <Typography.H3 variant={TypographyVariant.light}
            style={{ color: '#FFFFFF',
              fontWeight: 600,
              fontSize: 16,
              lineHeight: '24px',
              '&:hover': {
                color: '#DADADA',
              },
              '&:active': {
                color: '#DADADA',
              },
              '&:focus': {
                color: '#DADADA',
              },}}
            // className={buttonTypoGraphyClassName}
            >
              {proceedButtonLabel}
            </Typography.H3>
          </Buttons.DiallerCallActionButton> */}

          {/* // Above Buttons.DiallerCallActionButton style fix is wrong.
          //giving a temporary fix below */}
          <Button
            id={`${id}-CallForwardCard-Footer-button-back}`}
            sx={{
              width: '50%',
              height: '100%',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }
            }}
            onClick={handleBackButtonClicked}
          >
            <Typography.H6 style={{ fontSize: '16px', fontWeight: 500 }} variant={TypographyVariant.light}>Cancel</Typography.H6>
          </Button>
          <Button 
            id={`${id}-CallForwardCard-Footer-button-proceed}`}
            sx={{
              width: '50%',
              height: '100%',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              '&.Mui-disabled': {
                opacity: 0.4,
              }
            }}
            onClick={handleDialpadProceed}
            disabled={dialpadProceedButtonDisabled}
          >
            <Typography.H6 style={{ fontSize: '16px', fontWeight: 500 }} variant={TypographyVariant.light}>{proceedButtonLabel}</Typography.H6>
          </Button>
        </>
      )}
    </CallForwardCardThemer.CardFooter>
  );

  return (
    <CallForwardCardThemer.CardContainer id={`${id}-CallForwardCard`} ref={ref} 
    // className={cardClassName} 
    style={style}
    >
      {!hideHeader && header}
      {!dialpadOpen && (
        <CallForwardCardThemer.ContactSearchArea id={`${id}-CallForwardCard-content`} 
          className={contentClassName}
        >
          <CallForwardCardThemer.InputWrapper 
             className={inputWrapperClassName}
          // className={classnames(classes.inputWrapper, inputWrapperClassName)}
          >
            <SearchInput
              id={`${id}-CallForwardCard-content-input`}
              // className={inputClassName}
              theme={SearchInputTheme.inverse}
              value={inputValue}
              onChange={handleChange}
            />
          </CallForwardCardThemer.InputWrapper>
          {type === Type.forwardCall  && (
            <CallForwardCardThemer.DialpadButtonContainer
            className={dialpadButtonContainerClassName}
            // className={classnames(classes.dialpadButtonContainer, dialpadButtonContainerClassName)} 
            onClick={handleDialpadClick}
            >
              <DialpadIcon 
                sx={{
                  width: '18px',
                  height: '18px',
                  color: '#158BEA',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #158BEA',
                  borderRadius: '50%',
                  padding: '2px',
                }} 
              />
              <Typography.H4
              style={{ marginLeft: '12px', fontSize: '14px', fontWeight: 600,
                color: '#158BEA', lineHeight: '21px'}}
              // className={classes.dialpadText as any}
              >Use Dialpad</Typography.H4>
            </CallForwardCardThemer.DialpadButtonContainer>
          )}
        <CallForwardCardThemer.StyledContactRowList
          id={`${id}-CallForwardCard-content-list`}
          className={contactRowListClassName}
          data={data}
          dataLoading={dataLoading}
          miniSkeleton={miniSkeleton}
          dataTotalCount={dataTotalCounts}
          getId={getId}
          getAvatarUrl={getAvatarUrl}
          getAvatarFallbackLabel={getAvatarFallbackLabel}
          getContactName={getContactName}
          getContactStatus={getContactStatus}
          isActive={isActive}
          isSelected={isSelected}
          onSelect={handleSelect}
        />
      </CallForwardCardThemer.ContactSearchArea>
      )}
      {dialpadOpen  && (
        <CallForwardCardThemer.StyledDialpad
          id={`${id}-Call-Forward-Card-Dialpad`}
          className={callForwardDialpadClassName}
          number={externalNumber}
          onChange={onDialpadNumberPressed}
          onBackSpaceClicked={onBackSpaceClicked}
          callBackValue={externalNumberCallbackValue}
          showBackSpace={showBackSpace}
          onNumberChange={onNumberChange}
        />
      )}
      {footer}
    </CallForwardCardThemer.CardContainer>
  );
});

CallForwardCardComponent.defaultProps = {
  className: undefined,
  callForwardDialpadClassName: undefined,
  contentClassName: undefined,
  inputWrapperClassName: undefined,
  dialpadButtonContainerClassName: undefined,
  contactRowListClassName: undefined,
  style: undefined,
  hideHeader: false,
  title: undefined,
  proceedButtonLabel: undefined,
  inputValue: '',
  proceedButtonDisabled: false,
  onClose: () => {},
  onProceed: () => {},
  onSelect: () => {},
  onInputChange: () => {},
  data: [],
  dataTotalCounts: undefined,
  dataLoading: false,
  miniSkeleton: false,
  externalNumberCallbackValue: undefined,
  showBackSpace: false,
  dialpadProceedButtonDisabled: false,
  type: Type.forwardCall,
  onNumberChange: () => {},
  onDialpadProceed: () => {},
};

export const UserStatus = ContactListPersonStatus;
export { Type as CallType } from './CallForwardCard.types';
export default CallForwardCardComponent;
