import React, { forwardRef, useState, memo, useMemo } from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonVariant } from '../Button';

import { MessageBannerProps } from './MessageBanner.types';
import { ParentBannerDiv, MessageContainerDiv, RightSecDiv, BtnTextDiv } from './MessageBanner.theme';


const MessageBannerComponent: React.FC<MessageBannerProps> = memo(
  forwardRef<HTMLSpanElement, MessageBannerProps>(({
    bannerClassName,
    message,
    status,
    hideDismiss = false,
  }, ref) => {
    const [isBarVisible, setIsBarVisible] = useState(true);
    const memoizedIcon = useMemo(() => faTimes as IconProp, []);
  
    const handleDismissBar = () => {
      setIsBarVisible(false);
    };

    return (
      <ParentBannerDiv isBarVisible={isBarVisible}>
        <MessageContainerDiv bannerClassName={bannerClassName}>
          <div>
            {message}
          </div>
          {
            !(status === 'error' || hideDismiss) ?
              <RightSecDiv>
                <BtnTextDiv>Dismiss</BtnTextDiv>
                <Button
                  id="Message-Banner-Dismiss-Button"
                  onClick={handleDismissBar}
                  variant={ButtonVariant.text}
                >
                  <FontAwesomeIcon icon={memoizedIcon} />
                </Button>
              </RightSecDiv> : null
          }
        </MessageContainerDiv>
      </ParentBannerDiv>
    );
  })
);

export default MessageBannerComponent;
