import { styled } from "@mui/material";

export const Styles = {
  list: {
    width: '100%',
    maxWidth: 398,
    maxHeight: 60, 
    alignItems: 'center', 
    paddingTop: 0
  },
  listItem: {
    maxHeight: 72,
    paddingLeft: '24px !important',
  },
  summaryBodyWrapper: {
    position: 'relative',
  }, 
  summaryFooter: {   
    fontWeight: 400,
  },
  unavailableBanner: {
    backgroundColor: '#FF5B60',
    zIndex: 999,
  },
  homeIconStyle: {
    cursor: 'pointer',
  },
};

export const StyledHome = styled('div')({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
