import React, { forwardRef, useState, useEffect } from 'react';

import Tooltip from '../Tooltip';
import { AvatarService } from '../../Services/Avatar';
import Typography, { TypographyVariant } from '../Typography';

import { AvatarProps, Variant, Size } from './Avatar.types';
import { StyledAvatar, useFallbackTextStyles, useMultiLineTooltipStyles } from './Avatar.theme';

const resolveImgProps = (imgStyles?: React.CSSProperties) => ({ styles: imgStyles });

// We use the fallback mechanics of MaterialUI Avatar
// Check this link for more info
// https://material-ui.com/components/avatars/#fallbacks
const AvatarComponent = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const {
    id,
    alt,
    url,
    fallbackLabel,
    borderColor,
    className,
    style,
    imgStyle,
    fallbackTextClassName,
    fallbackTextStyle,
    variant,
    size,
    disableToolTip,
    isMultiLineTooltip,
  } = props;

  const [imgProps, setImgProps] = useState(resolveImgProps(imgStyle));
  const fallbackTextSizeClass = useFallbackTextStyles[size || Size.medium];
  const initials = AvatarService.getInitials(fallbackLabel);

  // Making sure we cater to updates to imgStyle
  useEffect(() => {
    setImgProps(resolveImgProps(imgStyle));
  }, [imgStyle]);

  const mergedTypographyClass = {...useFallbackTextStyles.default, ...fallbackTextSizeClass, ...fallbackTextClassName};

  const avatarNode = (
    <StyledAvatar
      id={id}
      ref={ref}
      sx={className}
      style={style}
      imgProps={imgProps as any}
      alt={alt}
      src={url ?? undefined}
      variant={variant}
      fallbackLabel={fallbackLabel}
      borderColor={borderColor}
      avatarSize={size || Size.medium}
    >
      <Typography.H6
        className={mergedTypographyClass}
        style={fallbackTextStyle}
      >
        {initials}
      </Typography.H6>
    </StyledAvatar>
  );

  const result = disableToolTip ? (
    avatarNode
  ) : (
    <Tooltip
      id={`${id}-FallbackLabel-Tooltip-Wrapper`}
      content={
        !isMultiLineTooltip ? 
        <Typography.P2 variant={TypographyVariant.light}>{fallbackLabel}</Typography.P2> :
        <p style={useMultiLineTooltipStyles.text} dangerouslySetInnerHTML={{ __html: fallbackLabel }}/>
      }
      placement="bottom"
      arrow
    >
      {avatarNode}
    </Tooltip>
  );

  return result;
});

AvatarComponent.defaultProps = {
  url: undefined,
  className: undefined,
  style: undefined,
  imgStyle: undefined,
  fallbackBackgroundColour: undefined,
  fallbackTextClassName: undefined,
  fallbackTextStyle: undefined,
  borderColor: undefined,
  variant: Variant.circular,
  size: Size.medium,
  disableToolTip: false,
  isMultiLineTooltip: false,
};

export { Variant as AvatarVariant, Size as AvatarSize } from './Avatar.types';
export default AvatarComponent;
