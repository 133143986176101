import { styled } from '@mui/material';

import { CallHistoryRowProps, Theme } from './CallHistoryRow.types';

const Background = { normal: 'rgba(255, 255, 255, 1)', inverse: 'rgba(240, 248, 255, 1)' };

interface CallHistoryRowDivProps {
  rowClassTheme?: Theme;
  showCallActionButton?: boolean;
  durationWithCallButtonClass?: React.CSSProperties
};

export const Styles = {
  row: {
    
  },

  rowNormal: { backgroundColor: Background.normal },
  statusColumn: { alignItems: 'center', justifyContent: 'center', width: 15, height: 21},
  rowInverse: { backgroundColor: Background.inverse },
  colourDot: { width: 10, height: 10, alignItems: 'center', justifyContent: 'center'},
  flagIcon: {
    marginRight: '4px !important',
    width: 24,
    height: 24,
    backgroundColor: '#50CF97',
  },
  callMediumIcon: {
    backgroundColor: '#4c94ba',
    width: 32,
    height: 32,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
  },
  timestampText: { maxWidth: '100%' },
  durationText: { maxWidth: '100%' },
  columnDurationWithCallButton: {
    flex: 1,
    minWidth: 98,
    width: 118,
    display: 'flex',
    marginLeft: '2%',
  },
  columnNavigation: {
    width: (props: CallHistoryRowProps<any>) => props.miniVersion ? 30 : 90,
    marginLeft: '3%',
  },
  showOnHover: {
    opacity: 0,
    marginLeft: '6%',
  },
  rowOnHover: {
    '&:hover': {
      cursor: 'pointer',
      '& > div:last-child': {
        opacity: 1,
      },
    },
  },
};

export const CallHistoryRowDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'rowClassTheme',
})<CallHistoryRowDivProps>(({ rowClassTheme }) => ({
  display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    paddingRight: 18,
    paddingLeft: 18,    

    '&:hover': {
      cursor: 'pointer',

      '& > .durationDiv': {
        display: 'none',
      },
      '& > .dropdownButtonDiv': {
        display: 'flex',

        // '& > div > div:last-child': { 
        //   zIndex: 10,
        // },
      },
    },
    ...(rowClassTheme === Theme.normal ? Styles.rowNormal : Styles.rowInverse),
}));

export const UseCallStatusBadgeStyles = {
  badge: { bottom: 0, right: 0 },
};

export const ColumnTimestampDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: 62,
  maxWidth: '22%',
  marginLeft: '15px',
  textAlign: 'left',
});

export const ColumnActionsDiv = styled('div')({
   minWidth: 94,
   display: 'flex',
   marginLeft: '6%',
});

export const DurationDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'durationWithCallButtonClass',
})<CallHistoryRowDivProps>(({ durationWithCallButtonClass }) => ({
  flex: 1,
  minWidth: 55,
  maxWidth: '22%',
  marginLeft: '15px',
  ...durationWithCallButtonClass
}));

export const DropdownButtonDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'showCallActionButton',
})<CallHistoryRowDivProps>(({ showCallActionButton }) => ({
  display: showCallActionButton ? 'none' : 'initial',
}));
