import React, { forwardRef, useCallback } from 'react';
import Button from '@mui/material/Button';
// import classnames from 'classnames';
import Typography, { TypographyVariant } from '../../../Typography';

import { DiallerButtonProps } from './DiallerButton.types';
import { StyledButton } from './DiallerButton.theme';

const DiallerButtonComponent = forwardRef<HTMLButtonElement, DiallerButtonProps>((props, ref) => {
  const {
    id,
    className,
    style,
    children,
    onClick,
    callbackValue,
    type,
    isSubscript,
    onMouseDown,
    onTouchStart,
    onMouseUp,
    onMouseLeave,
    onTouchEnd,
    isDisabled,
  } = props;
  // const classes = ButtonThemer.useStyles;
  // // const buttonClassName = classnames(classes.contained, classes.root, className);
  // const subScriptClassName = classnames(classes.subScriptNumber);
  const handleClick = useCallback(
    (e: any) => {
      onClick?.(callbackValue, e);
    },
    [onClick, callbackValue]
  );

  return (
    <StyledButton
      id={id}
      ref={ref}
      className={className}
      style={style}
      onClick={handleClick}
      disableRipple
      disableElevation
      disableFocusRipple
      type={type}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
      disabled={isDisabled}
    >
      <Typography.H3 variant={isDisabled ? TypographyVariant.disabled :TypographyVariant.light}
        style={{ fontSize: 16, fontWeight: 600, lineHeight: '24px' }}
      >
        {children}
      </Typography.H3>
      {isSubscript && (
        <Typography.P2 variant={isDisabled ? TypographyVariant.disabled :TypographyVariant.light}
          style={{ position: 'absolute',
                   marginBottom: '-8px', 
                   '&::before': {
                    content: '"\\A"',
                    whiteSpace: 'pre'
                  },
          }}
        //  className={subScriptClassName}
         >
          +
        </Typography.P2>
      )}
    </StyledButton>
  );
});

DiallerButtonComponent.defaultProps = {
  className: undefined,
  style: undefined,
  onClick: () => {},
  callbackValue: undefined,
  type: 'button',
  isSubscript: false,
  isDisabled: false,
};
export default DiallerButtonComponent;
