import React, { forwardRef } from 'react';
import classnames from 'classnames';

import IconButton, { IconButtonSize } from '../../../../IconButton';
import { Theme } from '../Buttons.types';

import { CallControlButtonProps } from './CallControlButton.types';
import { Styles } from './CallControlButton.theme';

const CallControlButton = forwardRef<HTMLButtonElement, CallControlButtonProps>((props, ref) => {
  const {
    id,
    className,
    style,
    size,
    theme,
    children,
    onClick,
    callbackValue,
    disabled,
    onMouseDown,
    onTouchStart,
    onMouseUp,
    onMouseLeave,
    onTouchEnd,
  } = props;

  const themeClassName = theme ? Styles[theme] : undefined;
  const mergedClass = {...themeClassName, ...Styles.common, ...className}

  return (
    <IconButton
      id={id}
      ref={ref}
      className={mergedClass}
      style={style}
      disabled={disabled}
      size={size}
      onClick={onClick}
      callbackValue={callbackValue}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
    >
      {children}
    </IconButton>
  );
});

CallControlButton.defaultProps = {
  className: undefined,
  style: undefined,
  disabled: false,
  theme: Theme.default,
  size: IconButtonSize.large,
  onClick: () => null,
  callbackValue: undefined,
};

export default CallControlButton;
