import React from 'react';

export interface AvatarProps {
  id: string;
  className?: any;
  style?: React.CSSProperties;
  url?: string | null;
  fallbackLabel: string;
  fallbackBackgroundColour?: string | null;
  alt: string;
  imgStyle?: React.CSSProperties;
  fallbackTextClassName?: React.CSSProperties;
  fallbackTextStyle?: React.CSSProperties;
  borderColor?: string;
  variant?: Variant;
  size?: Size;
  disableToolTip?: boolean;
  isMultiLineTooltip?: boolean
}

export enum Variant {
  circular = 'circular',
  rounded = 'rounded',
  square = 'square',
}

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
}
