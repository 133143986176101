import { ListItemIcon, ListItemIconProps, MenuItem, MenuItemProps, styled } from '@mui/material';

import { Theme } from './MenuItem.types';
import Colours from '../../Theme/Colours';
import FontFamily from '../../Theme/FontFamily';

interface StyledMenuItemProps extends MenuItemProps {
  styledMenuItemClass: React.CSSProperties,
  itemTheme?: Theme,
};

interface StyledListItemIconProps extends ListItemIconProps {
  styledListItemIconClass?: React.CSSProperties,
  itemTheme?: Theme,
};

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'styledMenuItemClass' && prop !== 'itemTheme',
})<StyledMenuItemProps>(({ styledMenuItemClass, itemTheme}) => ({
  minWidth: 178,
  height: 35,
  padding: '7px 12px',
  fontFamily: FontFamily,
  fontSize: 14,
  color: itemTheme === Theme.danger ? Colours.Danger.normal :
         itemTheme === Theme.dark ? Colours.Typography.light :
         Colours.Typography.active,
  backgroundColor: itemTheme === Theme.danger ? Colours.Backgrounds.dangerMenuItem :
                   itemTheme === Theme.dark ? Colours.Auxiliary.navigation :
                   Colours.Backgrounds.lightMenuItem,
  '&:hover': { 
    backgroundColor: itemTheme === Theme.danger ? Colours.Backgrounds.dangerMenuItemHovered :
                     itemTheme === Theme.dark ? Colours.Iconography.Dark.hovered :
                     Colours.Backgrounds.lightMenuItemHovered,
  },
  ...styledMenuItemClass,
}));

export const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'styledListItemIconClass' && prop !== 'itemTheme',
})<StyledListItemIconProps>(({ styledListItemIconClass, itemTheme }) => ({
  minWidth: 24,
  color: itemTheme === Theme.danger ? Colours.Danger.normal : 
         itemTheme === Theme.dark ? Colours.Typography.light :
         Colours.Typography.active,
  ...styledListItemIconClass,
}));

export const StyledSelectionWithPadding = {
  width: 'auto',
  height: 'auto',
  marginLeft: '12px',
  marginRight: '12px'
};
