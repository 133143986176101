import { forwardRef, useCallback } from 'react';
import ListItemText from '@mui/material/ListItemText';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Icon, { IconTheme } from '../Icon';

import { MenuItemProps, Theme } from './MenuItem.types';
import { StyledMenuItem, StyledListItemIcon, StyledSelectionWithPadding } from './MenuItem.theme';

const MenuItemComponent = forwardRef<HTMLLIElement, MenuItemProps>((props, ref) => {
  const {
    id,
    className,
    style,
    theme,
    value,
    leftIcon,
    leftIconClassName,
    leftIconStyle,
    children,
    callbackValue,
    onClick,
    fullWidthText,
    selected,
    disabled,
  } = props;

  const checkIconTheme = theme === Theme.dark ? IconTheme.light : IconTheme.secondary;

  const handleClick = useCallback(
    (e: any) => {
      onClick?.(e, callbackValue);
    },
    [onClick, callbackValue]
  );

  return (
    <StyledMenuItem
      id={id}
      ref={ref}
      styledMenuItemClass={className || {}}
      itemTheme={theme}
      style={style}
      value={value}
      onClick={handleClick}
      disabled={disabled}
      disableGutters
      disableRipple
      disableTouchRipple
    >
      {leftIcon && (
        <StyledListItemIcon styledListItemIconClass={leftIconClassName} style={leftIconStyle}>
          {leftIcon}
        </StyledListItemIcon>
      )}

      <ListItemText disableTypography>
        {children}

        {selected && !fullWidthText && (
          <Icon id={`${id}-SelectedIcon`} className={StyledSelectionWithPadding} theme={checkIconTheme}>
            <FontAwesomeIcon icon={faCheck as IconProp} />
          </Icon>
        )}
      </ListItemText>

      {selected && fullWidthText && (
        <Icon id={`${id}-SelectedIcon`} theme={checkIconTheme}>
          <FontAwesomeIcon icon={faCheck as IconProp} />
        </Icon>
      )}
    </StyledMenuItem>
  );
});

MenuItemComponent.defaultProps = {
  theme: Theme.normal,
  className: undefined,
  style: undefined,
  leftIconClassName: undefined,
  leftIconStyle: undefined,
  leftIcon: undefined,
  onClick: () => null,
  callbackValue: undefined,
  fullWidthText: false,
  selected: false,
  disabled: false,
};

export { Theme as MenuItemTheme } from './MenuItem.types';
export default MenuItemComponent;
