import { styled } from '@mui/material/styles';

// const useStyles = {
//   navigationDiallerContainer: {
//     display: 'flex',
//     justifyContent: 'center',
//     backgroundColor: '#001932',
//   },
// };

// export default { useStyles };

export const StyledNavigationDiallerDiv = styled('div')({ 
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#001932',
  padding: 0
});