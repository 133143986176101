import { forwardRef } from 'react';

import Typography, { TypographyVariant } from '../../Typography';

import { SummaryCardRootProps } from './SummaryCardRoot.types';
import { SummaryCardDiv, SummaryHeaderDiv, SummaryBodyDiv, SummaryFooterDiv } from './SummaryCardRoot.theme';

const SummaryCardRootComponent = forwardRef<HTMLDivElement, SummaryCardRootProps>((props, ref) => {
  const {
    id, style, 
    header, duration, children, footer,
    bodyClassName, footerClassName,
  } = props;

  return (
    <SummaryCardDiv
      ref={ref}
      style={style}
      id={id}
    >
      <SummaryHeaderDiv>
        {header}
        {duration && (
          <div>
            <Typography.H3 variant={TypographyVariant.active}>{duration}</Typography.H3>
          </div>
        )}
      </SummaryHeaderDiv>
      <SummaryBodyDiv summaryBodyClass={bodyClassName}>
       {children}
      </SummaryBodyDiv>
      {
        footer && 
        <SummaryFooterDiv summaryFooterClass={footerClassName}>
          {footer}
        </SummaryFooterDiv>
      }
    </SummaryCardDiv>
  );
});

SummaryCardRootComponent.defaultProps = {
  bodyClassName: undefined,
  footerClassName: undefined,
  style: undefined,
  header: undefined,
  duration: undefined,
  children: undefined,
  footer: undefined,
};

export default SummaryCardRootComponent;
