import { AvatarGroup, AvatarGroupProps, styled } from '@mui/material';

import Colours from '../../Theme/Colours';
import FontFamily from '../../Theme/FontFamily';

// import { AvatarGroupProps } from './AvatarGroup.types';

export const Styles = {
  avatarGroup: {
    position: 'relative',
    width: 'fit-content',
  },
 };

interface StyledAvatarGroupProps extends AvatarGroupProps {
  avatarGroupClass?: any;
};

interface OverflowCountWrapperDivProps {
  overflowCountHidden?: boolean
  dataLength: number
};

export const StyledAvatarGroup = styled(AvatarGroup, {
  shouldForwardProp: (prop) => prop !== 'avatarGroupClass',
})<StyledAvatarGroupProps>(({ avatarGroupClass }) => ({
  '& .MuiAvatarGroup-avatar': {
    width: 24,
    height: 24,
    fontSize: 12,
    fontWeight: 600,
    fontFamily: FontFamily,
    border: 'none',
  },
  ...avatarGroupClass,
}));

export const OverflowCountWrapperDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'overflowCountHidden' && prop !== 'dataLength',
})<OverflowCountWrapperDivProps>(({ overflowCountHidden, dataLength }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  display: overflowCountHidden ? 'none' : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colours.Secondary.Green.normal,
  zIndex: `${dataLength + 1} !important` as any,
}));

export const BottomIconDiv =  styled('div')({
  position: 'absolute',
  zIndex: '12 !important' as any,
  right: -4,
  top: 15,
  background: '#fff',
  boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '14px !important',
  width: '14px !important',

  '& > svg' : {
    fontSize: 10,
  }
});