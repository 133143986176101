import { Card, CardContent, CardProps, FormControlLabel, Radio, styled } from '@mui/material';
import Colours from '../../Theme/Colours';

interface StyledCardProps extends CardProps {
  styledCardClass: React.CSSProperties;
};

export const Styles = {
  card: {
    width: '100%',
    maxWidth: 400,
    minWidth: 400,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FCFBFA',
    height: 60,
    paddingLeft: 15,
    paddingRight: 15,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    marginRight: 12,
    borderRadius: 50,
    backgroundColor: '#50CF97',
    '& > svg': {
      fontSize: 14,
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 16,
    fontWeight: 500,
    marginRight: 4,
  },
  bodyContainer: {
    padding: '2px 15px 15px 15px',
    maxWidth: 400,
  },
  footerContainer: {
    backgroundColor: 'red',
  },
  wariningTextContainer: {
    width: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'left',
    padding: '0px 15px 0px 15px',
  },
  icon: {
    color: '#D1D4D8',
    fontSize: 16,
  },
  iconChecked: {
    color: Colours.Typography.primary,
    fontSize: 16,
  },
};

export const StyledCardButton = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'styledCardClass',
})<StyledCardProps>(({ styledCardClass }) => ({
  borderRadius: 0,
  margin: 'auto',
  background: Colours.Backgrounds.white,
  boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
  ...styledCardClass
}));

export const StyledCardContent = styled(CardContent)({
  padding: 0,
  '&:last-child': {
    padding: 0,
  },
});

export const StyledRadio = styled(Radio)({
  color: '#D1D4D8',
  paddingRight: 12,
  '&:hover': {
    backgroundColor: 'unset',
  },
  '&.Mui-checked': {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  fontFamily: 'Lato !important',
  fontStyle: 'normal !important',
  fontSize: '14px !important',
  lineHeight: '21px !important',
  color: '#555555',
});