import React, { forwardRef, useCallback } from 'react';
import Card from '@mui/material/Card';
import classnames from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faHashtag } from '@fortawesome/pro-solid-svg-icons';

import Numberpad from '../../NavigationDialler/private/NumberPad';
import DiallerButton from '../Private/DiallerButton';

import { DialpadProps } from './Dialpad.type';
import DialpadThemer from './Dialpad.theme';
import { useLongPress } from './Dialpad.hooks';

const itemPlaceholders = Array.from(new Array(9));

const defaultOptions = {
  shouldPreventDefault: true,
  delay: 500,
};

const DialpadComponent = forwardRef<HTMLDivElement, DialpadProps>((props, ref) => {
  const { id, className, style, number, onChange, showBackSpace, onBackSpaceClicked, callBackValue, onNumberChange } = props;
  const classes = DialpadThemer.useStyles;
  const cardClassName = classnames(classes.card, className);
  const diallerButtonClassName = classnames(classes.diallerButton);
  const diallerButtonAreaClassName = classnames(classes.buttonArea);

  const handleChange = useCallback(
    (value: any, e: any) => {
      e.persist();
      onChange?.(value, e);
    },
    [onChange]
  );

  const handleBackSpaceClick = useCallback(
    (value: any, e: any) => {
      onBackSpaceClicked?.(value, e);
    },
    [onBackSpaceClicked, callBackValue]
  );

  const longPressEvent = useLongPress(handleChange, handleChange, defaultOptions, '0', '+');

  return (
    // <Card id={id} ref={ref} className={cardClassName} style={style}>
    <DialpadThemer.StyledCard id={id} ref={ref} style={style} className={className}>
      <Numberpad
        id={`${id}-numberpad`}
        style={classes.numberPadContainer as any}
        number={number}
        onBackspaceClick={handleBackSpaceClick}
        callbackValue="backspace"
        showBackSpace={!!showBackSpace}
        onNumberChange={onNumberChange}
      />
      <DialpadThemer.ButtonArea>
       <div>
        {itemPlaceholders.map((item, index) => (
          <DialpadThemer.StyledDiallerButton
            id={`${id}-DialButton-${index + 1}`}
            key={`${id}-DialButton-${index + 1}`}
            // className={diallerButtonClassName}
            callbackValue={`${index + 1}`}
            onClick={handleChange}
          >
            {index + 1}
          </DialpadThemer.StyledDiallerButton>
        ))}
        <DialpadThemer.StyledDiallerButton id={`${id}-DialButton-*`} callbackValue="*" onClick={handleChange}>
          <FontAwesomeIcon icon={faAsterisk  as IconProp} />
        </DialpadThemer.StyledDiallerButton>
        <DialpadThemer.StyledDiallerButton id={`${id}-DialButton-0`} isSubscript callbackValue="0" {...longPressEvent}>
          0
        </DialpadThemer.StyledDiallerButton>
        <DialpadThemer.StyledDiallerButton id={`${id}-DialButton-#`} callbackValue="#" onClick={handleChange}>
          <FontAwesomeIcon icon={faHashtag  as IconProp} />
        </DialpadThemer.StyledDiallerButton>
        </div>
      </DialpadThemer.ButtonArea>
    </DialpadThemer.StyledCard>
  );
});

DialpadComponent.defaultProps = {
  className: undefined,
  style: undefined,
  number: undefined,
  showBackSpace: false,
  onChange: () => null,
  onNumberChange: () => null,
};

export default DialpadComponent;
