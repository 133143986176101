import React from 'react';

export interface CallStatusBadgeProps {
  id: string;
  classes?: any;
  className?: React.CSSProperties | undefined;
  style?: React.CSSProperties;
  children: React.ReactNode;
  statusType: StatusType,
  callStatusTooltip?: string,
};

export enum StatusType {
  inboundMissed = 'inbound-missed',
  inboundReceived = 'inbound-received',
  outboundMissed = 'outbound-missed',
  outboundReceived = 'outbound-received',
};

export enum Placement {
  default = 'default',
  topRight = 'top-right',
  bottomRight = 'bottom-right',
  bottomLeft = 'bottom-left',
  topLeft = 'top-left',
};

export { Theme, ThemeMuiColourMap } from '../Badge/Badge.types';
