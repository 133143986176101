import { styled } from "@mui/material";

export const Styles = {
  title: {
    color: '#222222',
    textAlign: 'start',
  },
};


export const SummaryEmptyBodyDiv =  styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginTop: 20,
});

export const EmptyStateImageContainerDiv =  styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: 12,
});
