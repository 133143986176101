import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import Buttons from '../Call/Private/Buttons';
import ContactRowList from './private/ContactRowList';
import Dialpad from '../Call/Dialpad';
import SearchInput from '../SearchInput';
import Colours from '../../Theme/Colours';

const CardContainer = styled(Card)({
  minWidth: '418px',
  maxWidth: '418px',
  minHeight: '593px',
  backgroundColor: '#001932',
  boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.15)',
  borderRadius: '0px',
});

const CardHeader = styled(Card)({
  height: '84px',
  background: 'rgba(255, 255, 255, 0.15)',
  padding: '30px 24px',
  borderRadius: '24px 24px 0px 0px',
});

const CardFooter = styled(Card)({
  height: '72px',
  background: 'rgba(255, 255, 255, 0.1)',
  // borderRadius: '0px 0px 24px 24px',
});

const ContactSearchArea = styled(Card)({
  // height: '84px',
  // color: 'FFFFFF',
  // background: '#001932',
  // padding: '12px 12px 30px 4px',
  // borderRadius: '24px 24px 0px 0px', // Copied style from Parent component
  // flex: 1,
  minWidth: '264px !important',
  borderRadius: '4px !important',
  minHeight: '414px !important',
  maxHeight: '486px !important',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.15)',
  backgroundColor: '#001932',
});

const InputWrapper = styled('div')({
  margin: '24px 12px !important'
});

const StyledSearchInput = styled(SearchInput)({
});

const StyledButton = styled(Buttons.DiallerCallActionButton)({
  textAlign: 'center',
  width: '50%',
  background: 'rgba(255, 255, 255, 0.1)',
  '&:disabled': {
    background: 'rgba(255, 255, 255, 0.1)',
    opacity: '50%',
    color: '#FFFFFF',
  },
  '&:hovered': {
    backgound: Colours.Iconography.Dark.hovered,
  },
});

const DialpadButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '12px',
  marginBottom: 9,
  minWidth: '418px',
  maxWidth: '418px',
  backgroundColor: '#001932',

  '&:hover': {
    cursor: 'pointer',
    '& > h4': { color: '#0257D7' },
    '& > span': { color: '#FFFFFF', borderColor: '#0257D7', backgroundColor: '#0257D7' },
  },
  '&:active': {
    '& > h4': { color: '#00409F' },
    '& > span': { color: '#FFFFFF', borderColor: '#00409F', backgroundColor: '#00409F' },
  },
  '&:focus': {
    '& > h4': { color: '#00409F' },
    '& > span': { color: '#FFFFFF', borderColor: '#00409F', backgroundColor: '#00409F' },
  },
});

const StyledContactRowList = styled(ContactRowList)({
  paddingLeft: '6px !important',
  maxHeight: '260px !important',
  textAlign: 'initial',
});

const StyledDialpad = styled(Dialpad)({
  // maxWidth: 408,
  // minHeight: 437,

  // '& > div:first-child': { maxWidth: 408, height: 72 },
  // '& > div:last-child': {
  //   width: 252,
  //   margin: '9px 78px',

  //   '& > button': { margin: '15px 18px' },  // Copied style from Parent component
  // },
    minHeight: '384px !important',
    borderRadius: '0px',
    flex: 1,

    '& > div:first-child': {
      height: '60px !important',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '0px',
    },
    '& > div:nth-of-type(2)': {
      width: 'auto !important',
      margin: '38px 62px !important',

      '& > button': { margin: '9px 12px !important' },
    },
});

const useStyles = {
  // card: {
  //   minWidth: '408px',
  //   maxWidth: '408px',
  //   minHeight: '593px',
  //   backgroundColor: '#001932',
  //   boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.15)',
  //   borderRadius: '24px',
  // },
  // header: {
  //   height: '84px',
  //   background: 'rgba(255, 255, 255, 0.15)',
  //   padding: '30px 24px',
  //   borderRadius: '24px 24px 0px 0px',
  // },
  // footer: {
  //   height: '72px',
  //   background: 'rgba(255, 255, 255, 0.1)',
  //   // borderRadius: '0px 0px 24px 24px',
  // },
  // contactSearchArea: {
  //   minHeight: 414,
  //   height: 414,
  //   maxHeight: 414,
  //   background: 'transparent',
  // },
  // closeIcon: {
  //   position: 'relative',
  //   float: 'right',
  //   top: '-40px',
  //   '& > *': {
  //     color: '#BBBBBB',
  //   },
  // },
  cancelButton: {
    textAlign: 'center',
    width: '50%',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '0px',
    height: '72px',
    lineHeight: '24px',
    '&:disabled': {
      background: 'rgba(255, 255, 255, 0.1)',
      opacity: '50%',
      color: '#FFFFFF',
    },
    '&:hovered': {
      backgound: Colours.Iconography.Dark.hovered,
    },
  },

  button: {
    textAlign: 'center',
    width: '50%',
    opacity: '50%',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '0px',
    height: '72px',
    lineHeight: '24px',
    '&:disabled': {
      background: 'rgba(255, 255, 255, 0.1)',
      color: '#FFFFFF',
    },
    '&:hovered': {
      backgound: Colours.Iconography.Dark.hovered,
    },
  },
  // buttonTypography: {
  //   color: '#FFFFFF',
  //   '&:hover': {
  //     color: '#DADADA',
  //   },
  //   '&:active': {
  //     color: '#DADADA',
  //   },
  //   '&:focus': {
  //     color: '#DADADA',
  //   },
  // },
  // inputWrapper: {
  //   margin: '24px',
  // },
  // input: {},
  // dialpadButtonContainer: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   paddingLeft: 30,
  //   marginBottom: 9,

  //   '&:hover': {
  //     cursor: 'pointer',
  //     '& > h4': { color: '#0257D7' },
  //     '& > span': { color: '#FFFFFF', borderColor: '#0257D7', backgroundColor: '#0257D7' },
  //   },
  //   '&:active': {
  //     '& > h4': { color: '#00409F' },
  //     '& > span': { color: '#FFFFFF', borderColor: '#00409F', backgroundColor: '#00409F' },
  //   },
  //   '&:focus': {
  //     '& > h4': { color: '#00409F' },
  //     '& > span': { color: '#FFFFFF', borderColor: '#00409F', backgroundColor: '#00409F' },
  //   },
  // },
  // dialpadIcon: {
  //   width: 24,
  //   height: 24,
  //   color: '#158BEA',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   border: '1px solid #158BEA',
  //   borderRadius: '50%',
  // },
  // dialpadText: {
  //   marginLeft: 12,
  //   color: '#158BEA',
  // },
  // numberPad: {
  //   backgroundColor: 'rgba(255, 255, 255, 0.1)',
  //   borderRadius: 0,
  //   height: '100%',
  //   maxHeight: 72,
  //   minHeight: 60,
  //   padding: 0,
  //   minWidth: 'unset',
  //   width: '100%',
  // },
  // dialpad: {
  //   maxWidth: 408,
  //   minHeight: 437,

  //   '& > div:first-child': { maxWidth: 408, height: 72 },
  //   '& > div:last-child': {
  //     width: 252,
  //     margin: '9px 78px',

  //     '& > button': { margin: '15px 18px' },
  //   },
  // },
};

export default { useStyles, CardHeader, CardContainer, CardFooter, ContactSearchArea, InputWrapper,
  StyledButton, DialpadButtonContainer, StyledContactRowList, StyledDialpad
 };
