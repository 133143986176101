import React, { forwardRef, useEffect, useCallback } from 'react';
import Moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHouseDay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faHome, faMobile, faCity } from '@fortawesome/pro-solid-svg-icons';

import MenuItem from '../MenuItem';
import MessageBanner from '../MessageBanner';
import { CallHistoryCallDirection } from '../CallHistory';
import IconButton, { IconButtonTheme } from '../IconButton';
import Typography, { TypographyVariant } from '../Typography';
import SummaryCardCallHistory from '../SummaryCardCallHistory';
import { Type } from '../CallActionAvatar/CallActionAvatar.types';
import SummaryCardScheduledCalls from '../SummaryCardScheduledCalls';
import SummaryCardEmptyStateComponent from '../SummaryCardEmptyState';
import CallActionAvatar, { CallActionAvatarType, CallActionAvatarVariant } from '../CallActionAvatar';
import SaleforceService  from '../../Services/SaleforceService';
import OutboundCallService from '../../Services/Call/OutboundCall';
import CommunicationService from '../../Services/CommunicationService';
import { getScheduledCallsInitState } from '../../slices/scheduledCalls';
import { getCallHistoryInitState } from '../../slices/callHistory';
import GetAvailabilityStatus from '../../models/Availability';
import { CallRecordWrapUpStatusEnum } from '../../Enums/CallActivityActionType';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CallBarState } from '../../constants/CallBarState';

import { HomeProps } from './Home.types'
import { Styles, StyledHome } from './Home.theme';

const resolveCallEventContactName = (data :any) =>
  `${data.contactDetails?.firstName || ''} ${data.contactDetails?.lastName || ''}`.trim();

enum CallActivityActionType {
  Opportunity = 'Opportunity',
  Case = 'Case',
  Note = 'Note',
  Vulnerability = 'Vulnerability',
  GDPRSetting = 'GDPRSetting',
  ScheduleCallback = 'ScheduleCallback',
  Voicemail = 'Voicemail',
  SMS = 'Sms',
  Email = 'Email',
  CallOutcome = 'CallOutcome',
}

interface CallActionAvatarTypeAction {
  [CallActivityActionType.Opportunity]: CallActionAvatarType;
  [CallActivityActionType.Case]: CallActionAvatarType;
  [CallActivityActionType.Note]: CallActionAvatarType;
  [CallActivityActionType.Vulnerability]: CallActionAvatarType;
  [CallActivityActionType.GDPRSetting]: CallActionAvatarType;
  [CallActivityActionType.ScheduleCallback]: CallActionAvatarType;
  [CallActivityActionType.Voicemail]: CallActionAvatarType;
  [CallActivityActionType.SMS]: CallActionAvatarType;
  [CallActivityActionType.Email]: CallActionAvatarType;
  [CallActivityActionType.CallOutcome]: CallActionAvatarType;
}
interface CallActionAvatarTypeLabel {
  [CallActivityActionType.Opportunity]: string;
  [CallActivityActionType.Case]: string;
  [CallActivityActionType.Note]: string;
  [CallActivityActionType.Vulnerability]: string;
  [CallActivityActionType.GDPRSetting]: string;
  [CallActivityActionType.ScheduleCallback]: string;
  [CallActivityActionType.Voicemail]: string;
  [CallActivityActionType.SMS]: string;
  [CallActivityActionType.Email]: string;
  [CallActivityActionType.CallOutcome]: string;
}

const CallActionAvatarTypeActionTypeMap: CallActionAvatarTypeAction = {
  [CallActivityActionType.Opportunity]: CallActionAvatarType.opportunity,
  [CallActivityActionType.Case]: CallActionAvatarType.case,
  [CallActivityActionType.Note]: CallActionAvatarType.note,
  [CallActivityActionType.Vulnerability]: CallActionAvatarType.vulnerability,
  [CallActivityActionType.GDPRSetting]: CallActionAvatarType.gdpr,
  [CallActivityActionType.ScheduleCallback]: CallActionAvatarType.callback,
  [CallActivityActionType.Voicemail]: CallActionAvatarType.voicemail,
  [CallActivityActionType.Email]: CallActionAvatarType.email,
  [CallActivityActionType.SMS]: CallActionAvatarType.sms,
  [CallActivityActionType.CallOutcome]: CallActionAvatarType.callOutcome,
};

const CallActionAvatarTypeLabelMap: CallActionAvatarTypeLabel = {
  [CallActivityActionType.Opportunity]: 'Opportunity',
  [CallActivityActionType.Case]: 'Case Created',
  [CallActivityActionType.Note]: 'Note',
  [CallActivityActionType.Vulnerability]: 'Vulnerability',
  [CallActivityActionType.GDPRSetting]: 'GDPR',
  [CallActivityActionType.ScheduleCallback]: 'Schedule Callback',
  [CallActivityActionType.Voicemail]: 'Voicemail',
  [CallActivityActionType.Email]: 'Email',
  [CallActivityActionType.SMS]: 'SMS',
  [CallActivityActionType.CallOutcome]: 'Call outcome',
};

const TypeBackgroundColourMap = {
  default: '#5778EE',
  [CallActivityActionType.Opportunity]: '#50CF97',
  [CallActivityActionType.Case]: '#5778EE',
  [CallActivityActionType.Note]: '#5778EE',
  [CallActivityActionType.GDPRSetting]: '#F3605A',
  [CallActivityActionType.Vulnerability]: '#FF7C80',
  [CallActivityActionType.ScheduleCallback]: '#5869E2',
  [CallActivityActionType.Voicemail]: '#009FD9',
  [CallActivityActionType.Email]: '#158BEA',
  [CallActivityActionType.SMS]: '#158BEA',
  [Type.incomplete]: '#50CF97',
  [Type.hotTransfer]: '#009FD9',
  [CallActivityActionType.CallOutcome]: '#009FD9',
};

const resolveDuration = (durationInSeconds: number = 0): string => {
  let result = '-';
  if (Number.isNaN(durationInSeconds) || durationInSeconds === 0) return result;
  const hours: number = parseInt((durationInSeconds / 3600).toString(), 10);
  const minutes: number = parseInt(((durationInSeconds - (3600 * hours)) / 60).toString(), 10);
  const seconds = durationInSeconds % 60;

  const hoursText = hours > 0 ? `${hours}h` : '';
  const minutesText = minutes > 0 ? `${minutes}m` : '';
  const secondsText = `${seconds}s`;

  if (hours > 0) {
    result = `${hoursText} ${minutesText}`.trim();
  } else {
    result = `${minutesText} ${secondsText}`.trim();
  }

  return result;
};

const callActions = (item: any): CallActivityActionType[] => {
  const result: CallActivityActionType[] = item?.content?.actionTypes?.filter((action: CallActivityActionType) => CallActionAvatarTypeLabelMap[action]);   
  
  if (item?.callOutcome) {
    result.unshift(CallActivityActionType.CallOutcome);
  }

  return result;
};

const overflowTooltip = (overflowItems: CallActivityActionType[]): JSX.Element => (
  <div>
    {overflowItems.map((item: CallActivityActionType) => (
      <div
        id={`Action-${item}-${uuidv4()}`}
        key={`Action-${item}`}
        style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1px 0' }}
      >
        <CallActionAvatar
          id={`Action-TooltipItem-${item}-${uuidv4()}`}
          key={`Action-TooltipItem-${item}`}
          variant={CallActionAvatarVariant.light} // Adjust variant type based on the actual type
          type={CallActionAvatarTypeActionTypeMap[item]}
        />

        <Typography.P2 variant={TypographyVariant.light}>
          {CallActionAvatarTypeLabelMap[item]}
        </Typography.P2>
      </div>
    ))}
  </div>
);

const HomeComponent: React.FC<HomeProps> = forwardRef<HTMLDivElement, HomeProps>((props, ref) => {
  const { onCallWrapup } = props;
  const authData = useAppSelector((state:any) => state.auth);
  const settingsData = useAppSelector((state:any) => state.settings);
  const userInfo = useAppSelector((state:any) => state.auth.user);
  const dispatch = useAppDispatch();
  const isCallDispositionNotMandatory = settingsData?.tenantSettings?.tenantCallSettings?.isCallDispositionNotMandatory;

  // Scheduled Calls
  const scheduleCallsData = useAppSelector((state:any) => state.scheduledCalls.data) || [];
  const scheduleCallsDataLoading = useAppSelector((state:any) => state.scheduledCalls.loading);
  const scheduleCallsDataCount = scheduleCallsData.length || 0;
  const callBarStateData = useAppSelector((state: any) => state.callCentre.callBarState);
  const isCurrentCallInHold = useAppSelector((state: any) => state.callCentre.isCurrentCallInHold);

  //CallHistory
  const callHistoryData = useAppSelector((state:any) => state.callHistory.data) || [];
  const callHistoryDataLoading = useAppSelector((state:any) => state.callHistory.loading);
  const callHistoryDataCount = callHistoryData.length || 0;

  const isOnline = useAppSelector((state: any) => state.networkConnection?.connection);
  
  const handleCallActions = (event: any, callAction: any) => {
    const callEventId = callAction?.callEvent?.callEventId;
    const contactNo = callAction?.contactNumber;
    const dialFromNumber = CommunicationService.getOutgoingPhoneNumber();

    event.stopPropagation();
    OutboundCallService.initiateOutboundCallWithGdpr(
      authData, settingsData,
      callAction?.contactDetails, 
      contactNo,
      dialFromNumber, 
      callEventId
    );
  };

  const resolveCallActionMenuItems = (
    itemId: any,
    contactDetails: any,
    contactPhoneNumber: any,
    contactMobileNumber: any,
    contactCompanyNumber: any,
    callEvent: any
  ) => {
    const result = [];
    if (contactPhoneNumber) {
      const phoneNumberItem = (
        <MenuItem
          id="CallOptionButton-PhoneNumberItem"
          key={`${itemId}-${contactPhoneNumber}`}
          leftIcon={<FontAwesomeIcon icon={faHome as IconProp} />}
          callbackValue={{ contactDetails, contactNumber: contactPhoneNumber, callEvent }}
          onClick={handleCallActions}
          selected
        >
          {contactPhoneNumber}
        </MenuItem>
      );
      result.push(phoneNumberItem);
    }

    if (contactMobileNumber) {
      const phoneNumberItem = (
        <MenuItem
          id="CallOptionButton-MobileNumberItem"
          key={`${itemId}-${contactMobileNumber}`}
          leftIcon={<FontAwesomeIcon icon={faMobile as IconProp} />}
          callbackValue={{ contactDetails, contactNumber: contactMobileNumber, callEvent }}
          onClick={handleCallActions}
          selected={!contactPhoneNumber}
        >
          {contactMobileNumber}
        </MenuItem>
      );
      result.push(phoneNumberItem);
    }

    if (contactCompanyNumber) {
      const companyNumberItem = (
        <MenuItem
          id="CallOptionButton-CompanyNumberItem"
          style={{ textAlign: 'start' }}
          key={`${itemId}-${contactCompanyNumber}`}
          leftIcon={<FontAwesomeIcon icon={faCity as IconProp} />}
          callbackValue={{ contactDetails, contactNumber: contactCompanyNumber, callEvent }}
          onClick={handleCallActions}
          selected={!contactPhoneNumber && !contactMobileNumber}
        >
          {contactCompanyNumber}
        </MenuItem>
      );
      result.push(companyNumberItem);
    }

    return result;
  };

  useEffect(() => {
    dispatch(getScheduledCallsInitState({ listData: [], loading: true }));
    SaleforceService.getScheduledCalls();

    if (!isCurrentCallInHold) {
      dispatch(getCallHistoryInitState({ listData: [], loading: true }));
      SaleforceService.getCallHistory();
    } else {
      // time out for getting the latest call record after call ended
      const timer = setTimeout(() => {
        dispatch(getCallHistoryInitState({ listData: [], loading: true }));
        SaleforceService.getCallHistory();
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, isCurrentCallInHold]);

  const handleCallButtonClicked = (event: any) => {
    const callEventId = event?.callEventId;
    const contactNo = event?.contactDetails?.phone || event?.contactDetails?.mobilePhone;
    const dialFromNumber = CommunicationService.getOutgoingPhoneNumber();

    OutboundCallService.initiateOutboundCallWithGdpr(
      authData, settingsData,
      event?.contactDetails, 
      contactNo,
      dialFromNumber, 
      callEventId
    );
  };

  const resolveActionNode = (action: CallActivityActionType, style: any, item: any): JSX.Element => {
    const style1 = style;
    const style2 = { backgroundColor: TypeBackgroundColourMap[action] };
    const combinedStyles = { ...style1, ...style2 };

    return (
      <CallActionAvatar
        id={`Action-${action}-${uuidv4()}`}
        key={`Action-${action}`}
        style={combinedStyles}
        type={CallActionAvatarTypeActionTypeMap[action]}
        callOutcome={item?.callOutcome || ''}
      />
    );
  };

  const handleCallHistoryAvatarClick = useCallback((callHistoryData: any) => {
    SaleforceService.navigateToObject(callHistoryData?.contactDetails?.remoteId);
  }, []);

  const handleCallHistoryRowClick = useCallback((callHistoryData: any) => {
    SaleforceService.navigateToObject(callHistoryData?.remoteId)
  }, []);

  const handleScheduleCallsRender = useCallback(() => {
    dispatch(getScheduledCallsInitState({ listData: [], loading: true }));
    SaleforceService.getScheduledCalls();
  }, [dispatch]);

  const handleCallHistoryRender = useCallback(() => {
    dispatch(getCallHistoryInitState({ listData: [], loading: true }));
    SaleforceService.getCallHistory();
  }, [dispatch]);

  const handleHomePageRender = useCallback(() => {
    dispatch(getScheduledCallsInitState({ listData: [], loading: true }));
    SaleforceService.getScheduledCalls();

    dispatch(getCallHistoryInitState({ listData: [], loading: true }));
    SaleforceService.getCallHistory();
  }, [dispatch]);

  const renderInternetConnectionStatusBanner = () => {
    const message = (
      <Typography.H5
        style={{ fontSize: 14, fontWeight: 500 }}
        variant={TypographyVariant.light}
      >
        No Internet Connection
      </Typography.H5>
    );

    return (
      <MessageBanner
        bannerClassName={Styles.unavailableBanner}
        message={message}
        status="warn"
        hideDismiss
      />
    )
  };

  const renderUserUnavailableBanner = () => {
    const message = (
      <Typography.H5
        style={{ fontSize: 14, fontWeight: 500 }}
        variant={TypographyVariant.light}
      >
        Your status is set to unavailable and you will not receive inbound calls. Calls will be routed according to your team’s routing settings.
      </Typography.H5>
    );

    return (
      <MessageBanner
        bannerClassName={Styles.unavailableBanner}
        message={message}
        status="warn"
        hideDismiss
      />
    )
  };

  const renderUserOnCallBanner = () => {
    const message = (
      <Typography.H5
        style={{ fontSize: 14, fontWeight: 500 }}
        variant={TypographyVariant.light}
      >
        It seems you were in a call that needs to be closed before you can make any further calls.&nbsp;
        <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={onCallWrapup}>Click here</span> to wrap things up.
      </Typography.H5>
    );

    return (
      <MessageBanner
        bannerClassName={Styles.unavailableBanner}
        message={message}
        status="warn"
        hideDismiss
      />
    );
  };

  const isUserUnavailable = GetAvailabilityStatus(userInfo?.userStatus, settingsData?.tenantSettings?.userStatuses);
  const unWrapedCallAvailable = authData.userWrapUpStatus?.status === CallRecordWrapUpStatusEnum.Pending && callBarStateData === CallBarState.IDLE;
  const wrapupLoading = authData.userWrapUpStatus?.loading;

  return (
    <StyledHome>
      {!isOnline && renderInternetConnectionStatusBanner()}
      {isUserUnavailable && renderUserUnavailableBanner()}
      {(unWrapedCallAvailable && !wrapupLoading && !isCallDispositionNotMandatory) && renderUserOnCallBanner()}
      <List sx={Styles.list}>
        <ListItem sx={Styles.listItem}>
          <ListItemAvatar>
            <Avatar sx={{ p: 0, bgcolor: 'rgb(246, 157, 27)', width: 36, height:36, }}>
              {(scheduleCallsDataLoading && callHistoryDataLoading) && (
                  <FontAwesomeIcon
                    icon={faHouseDay as IconProp}
                    color='white'
                    style={{ cursor: 'pointer' }}
                    onClick={handleHomePageRender} bounce
                  />
              )}
              {(!scheduleCallsDataLoading || !callHistoryDataLoading) && (
                  <FontAwesomeIcon
                    icon={faHouseDay as IconProp}
                    color='white'
                    style={{ cursor: 'pointer' }}
                    onClick={handleHomePageRender}
                  />
              )}
            </Avatar> 
          </ListItemAvatar>
          <ListItemText>
            <h3 style={{color:"black"}}>Welcome {userInfo?.firstName}!</h3>
          </ListItemText>
        </ListItem>
      </List>
      <SummaryCardScheduledCalls
        id="ScheduledCalls"
        getId={(scheduleCallsData) => (scheduleCallsData?.callEventId)}
        getDate={(scheduleCallsData) => (!scheduleCallsData?.scheduledTime ? '-' : Moment(scheduleCallsData?.scheduledTime).format('DD/MM/YYYY'))}
        getTime={(scheduleCallsData) => ('')}
        getPhotoUrl={() => null}
        getPhotoFallbackLabel={(scheduleCallsData) => resolveCallEventContactName(scheduleCallsData)}
        getName={(scheduleCallsData) => resolveCallEventContactName(scheduleCallsData)}
        getCompanyName={(scheduleCallsData) => scheduleCallsData?.contactDetails?.account?.name || ''}
        getCampaignColour={scheduleCallsData => scheduleCallsData?.campaignColour || '#4287f5'}
        getCampaignName={(scheduleCallsData) => scheduleCallsData?.campaignName || ''}
        onCall={handleCallButtonClicked}
        data={scheduleCallsData || []}
        onViewMoreClick={() => { console.log('view more'); } }
        dataLoading={scheduleCallsDataLoading}
        scheduledCallsCount={scheduleCallsDataCount}
        getMenuItems={(callEvent) =>
          resolveCallActionMenuItems(
            callEvent.id,
            callEvent?.contactDetails,
            callEvent?.contactDetails?.phone,
            callEvent?.contactDetails?.mobilePhone,
            callEvent?.contactDetails?.account?.phone,
            callEvent,
          )}
          bottomHitThreshold={200}
        footerClassName={Styles.summaryFooter}
        bodyClassName={Styles.summaryBodyWrapper}
        onContactClick={(scheduleCallsData) => {
          SaleforceService.navigateToObject(scheduleCallsData?.contactDetails?.remoteId);
        }}
        onScheduleRowClick={(scheduleCallsData) => { SaleforceService.navigateToObject(scheduleCallsData?.remoteId) }}
        callButtonDisabled={false}
        isUnknownContact={(scheduleCallsData) => scheduleCallsData?.contactDetails?.isUnknownContact} 
        title={"Upcoming Calls"} 
        onNextPageRequested={() => { console.log('not done'); }} 
        scheduleIconOnClick={handleScheduleCallsRender}   
      >
        {(!scheduleCallsDataLoading && scheduleCallsData?.length === 0) && (
          <SummaryCardEmptyStateComponent
            id="SummaryCardScheduledCalls-Empty-states"
            style={{ padding: '0px 18px' }}
            title="Nothing to see here!"
            paragraph="No scheduled calls happening here..."
            height={152}
          />
        )}
      </SummaryCardScheduledCalls>
      <br />
      <SummaryCardCallHistory
        id="SummaryCallHistory"
        title={"My Call History"}
        data={callHistoryData || []}
        dataTotalCount={callHistoryDataCount}
        dataLoading={callHistoryDataLoading}
        bottomHitThreshold={200}
        onNextPageRequested={() => { console.log('not done'); }}
        onCall={handleCallButtonClicked}
        getId={callHistoryData => callHistoryData?.id}
        getAvatarUrl={callHistoryData => callHistoryData?.contactDetails?.photoUrl}
        getAvatarFallbackLabel={callHistoryData => `${callHistoryData?.contactDetails?.firstName || 'Unknown Caller'} ${callHistoryData?.contactDetails?.lastName || ''}`}
        isFailedCall={callHistoryData => callHistoryData?.content?.status !== 'Completed'}
        isUnwrapped={callHistoryData => callHistoryData?.content?.callRecordWrapUpStatus === 'Pending'}
        getCallDirection={callHistoryData => callHistoryData?.content.callDirection === 'Inbound' ? CallHistoryCallDirection.inbound : CallHistoryCallDirection.outbound}
        getCallMedium={callHistoryData => callHistoryData?.content.callMedium}
        getDate={callHistoryData => (callHistoryData?.createdDate ? Moment.utc(callHistoryData?.createdDate).local().format('DD/MM/YY') : '-')}
        getTime={callHistoryData => (callHistoryData?.createdDate ? Moment.utc(callHistoryData?.createdDate).local().format('HH:mm') : '-')}
        getDuration={callHistoryData => resolveDuration(callHistoryData?.content?.callDuration)}
        getActions={callHistoryData => callActions(callHistoryData)}
        getCallStatusBadgeTooltip={callHistoryData => callHistoryData?.customerPhone }
        bodyClassName={Styles.summaryBodyWrapper}
        footerClassName={Styles.summaryFooter}
        showNavigationNode
        showCallActionButton
        showNavNodeOnHover
        onViewMoreClick={ () => { console.log('view more') }}
        onHistoryRowClick={handleCallHistoryRowClick} // Call Obj RemoteId Missing
        onAvatarClick={handleCallHistoryAvatarClick}
        showActionsOverflowTooltip
        getActionsOverflowCountTooltipContent={(overflowItems) =>overflowTooltip(overflowItems)}
        historyIconOnClick={handleCallHistoryRender}
        getActionNode={(avatar: any, style: any, item: any) => 
          resolveActionNode(
            avatar,
            style,
            item
          )
        }
        getNavigationNode= {(callHistoryData) => (
          <IconButton
            id='Chevron'
            theme={IconButtonTheme.primary}
            onClick={() => console.log(callHistoryData)}
          >
            <FontAwesomeIcon icon={faChevronRight as IconProp} />
          </IconButton>
        )}
        getMenuItems={ (callEvent) =>
          resolveCallActionMenuItems(
            callEvent.id,
            callEvent?.contactDetails,
            callEvent?.contactDetails?.phone,
            callEvent?.contactDetails?.mobilePhone,
            callEvent?.contactDetails?.account?.phone,
            null,
          )}  
      >
        {(!callHistoryDataLoading && callHistoryData?.length === 0) && (
          <SummaryCardEmptyStateComponent
            id="SummaryCardCallHistory-Empty-states"
            style={{ padding: '0px 18px' }}
            title="Nothing to see here!"
            paragraph="No call histories here..."
            height={152}
          />
        )}
      </SummaryCardCallHistory> 
    </StyledHome>
  );
});

export default HomeComponent;
