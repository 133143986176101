import React from 'react';

export interface IconProps {
  id?: string;
  children: React.ReactNode;
  className?: any;
  style?: React.CSSProperties;
  theme?: Theme;
  size?: Size;
}

export enum Theme {
  default = 'default',
  inherit = 'inherit',
  light = 'light',
  dark = 'dark',
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  close = 'close',
  disabled = 'disabled',
}
export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
  massive = 'massive',
}
