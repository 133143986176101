import Button from '@mui/material/Button';
import { styled } from '@mui/material';

export const StyledButton = styled(Button)({
  border: '1px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: '16px',
  minWidth: '50px',
  maxWidth: '50px',
  height: '44px',
  '&:hover': {
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    background: 'rgba(255, 255, 255, 0.1)',
    boxShadow: 'none',
  },
  '&:active': {
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    background: 'rgba(255, 255, 255, 0.2)',
    boxShadow: 'none',
  },
  '&:focus': {
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    background: 'rgba(255, 255, 255, 0.1)',
    boxShadow: 'none',
  },
  '&:disabled': {
    color: '#808080',
    border: '1px solid #666666',
    background: 'rgba(128,128,128, 0.4)',
    boxShadow: 'none',
  }
  });
  // contained: {
  //   border: '1px solid #FFFFFF',
  //   boxSizing: 'border-box',
  //   borderRadius: '16px',
  //   maxWidth: '48px',
  //   height: '42px',
  //   '&:hover': {
  //     color: '#FFFFFF',
  //     border: '1px solid #FFFFFF',
  //     background: 'rgba(255, 255, 255, 0.1)',
  //     boxShadow: 'none',
  //   },
  //   '&:active': {
  //     color: '#FFFFFF',
  //     border: '1px solid #FFFFFF',
  //     background: 'rgba(255, 255, 255, 0.2)',
  //     boxShadow: 'none',
  //   },
  //   '&:focus': {
  //     color: '#FFFFFF',
  //     border: '1px solid #FFFFFF',
  //     background: 'rgba(255, 255, 255, 0.1)',
  //     boxShadow: 'none',
  //   },
  //   '&:disabled': {
  //     color: '#808080',
  //     border: '1px solid #666666',
  //     background: 'rgba(128,128,128, 0.4)',
  //     boxShadow: 'none',
  //   }
  // },
  // root: {
  //   minWidth: '48px'
  // },
  // subScriptNumber: {
  //   position: 'absolute',
  //   marginBottom: '-8px',
  //   '&::before': {
  //     content: '"\\A"',
  //     whiteSpace: 'pre'
  //   }
  // }

// export default { StyledButton };
