import React, { ElementType } from 'react';

import { MenuItemTheme } from '../MenuItem';

interface MenuProps { [key: string]: any }

export interface SelectProps<MenuItem> {
  id: string
  name?: string
  className?: any
  style?: React.CSSProperties
  menuItemClassName?: any
  menuItemStyle?: React.CSSProperties
  formControlClassName?: React.CSSProperties
  value?: any
  renderValue?: (value: any) => React.ReactNode
  defaultValue?: any
  inputElement?: React.ReactNode
  menuItems?: Array<MenuItem>
  startAdornmentNode?: React.ReactNode
  /**
   * The menu items can be rendered in a custom way if this prop is given
   * If not given, other menu item getters must be provided
   */
  renderMenuItem?: (menuItem: MenuItem, index: number) => React.ReactNode | null
  /**
   * Mandatory if `renderMenuItem` si not used
   */
  getMenuItemValue?: (item: MenuItem) => any
  /**
   * Mandatory if `renderMenuItem` si not used
   */
  getMenuItemLabel?: (item: MenuItem) => any
  /**
   * Mandatory if `renderMenuItem` si not used
   */
  isMenuItemSelected?: (item: MenuItem) => boolean
  /**
   * Mandatory if `renderMenuItem` si not used
   */
  isMenuItemDisabled?: (item: MenuItem) => boolean
  onChange?: (callbackValue?: any, event?: any) => any
  onClose?: (callbackValue?: any, event?: any) => any
  onOpen?: (callbackValue?: any, event?: any) => any
  variant?: Variant
  theme?: Theme
  open?: boolean
  autoWidth?: boolean
  label?: React.ReactNode
  menuProps?: MenuProps
  multiple?: boolean
  iconComponent?: ElementType<any>
  inputLabel?: React.ReactNode
  showLabel?: boolean
  labelShrink?: boolean
  formControlTheme?: 'primary' | 'secondary'
  disabled?: boolean
  formControlError?: boolean
  focused?: boolean
  fullWidth?: boolean
  hiddenFormLabel?: boolean
  margin?: Margin
  required?: boolean
  formControlSize?: FormControlSize
  formControlVariant?: Variant
  showHelperText?: boolean
  formHelperText?: React.ReactNode
}

export enum Variant {
  filled = 'filled',
  outlined = 'outlined',
  standard = 'standard',
}

export enum Theme {
  light = 'light',
  dark = 'dark',
}

export enum Margin {
  dense = 'dense',
  none = 'none',
  normal = 'normal',
}

export enum FormControlSize {
  medium = 'medium',
  small = 'small',
}

export const SelectMenuItemThemeMap = {
  [Theme.light]: MenuItemTheme.normal,
  [Theme.dark]: MenuItemTheme.dark,
};
