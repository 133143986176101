import { styled } from '@mui/material';
import Avatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import FontFamily from '../../Theme/FontFamily';
import { AvatarService } from '../../Services/Avatar';

import { Size, AvatarProps } from './Avatar.types';

const fontFamily = FontFamily;

interface StyledAvatarProps extends MuiAvatarProps {
  fallbackLabel: string;
  borderColor?: string | undefined;
  avatarSize: string;
};

export const useFallbackTextStyles = {
  default: {
    color: (props: AvatarProps) =>
      AvatarService.getTextColour(props.fallbackLabel, props.fallbackBackgroundColour),
  },
  [Size.small]: { fontSize: 12 },
  [Size.medium]: { fontSize: 12 },
  [Size.large]: { fontSize: 20 },
};

export const useMultiLineTooltipStyles = {
  text: {
    fontFamily,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    display: 'inline-block',
  },
};

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'fallbackLabel' && prop !== 'borderColor' && prop !== 'avatarSize',
})<StyledAvatarProps>(({ fallbackLabel, borderColor, avatarSize }) => ({
  backgroundColor: AvatarService.getBackgroundColour(fallbackLabel, null),
  border: borderColor ? `1px solid ${borderColor} !important` : 'none',
  width: avatarSize === Size.small ? '24px' : avatarSize === Size.medium ? '36px' : avatarSize === Size.large ? '48px' : '36px',
  height: avatarSize === Size.small ? '24px' : avatarSize === Size.medium ? '36px' : avatarSize === Size.large ? '48px' : '36px',
}));