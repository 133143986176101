import { forwardRef } from 'react';

import { Theme, ButtonWidth } from '../Buttons.types';
import Button, { ButtonVariant } from '../../../../Button';

import { DiallerCallActionButtonProps } from './DiallerCallActionButton.types';
import { Styles } from './DiallerCallActionButton.theme';

const DiallerCallActionButton = forwardRef<HTMLButtonElement, DiallerCallActionButtonProps>((props, ref) => {
  const {
    id,
    className,
    style,
    theme,
    variant,
    children,
    onClick,
    callbackValue,
    disabled,
    startIcon,
    endIcon,
    type,
    fullWidth,
    buttonWidth,
  } = props;

  const themeClassName = theme ? Styles[theme] : undefined;
  const widthClassName = buttonWidth ? Styles[buttonWidth] : undefined;
  const buttonClassNames = {...themeClassName, ...className, ...widthClassName, ...Styles.common};

  return (
    <Button
      id={id}
      ref={ref}
      type={type}
      className={buttonClassNames}
      style={style}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      callbackValue={callbackValue}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  );
});

DiallerCallActionButton.defaultProps = {
  className: undefined,
  style: undefined,
  disabled: false,
  theme: Theme.primary,
  variant: ButtonVariant.normal,
  onClick: () => null,
  callbackValue: undefined,
  fullWidth: false,
  buttonWidth: ButtonWidth.full,
};

export default DiallerCallActionButton;
