import { styled, Popper, PopperProps, MenuList, MenuListProps } from "@mui/material";

interface StyledPopperProps extends PopperProps {
  popperClassName?: React.CSSProperties;
};

interface StyledMenuListProps extends MenuListProps {
  menuListclass?: React.CSSProperties;
};

export const StyledPopper = styled(Popper, {
  shouldForwardProp: (prop) => prop !== 'popperClassName',
})<StyledPopperProps>(({ popperClassName }) => ({
  ...popperClassName,
}));

export const StyledMenuList = styled(MenuList, {
  shouldForwardProp: (prop) => prop !== 'menuListclass',
})<StyledMenuListProps>(({ menuListclass }) => ({
  ...menuListclass,
}));