import { styled } from "@mui/material";

export const SplashContainerDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});

export const CenterDiv = styled('div')({
  color: '#38363a',
  textAlign: 'center',
  position: 'relative',
  bottom: '75px',   
});