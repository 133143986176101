import { forwardRef, useCallback } from 'react';

import { IconButtonProps, Theme, Size, MuiSizeMap } from './IconButton.types';
import { Styles, StyledIconButton } from './IconButton.theme';

const IconButtonComponent = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    id,
    ariaOwns,
    ariaHasPopup,
    ariaControls,
    children,
    className,
    style,
    disabled,
    theme,
    size,
    edge,
    onClick,
    callbackValue,
    onMouseDown,
    onTouchStart,
    onMouseUp,
    onMouseLeave,
    onTouchEnd,
    onMouseEnter,
    onMouseOver,
  } = props;

  const themeClassName = theme ? Styles[theme] : null;
  const sizeClassName = size ? Styles[size] : null;
  const mergedClassName = {...themeClassName, ...sizeClassName, ...className}
  const muiSize = MuiSizeMap[size ?? Size.medium];

  const handleClick = useCallback((event: any) => {
    onClick?.(callbackValue, event);
  }, [onClick, callbackValue]);

  return (
    <StyledIconButton
      id={id}
      ref={ref}
      aria-owns={ariaOwns}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
      styledIconButtonClass={mergedClassName}
      sx={style}
      disabled={disabled}
      size={muiSize as any}
      edge={edge}
      onClick={handleClick}
      disableFocusRipple
      disableRipple
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
      onMouseEnter={onMouseEnter}
      onMouseOver={onMouseOver}
    >
      {children}
    </StyledIconButton>
  );
});

IconButtonComponent.defaultProps = {
  className: undefined,
  style: undefined,
  disabled: false,
  theme: Theme.dark,
  size: Size.medium,
  edge: undefined,
  onClick: () => null,
  callbackValue: undefined,
  ariaOwns: undefined,
  ariaHasPopup: undefined,
  ariaControls: undefined,
};

export { Theme as IconButtonTheme, Size as IconButtonSize };
export default IconButtonComponent;
