import { Paper } from '@mui/material';

import { hideGdprModal } from '../../../slices/gdprModal';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Typography, { TypographyVariant } from '../../Typography';
import Button, { ButtonTheme, ButtonVariant } from '../../Button';
import OutboundCallService from '../../../Services/Call/OutboundCall';

import { StyledModal, StyledModalContainer, StyledTitle, StyledFooter, StyledDangerButton } from './GdprModal.theme';

const GdprModal = () => {
  const dispatch = useAppDispatch();
  const authData = useAppSelector((state: any) => state.auth);
  const tenantSettings = useAppSelector((state: any) => state.settings.tenantSettings);
  const gdprModalData = useAppSelector((state: any) => state.gdprModal);

  const handleClose = () => {
    dispatch(hideGdprModal());
  };

  const handleCall = () => {
    OutboundCallService.initiateCallRequest(
      authData, 
      tenantSettings,
      gdprModalData.modalData.contact, 
      gdprModalData.modalData.contactNumber,
      gdprModalData.modalData.dialFromNumber, 
      gdprModalData.modalData.callEventId
    );

    dispatch(hideGdprModal());
  };

  return (
    <StyledModal sx={StyledModalContainer} open={gdprModalData?.open} onClose={handleClose}>
      <Paper sx={{ padding: '14px', margin: '12px', borderRadius: '18px !important' }}>
        <StyledTitle><Typography.H2 style={{ fontSize: 18, fontWeight: 500 }} variant={TypographyVariant.title}>{gdprModalData.modalData.title}</Typography.H2></StyledTitle>
        <div><Typography.P1 style={{ fontSize: 14 }} variant={TypographyVariant.title}>{gdprModalData.modalData.primaryText}</Typography.P1></div>
        <br />
        {!!(gdprModalData.modalData.secondaryText) && (
          <div><Typography.P1 style={{ fontSize: 14 }}  variant={TypographyVariant.title}>{gdprModalData.modalData.secondaryText}</Typography.P1></div>
        )}
        <StyledFooter>
          <Button id="Back-Button" theme={ButtonTheme.primary} variant={ButtonVariant.text} onClick={handleClose} disabled={false}>
            <Typography.H4 style={{ fontSize: 18, fontWeight: 500 }} variant={TypographyVariant.primary}>{gdprModalData.modalData.cancelButtonText}</Typography.H4>
          </Button>
          {!!(gdprModalData.modalData.confirmButtonText) && (
            <Button
              id="Proceed-Button"
              theme={ButtonTheme.primary}
              variant={ButtonVariant.normal}
              className={StyledDangerButton}
              onClick={handleCall}
              disabled={false}
            >
              <Typography.H4 style={{ fontSize: 18, fontWeight: 500 }} variant={TypographyVariant.light}>{gdprModalData.modalData.confirmButtonText}</Typography.H4>
            </Button>
          )}
        </StyledFooter>
      </Paper>
    </StyledModal>
  );
};

export default GdprModal;
