import React, { forwardRef } from 'react';
import { Button } from '@mui/material';
import { Send } from '@mui/icons-material';

import { StyledHome, StyledParagraph } from './ErrorPage.theme';
import { ErrorPageProps } from './ErrorPage.types';

const ErrorPageComponent =  forwardRef<HTMLDivElement, ErrorPageProps>(({errorCode = undefined}, ref) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    console.log('Send Email was clicked.');
  }

  return (
    <StyledHome ref={ref}>
      <StyledParagraph>ErrorCode: {errorCode}</StyledParagraph>
      {errorCode === 100 && <StyledParagraph>Something went wrong. Seems you do not have the right access to use this app. Please contact rocketphone.ai to get access.</StyledParagraph>}
      {errorCode === 401 && <>
        <StyledParagraph>Authentication Failed.Please try logging in and out of Salesforce. If the problem persists, please make contact with the rocketphone.ai support team</StyledParagraph>
        <Button variant="contained" endIcon={<Send />} color="primary" onClick={handleClick}>
          Send Email
        </Button>
      </>}
    </StyledHome>
  );
});

export default ErrorPageComponent;
