import React, { forwardRef } from 'react';

import { TooltipProps } from './Tooltip.types';
import { StyledTooltip } from './Tooltip.theme';

const TooltipComponent: React.FC<TooltipProps> = forwardRef<HTMLButtonElement, TooltipProps>((props, ref) => {
  const {
    id, className, style,
    content, placement,
    arrow, force,
    children,
  } = props;

  const childContent = force ? (<span>{children}</span>) : (children);

  return (
    <StyledTooltip
      id={id}
      ref={ref}
      sx={className}
      style={style}
      title={content}
      placement={placement}
      arrow={arrow}
    >
      {childContent}
    </StyledTooltip>
  );
});

TooltipComponent.defaultProps = {
  className: undefined,
  style: undefined,
  arrow: false,
  placement: 'bottom',
  force: false,
};

export default TooltipComponent;
