import React, { forwardRef, useCallback } from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from '../../../Avatar';
import Typography, { TypographyVariant } from '../../../Typography';

import { ContactRowProps, ContactStatus } from './ContactRow.types';
import { Styles, ContactRowDiv } from './ContactRow.theme';

const statusColorMap = {
  [ContactStatus.Available]: '#0BA360',
  [ContactStatus.AvailableMobile]: '#0BA360',
  [ContactStatus.Busy]: '#e392f7',
  [ContactStatus.Away]: '#F69D1B',
};

const ContactRowComponent: React.FC<ContactRowProps> = forwardRef<HTMLDivElement, ContactRowProps>((props, ref) => {
  const {
    id,
    className,
    style,
    dataItem,
    contactName,
    avatarUrl,
    avatarFallbackLabel,
    contactStatus,
    selected,
    onSelect,
    callbackvalue,
    active,
  } = props;

  const contactStatusClass = { fontSize: '12px', fontWeight: 500, marginLeft: '12px', color: statusColorMap[contactStatus] };
  const avatarClass = {...Styles.avatar, ...(selected && Styles.selectedAvatar)};


  const handleSelect = useCallback(
    (e: any) => {
      onSelect?.(dataItem);
    },
    [onSelect, callbackvalue]
  );

  return (
    <ContactRowDiv 
      id={`${id}-contactRow}`} 
      ref={ref} 
      style={style} 
      contactRowClass={className}
      isActive={active}
      onClick={handleSelect}
    >
      <Avatar
        id={`${id}-contactRow-Avatar`}
        className={avatarClass}
        url={avatarUrl}
        fallbackLabel={avatarFallbackLabel}
        alt="Contact Avatar"
      />
      <Typography.H4 variant={TypographyVariant.light} style={{ fontSize: '15px', fontWeight: 500, marginLeft: '12px' }}>{contactName}</Typography.H4>
      <Typography.H5 variant={TypographyVariant.light} style={contactStatusClass}>
        {contactStatus}
      </Typography.H5>
      {selected && <FontAwesomeIcon 
        icon={faCheck as IconProp} 
        style={{
          position: 'relative',
          paddingLeft: '8px',
          fontWeight: 900,
          fontSize: '24px',
          color: '#0BA360',
          marginLeft: '18px',
        }}
      />}
    </ContactRowDiv>
  );
});

ContactRowComponent.defaultProps = {
  className: undefined,
  style: undefined,
  contactName: undefined,
  selected: false,
  active: false,
  avatarFallbackLabel: 'NA',
};

export const ContactPersonStatus = ContactStatus;
export default ContactRowComponent;
