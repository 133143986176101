import React from 'react';

import { CallMedium } from '../../../../Enums/ActivityEnum';

export interface CallHistoryRowProps<ActionItem> {
  id: string;
  menuListStyle?: React.CSSProperties;
  avatarClassName?: any;
  style?: React.CSSProperties;
  avatarUrl: string;
  avatarFallbackLabel: string;
  callFailed?: boolean;
  callUnwrapped?: boolean;
  callDirection: CallDirection;
  callMedium: CallMedium;
  date: string;
  time: string;
  duration: string;
  actions: Array<ActionItem>;
  getActionNode: (action: ActionItem, style: React.CSSProperties, item?: any) => React.ReactNode;
  showActionsOverflowTooltip?: boolean;
  getActionsOverflowCountTooltipContent?: (overflowItems: Array<any>) => React.ReactChild;
  showNavigationNode?: boolean;
  showCallActionButton?: boolean;
  showNavNodeOnHover?: boolean;
  navigationNode?: React.ReactNode;
  theme: Theme;
  miniVersion?: React.ReactNode;
  item?: Object;
  callButtonDisabled?: boolean;
  getMenuItems: (data: any) => Array<any>;
  onCall: (callbackValue?: any) => any;
  onHistoryRowClick?: (item?: any) => any;
  onAvatarClick?: (item?: any) => any
  getCallStatusBadgeTooltip?: (data: any) => string
};

export enum CallDirection {
  outbound = 'outbound',
  inbound = 'inbound',
};

export enum Theme {
  normal = 'normal',
  inverse = 'inverse',
};
