import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Colours from '../../Theme/Colours';
import Buttons from './private/Buttons';
import CallForwardCard from '../CallForwardCard';

// const classes = {
//   root: {
//     minHeight: 556,
//     maxHeight: "100vh",
//     width: '100%',
//     margin: 0,
//     height: 556,
//     borderRadius: 0,
//   },
// };
// const cardContentClasses = {
//   root: {
//     padding: 0,
//     minHeight: 445,
//     maxHeight: 568,
//     height: 568,
//     '&:last-child': {
//       padding: '0px !important',
//     },
//   },
// };

const ThemedCard = styled(Card)({
  root: {
    minHeight: 556,
    maxHeight: "100vh",
    width: '100%',
    margin: 0,
    height: 556,
    borderRadius: 0,
    padding: 0,
  },
  minHeight: 556,
  maxHeight: "100vh",
  width: '100%',
  margin: 0,
  height: 556,
  borderRadius: 0,
  padding: 0
});

const ThemedCardContent = styled(CardContent)({
  root: {
    padding: 0,
    minHeight: 445,
    maxHeight: 568,
    height: 568,
  },
  minHeight: 445,
  maxHeight: 568,
  height: 568,
  padding: 0,
  '&:last-child': {
      paddingBottom: '0px !important',
    },
});
// const useStyles = {
//   default: {},
const MainContainer = styled('div')({
  display: 'flex',
  width: '100%',
});

const HeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  height: 70,
  background: Colours.Auxiliary.navigation,
  overflow: 'hidden',
});

const BodyContainer = styled('div')({
  background: Colours.Auxiliary.navigation,
  height: '418px',
  minHeight:'418px',
  maxHeight: 600,
});

const BodyContainerExtended = styled('div')({
  height: 400,
});

const ActionsContainer = styled('div')({
  width: '100%',
  minHeight: '68px',
  height: '68px',
  paddingTop: 0,
});

const NumberAndContactContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const NumberInputContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

const AddContactContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  height: 60,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});
  
const AddContactContainerExtended = styled('div')({
  height: 72,
});

const AddContactButton = styled('div')({
  '&:hover': {
    backgroundColor: 'unset',
  },
  '&:active': {
    color: Colours.Typography.light,
    backgroundColor: 'unset',
  },
  '&:focus': {
    color: Colours.Typography.light,
    backgroundColor: 'unset',
  },
});

const NumberPadDiv = styled('div')({
  backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
  borderRadius: 0,
  height: '100%',
  maxHeight: 72,
  minHeight: 100,
  padding: 0,
  minWidth: 400,
  width: '100%',
});

const NumberPadDivExtended = styled('div')({
  height: 60,
});

const CallButtonContainer = styled('div')({
  width: '100%',
  height: '100%',
});

const DiallerCallActionButton = styled(Buttons.DiallerCallActionButton)({
  height: '100% !important',
});

// // const CallIcon = styled('div')({
//     fontSize: 30,
// });

// // const DialpadIcon = styled('div')({
//   fontSize: 24,
// });

const CallControlsContainer = styled('div')({
  width: '100%',
  height: 69,
  backgroundColor: 'rgba(0, 25, 50, 0.87)',
  display: 'flex',
  alignItems: 'center',
});

const CallButtonsWrapper = styled('div')({
  width: '100%',
  display: 'block',
  alignItems: 'center',
});

const DiallerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

const DraggablePanel = styled('div')({
  height: 22,
  backgroundColor: '#001326',
  '&:hover': {
    cursor: 'move',
  },
});

const CallForwardCardContainer = styled(CallForwardCard)({
  minWidth: '264px !important',
  borderRadius: '0px !important',
  minHeight: '486px !important',
  maxHeight: '486px !important',
  display: 'flex',
  flexDirection: 'column',

  '& > div': {
    backgroundColor: '#001932',
  },
});
  // moreNumberContainerWrapper: {
  //   width: '100%',
  //   backgroundColor: 'rgba(255, 255, 255, 0.1)',
  // },
  // moreNumbersPanel: {
  //   '& > div': {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     color: '#FFFFFF',
  //   },
  //   '& > svg': {
  //     position: 'relative',
  //     right: 50,
  //     color: '#FFFFFF',
  //     fontSize: 16,
  //   },
  // },
  // nestedNumber: {
  //   paddingLeft: 24,
  //   color: '#FFFFFF',
  // },
  // nestedNumberValue: {
  //   flex: '0 1 auto',
  //   marginRight: 6,
  // },
  // nestedNumberIcon: {
  //   minWidth: 0,
  //   marginRight: 20,
  //   color: '#FFFFFF',
  // },
  // wrapupButtonsContainer: {
  //   width: '100%',
  // },

 const Styles = {
  cardContainer: {
    minWidth: '264px !important',
    borderRadius: '0px !important',
    minHeight: '486px !important',
    maxHeight: '486px !important',
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      backgroundColor: '#001932',
    },
  },
  callForwardDialpad: {
    minHeight: '378px !important',
    borderRadius: '0px',
    flex: 1,

    '& > div:first-child': {
      height: '60px !important',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '0px',
    },
    '& > div:nth-of-type(2)': {
      width: 'auto !important',
      margin: '38px 80px !important',

      '& > button': { margin: '9px 12px !important' },
    },
  },
  draggablePanel: {
    height: 22,
    backgroundColor: '#001326',
    '&:hover': {
      cursor: 'move',
    },
  },
  content: {
    height: '378px !important',
    minHeight: '378px !important',
    flex: 1,
  },
  inputWrapper: { margin: '24px 12px !important' },
  dialpadButtonContainer: { paddingLeft: '12px !important' },
  contactRowList: {
    paddingLeft: '6px !important',
    maxHeight: '260px !important',
    textAlign: 'initial',
  },
  numberPadContainer : {
    backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
    borderRadius: 0,
    height: '100%',
    maxHeight: 72,
    minHeight: 100,
    padding: 0,
    minWidth: 400,
    width: '100%',
  },

  callButton: {
    height: '100% !important',
  },

  callControlButton: {
    marginLeft: 18,
  },
}

export default { MainContainer, HeaderContainer, DiallerContainer, BodyContainer, BodyContainerExtended,
  ActionsContainer, NumberAndContactContainer, NumberInputContainer, AddContactContainer, AddContactContainerExtended,
  AddContactButton, NumberPadDiv, NumberPadDivExtended, CallButtonContainer, DiallerCallActionButton,
  CallControlsContainer, CallButtonsWrapper,
  Styles, ThemedCard, ThemedCardContent, DraggablePanel, CallForwardCardContainer
};

// export default { classes, cardContentClasses, useStyles };
