import Select from '@mui/material/Select';
import { FormControl, FormControlProps, styled } from '@mui/material';

import Colours from '../../Theme/Colours';
import { Theme } from './Select.types';

interface StyledFormControlProps extends FormControlProps {
  styledFormControlClass?: React.CSSProperties
};

interface StyledSelectProps {
  styledSelectClass?: React.CSSProperties,
  formControlTheme?: Theme
};

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'styledFormControlClass',
})<StyledFormControlProps>(({ styledFormControlClass }) => ({
  ...styledFormControlClass,
}));

export const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'styledSelectClass' && prop !== 'formControlTheme',
})<StyledSelectProps>(({ styledSelectClass, formControlTheme }) => ({
  '.MuiSelect-icon': {
    color: '#FFFFFF',
    fontWeight: 900,
    fontSize: 24,
  },
  '.MuiSelect-iconFilled': {
    color: `${Colours.Typography.light} !important`,
    marginRight: 16,
    top: 'calc(50% - 14px)',
  },
  '.MuiSelect-iconOpen': {
    color: Colours.Typography.light,
    marginLeft: 16,
    top: 'calc(50% - 14px)',
  },

  minWidth: 178,
  fontSize: 14,

  color: formControlTheme === Theme.dark ? Colours.Typography.light : Colours.Typography.input,
  backgroundColor: formControlTheme === Theme.dark ? ' #001326 !important' : Colours.Backgrounds.lightMenuItem,

  '&:hover': {
    color: formControlTheme === Theme.dark ? Colours.Typography.light : Colours.Typography.input,
    // backgroundColor: ' #001326',
  },
  '&:active': {
    color: formControlTheme === Theme.dark ? Colours.Typography.light : Colours.Typography.input,
    // backgroundColor: ' #001326',
  },
  '&:focus': {
    color: formControlTheme === Theme.dark ? Colours.Typography.light : Colours.Typography.input,
    // backgroundColor: ' #001326',
  },

  ...styledSelectClass,
}));
