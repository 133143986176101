import React, { forwardRef } from 'react';
import { styled  } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { TypographyProps, Variant } from './Typography.types';
import { variantClasses, colorClasses, StyledTypography } from './Typography.theme';

type MuiVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';

const ThemedTypography = styled(StyledTypography)(variantClasses);

const VariantMapping: Partial<Record<MuiVariant, string>> = {
  'body1': 'span',
  'body2': 'span',
};

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (muiVariant: MuiVariant) => {
  const TypographyVariantComponent: React.FC<TypographyProps> = forwardRef<HTMLSpanElement, TypographyProps>((props, ref) => {
    const {
      id, className, style, children,
      variant, bottomMargin, noWrap,
    } = props;
    const variantClass = colorClasses[variant ?? Variant.label];
  
    return (
      <ThemeProvider theme={theme}>
        <ThemedTypography
          id={id}
          ref={ref}
          typographyClass={className}
          typographyVariantClass={variantClass}
          sx={style}
          variant={muiVariant as any}
          gutterBottom={!!bottomMargin}
          noWrap={noWrap}
          variantMapping={VariantMapping}
        >
          {children}
        </ThemedTypography>
      </ThemeProvider>
    );
  });

  TypographyVariantComponent.defaultProps = {
    id: undefined,
    children: '',
    className: undefined,
    style: undefined,
    variant: Variant.label,
    bottomMargin: false,
    noWrap: false,
  };

  return TypographyVariantComponent;
};
