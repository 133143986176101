import React, { forwardRef, useState, useEffect } from 'react';
// import FilledInput from '@mui/material/FilledInput';
import { PopoverOrigin } from '@mui/material/Popover';
// import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import classnames from 'classnames';

import Select, { SelectTheme, SelectVariant } from '../../../Select';

import { NumberDropdownProps } from './NumberDropdown.types';
import NumberDropdownThemer from './NumberDropdown.theme';

const DefaultAnchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' };
const DefaultTransformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' };

// const ThemedFilledInput = styled(FilledInput)(NumberDropdownThemer.filledInputClasses);

const resolveInitialValue = (menuItems: any[] | undefined) => {
  let result = (menuItems && { value: menuItems[0]?.value, outgoingPreference: menuItems[0]?.outgoingPreference }) || {};

  menuItems?.forEach((item) => {
    if (item?.selected) {
      result = { value: item?.value, outgoingPreference: item?.outgoingPreference };
    }
  });

  return result;
};

const NumberDropdown = forwardRef<HTMLDivElement, NumberDropdownProps<any>>((props, ref) => {
  const { id, menuItems, dropdownDisabled, onDropDownSelect, onMenuOpen, onMenuClose } = props;

  const initialValue = resolveInitialValue(menuItems);
  const [dataState, setDataState] = useState(initialValue?.value);

  useEffect(() => {
    onDropDownSelect?.(initialValue);
  }, []);

  const handleChange = (event: any) => {
    const selectedOutgoingPreference = menuItems?.find(item => item?.value === event.target.value).outgoingPreference;
    setDataState(event.target.value);
    onDropDownSelect?.({ value: event.target.value, outgoingPreference: selectedOutgoingPreference});
  };

  const classes = NumberDropdownThemer.useStyles;
  const themeClassName = {...classes.default, ...classes.inputContainer, ...classes.inputText};

  const dropdownIcon = dropdownDisabled ? NotInterestedIcon : ExpandMoreIcon;

  return (
    <Select
      id={id}
      ref={ref}
      className={themeClassName}
      menuItemClassName={classes.dropdownItemStyles as any}
      formControlClassName={classes.inputContainer}
      inputElement={<NumberDropdownThemer.ThemedFilledInput 
                      fullWidth
                      inputProps={{
                        'aria-label': 'dropdown input'
                      }}
                      />}
      menuItems={menuItems}
      value={dataState}
      defaultValue={initialValue}
      onOpen={onMenuOpen}
      onClose={onMenuClose}
      onChange={handleChange}
      getMenuItemValue={(item) => item.value}
      getMenuItemLabel={(item) => item.label}
      isMenuItemSelected={(item) => item.selected}
      isMenuItemDisabled={(item) => item.disabled}
      theme={SelectTheme.dark}
      variant={SelectVariant.filled}
      iconComponent={dropdownIcon}
      menuProps={{
        classes: { paper: classes.dropdownMenuStyle, list: classes.dropdownListStyle },
        anchorOrigin: DefaultAnchorOrigin,
        transformOrigin: DefaultTransformOrigin,
        getContentAnchorEl: null,
        PopoverClasses: { root: classes.popoverRootStyle },
      }}
      disabled={dropdownDisabled}
    />
  );
});

NumberDropdown.propTypes = {
  menuZIndex: 1300 as any,
};

export default NumberDropdown;
