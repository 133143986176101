import { styled } from '@mui/material';
import Colours from '../../Theme/Colours';

interface ContainerDivProps {
  callHistoryClassName?: any;
};

export const ContainerDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'callHistoryClassName',
})<ContainerDivProps>(({ callHistoryClassName }) => ({
  width: 386,
  minWidth: 380,
  backgroundColor: Colours.Backgrounds.white,
  ...callHistoryClassName,
}));