import { Icon, IconProps, styled } from '@mui/material';
import Colours from '../../Theme/Colours';
import { Theme, Size } from './Icon.types';
import React from 'react';

interface StyledIconProps extends IconProps {
  IconTheme: React.CSSProperties;
  IconSize: React.CSSProperties;
  IconClassName: any;
};

export const Styles = {
  [Theme.inherit]: { color: 'inherit' },
  [Theme.default]: { color: Colours.Iconography.Default.normal },
  [Theme.dark]: { color: Colours.Iconography.Dark.normal },
  [Theme.light]: { color: Colours.Iconography.Light.normal },
  [Theme.primary]: { color: Colours.Iconography.Primary.normal },
  [Theme.secondary]: { color: Colours.Iconography.Secondary.normal },
  [Theme.danger]: { color: Colours.Iconography.Danger.normal },
  [Theme.close]: {
    color: Colours.Iconography.Close.normal,
    '&:hover': { color: Colours.Iconography.Close.hovered },
    '&:active': { color: Colours.Iconography.Close.pressed },
    '&:focus': { color: Colours.Iconography.Close.pressed },
  },
  [Theme.disabled]: {
    color: Colours.Typography.disabled,
  },

  [Size.massive]: { fontSize: 24 },
  [Size.extraLarge]: { fontSize: 18 },
  [Size.large]: { fontSize: 16 },
  [Size.medium]: { fontSize: 14 },
  [Size.small]: { fontSize: 12 },
};

export const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'IconTheme' && prop !== 'IconSize' && prop !== 'IconClassName',
})<StyledIconProps>(({ IconTheme, IconSize, IconClassName }) => ({
  ...IconTheme,
  ...IconSize,
  ...IconClassName,
}));