// import { styled } from "@mui/material";
// import Button from '../Button';

export const Styles = {
  contentButton: {
    minWidth: 80,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: 'rgb(11, 163, 96)',
    color: 'white !important',

    '&:hover': {
      backgroundColor: '#00693E !important',
    },
  },
  dropButton: {
    width: 36,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: 'rgb(11, 163, 96)',
    '&:hover': {
      backgroundColor: '#00693E !important',
    },
  },
  popper: {
    zIndex: 100,
  },
  menu: {},
  menuList: { paddingTop: 6, paddingBottom: 6 },
};
