import React, { forwardRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import classnames from 'classnames';

import { ContactRowLoadingProps } from './ContactRowLoading.types';
import ContactRowLoadingThemer from './ContactRowLoading.theme';

const CallHistoryRowLoadingComponent = forwardRef<HTMLDivElement, ContactRowLoadingProps>((props, ref) => {
  const { id, className, style, miniSkeleton } = props;
  // const classes = ContactRowLoadingThemer.useStyles;
  // const rowClassName = classnames(classes.row, className);
  // const columnClassName = classnames(classes.column);

  return (
    <div
      id={id}
      ref={ref}
      // className={rowClassName}
      style={style}
    >
      <div
      //  className={classnames(classes.columnAvatar, classes.column)}
      >
        <Skeleton variant="circular" width={40} height={40} />
      </div>

      <div
      //  className={columnClassName}
       >
        {miniSkeleton && (
          <Skeleton variant="rectangular" width={80} height={12} />
        )}
        {!miniSkeleton && (
          <Skeleton variant="rectangular" width={130} height={21} />
        )}
      </div>

      <div
      //  className={columnClassName}
      >
        {miniSkeleton && (
        <Skeleton variant="rectangular" width={60} height={12} />
        )}
        {!miniSkeleton && (
          <Skeleton variant="rectangular" width={100} height={21} />
        )}
      </div>
    </div>
  );
});

CallHistoryRowLoadingComponent.defaultProps = {
  className: undefined,
  style: undefined,
  miniSkeleton: false,
};

export default CallHistoryRowLoadingComponent;
