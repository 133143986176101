import { styled } from '@mui/material';
import { Theme } from './ScheduledCallsCardRowLoading.types';

const Background = { normal: 'rgba(255, 255, 255, 1)', inverse: 'rgba(240, 248, 255, 1)' };

interface StyledDivProps {
  rowClass?: React.CSSProperties;
  rowTheme?: Theme;
};

export const RowDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'rowClass' && prop !== 'rowTheme',
})<StyledDivProps>(({ rowClass, rowTheme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 56,
  paddingLeft: 18,
  paddingRight: 18,
  justifyContent: 'space-between',
  backgroundColor: rowTheme === Theme.normal ? Background.normal : rowTheme === Theme.inverse ? Background.inverse : Background.normal,
  ...rowClass,
}));

export const ColumnContactDetailsDiv =  styled('div')({
  flex: 1,
  minWidth: 100,
  maxWidth: '32%',
  marginLeft: '8%',
});

export const ColumnTimestampDiv =  styled('div')({
  flex: 1,
  minWidth: 62,
  maxWidth: '22%',
  marginLeft: '15%',
});