import React, { forwardRef } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SummaryCardRoot from './SummaryCardRoot';
import IconTitle from '../IconTitle';
import Typography, { TypographyVariant } from '../Typography';
import CallHistory from '../CallHistory';

import { SummaryCardCallHistoryProps } from './SummaryCardCallHistory.types';
import { Styles } from './SummaryCardCallHistory.theme';

const Title = (props:any) => {
  const { textColor, title, historyIconOnClick, renderHistoryIcon } = props;

  return (
    <IconTitle
      icon={renderHistoryIcon}
      iconBackgroundColor="rgb(250, 154, 113)"
      iconOnClick={historyIconOnClick}
    >
      <Typography.H6 style={{ color: textColor }} className={Styles.title} variant={TypographyVariant.active}>
        {title ??  `Call History`}
      </Typography.H6>
    </IconTitle>
  );
};

const SummaryCardCallHistoryComponent = forwardRef<HTMLDivElement, SummaryCardCallHistoryProps<any, any>>((props, ref) => {
  const {
    style, title,
    footerClassName,
    // callHistoryContainer,
    bodyClassName, data, dataTotalCount, dataLoading,
    id, bottomHitThreshold, children, getId, getAvatarUrl,
    getAvatarFallbackLabel, isFailedCall, getCallDirection, getCallMedium,
    getDate, getTime, getDuration,
    getActions, getActionNode, showActionsOverflowTooltip,
    getActionsOverflowCountTooltipContent, showNavigationNode,
    showNavNodeOnHover, getNavigationNode,
    onNextPageRequested, getAvatarClassName, isUnwrapped,
    showCallActionButton, menuListStyle, getMenuItems, onCall, getCallStatusBadgeTooltip,
    onHistoryRowClick, onAvatarClick, historyIconOnClick,
  } = props;

  return (
    <SummaryCardRoot
      id={`${id}-SummaryCard-CallHistory`}
      style={style}
      footerClassName={{...footerClassName, ...Styles.historyFooter}}
      bodyClassName={{...bodyClassName, ...Styles.summaryHistoryBody}}
      header={
        <Title
          title={title}
          iconBgColor='#FA9A71'
          textColor='#000000'
          historyIconOnClick={historyIconOnClick}
          renderHistoryIcon={
          <>
            {dataLoading && 
              (
                <FontAwesomeIcon icon={faHistory as IconProp} color={'white'} spin spinReverse/>
            )}
            {!dataLoading && 
              (
                <FontAwesomeIcon icon={faHistory as IconProp} color={'white'}/>
            )}
          </>}
        />
      }
      footer={
        <>
        </>
      }
    >
      <div
        id={id}
        ref={ref}
        style={style}
      >
        <CallHistory
          id={`${id}-SummaryCallHistory`}
          className={Styles.historyContainer}
          data={data}
          dataTotalCount={dataTotalCount}
          dataLoading={dataLoading}
          bottomHitThreshold={bottomHitThreshold}
          onNextPageRequested={onNextPageRequested}
          getId={getId}
          getAvatarUrl={getAvatarUrl}
          getAvatarFallbackLabel={getAvatarFallbackLabel}
          getAvatarClassName={getAvatarClassName}
          isFailedCall={isFailedCall}
          isUnwrapped={isUnwrapped}
          getCallDirection={getCallDirection}
          getCallMedium={getCallMedium}
          getDate={getDate}
          getTime={getTime}
          getDuration={getDuration}
          getActions={getActions}
          getActionNode={getActionNode}
          showActionsOverflowTooltip={showActionsOverflowTooltip}
          getActionsOverflowCountTooltipContent={getActionsOverflowCountTooltipContent}
          showNavigationNode={showNavigationNode}
          showCallActionButton={showCallActionButton}
          showNavNodeOnHover={showNavNodeOnHover}
          getNavigationNode={getNavigationNode}
          // showHeader={true}
          miniVersion
          menuListStyle={menuListStyle}
          onCall={onCall}
          getMenuItems={getMenuItems}
          onHistoryRowClick={onHistoryRowClick}
          onAvatarClick={onAvatarClick}
          getCallStatusBadgeTooltip={getCallStatusBadgeTooltip}
        />
        {children}
      </div>
    </SummaryCardRoot>
  );
});

SummaryCardCallHistoryComponent.defaultProps = {
  style: undefined,
  title: undefined,
  footerClassName: undefined,
  bodyClassName: undefined,
  callHistoryContainer: undefined,
  data: [],
  dataTotalCount: 0,
  dataLoading: false,
  onViewMoreClick: () => null,
  href: '',
  showNavNodeOnHover: false,
};

export default SummaryCardCallHistoryComponent;
