import React, { forwardRef, useRef, useState, useCallback } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import { PopoverOrigin } from '@mui/material/Popover';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';

import Button, { ButtonTheme, ButtonVariant } from '../Button';
import Popper from '../Popper';

import { DropdownButtonSplitProps } from './DropdownButtonSplit.types';
import { Styles } from './DropdownButtonSplit.theme';

const DefaultAnchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' };
const DefaultTransformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

const DropdownButtonSplitComponent = forwardRef<HTMLDivElement, DropdownButtonSplitProps>((props, ref) => {
  const {
    id,
    contentButtonStyle,
    dropButtonStyle,
    menuListStyle,
    startIcon,
    dropIcon,
    theme,
    variant,
    disabled,
    children,
    menuItems,
    // anchorOrigin,
    // transformOrigin,
    callbackValue,
    onPrimaryButtonClick,
  } = props;

  const elementRef = useRef<any>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handlePrimaryButtonClick = useCallback(( cb: any, e: any) => {
    onPrimaryButtonClick?.(callbackValue, e);
  }, [callbackValue, onPrimaryButtonClick]);

  const handleDropButtonClick = useCallback(( cb: any, e: any) => {
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  }, [menuOpen]);
  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const dropdownIcon = menuOpen ? (
      <FontAwesomeIcon icon={faAngleUp as IconProp} color='white' /> 
  ) : (  
      <FontAwesomeIcon icon={faAngleDown as IconProp} color='white' />  
  );

  return (
    <div id={id} ref={ref}>
      <ButtonGroup id={`${id}-ButtonGroup`} ref={elementRef}>
        <Button
          id={`${id}-ButtonGroup-ContentButton`}
          style={contentButtonStyle}
          className={Styles.contentButton}
          startIcon={startIcon}
          theme={theme}
          variant={variant}
          onClick={handlePrimaryButtonClick}
          disabled={disabled}
        >
          {children}
        </Button>
        <Button
          id={`${id}-ButtonGroup-ContentButton`}
          style={dropButtonStyle}
          className={Styles.dropButton}
          theme={theme}
          variant={variant}
          onClick={handleDropButtonClick}
          disabled={disabled}
        >
          {dropIcon ?? dropdownIcon}
        </Button>
      </ButtonGroup>

      <Popper
        id={id}
        className={Styles.popper}
        anchorEl={elementRef.current}
        open={menuOpen}
        onClose={handleMenuClose}
        menuItems={menuItems}
        menuListClassName={Styles.menuList}
        menuListStyle={menuListStyle}
        disablePortal
        transition
      />
    </div>
  );
});

DropdownButtonSplitComponent.defaultProps = {
  contentButtonStyle: undefined,
  dropButtonStyle: undefined,
  menuListStyle: undefined,
  theme: ButtonTheme.primary,
  variant: ButtonVariant.normal,
  anchorOrigin: DefaultAnchorOrigin,
  transformOrigin: DefaultTransformOrigin,
  callbackValue: null,
  onPrimaryButtonClick: () => null,
};

export { DropdownButtonSplitTheme, DropdownButtonSplitVariant } from './DropdownButtonSplit.types';
export default DropdownButtonSplitComponent;
