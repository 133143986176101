import { styled } from '@mui/material';

import DiallerButton from '../DiallerButton';

// const useStyles = {
//   default: {},
export const DialpadContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const ButtonsContainer = styled('div')({
  padding: '15px 24px 25px',
});

export const StyledDiallerButton = styled(DiallerButton)({
    margin: '13px 24px',
});

