import { styled } from '@mui/material';

import Colours from '../../Theme/Colours';


interface IconTitleDivProps {
  iconTitleWrapperClass?: React.CSSProperties;
  iconWrapperClass?: React.CSSProperties;
}

export const Styles = {
  subChildren: {
    position: 'absolute',
  },
};

export const IconTitleWrapperDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'iconTitleWrapperClass',
})<IconTitleDivProps>(({ iconTitleWrapperClass }) => ({
  ...iconTitleWrapperClass,
  display: 'flex',
  alignItems: 'center',
}));

export const IconWrapperDiv =  styled('div', {
  shouldForwardProp: (prop) => prop !== 'iconWrapperClass',
})<IconTitleDivProps>(({ iconWrapperClass }) => ({
  ...iconWrapperClass,
  width: 36,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
  cursor: 'pointer',
  marginRight: 11,
  '& > svg': {
    color: Colours.Typography.light,
    // fontSize: 18,
  },
  backgroundColor: 'rgb(21, 139, 234)',
}));