import { styled } from '@mui/material';

const CallForwardInfo = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const PanelContainer = styled('div')({
  bottom: '0px', 
  height: '100%',
});

const CallPanel = styled('div')({
  height: '100%',
  minWidth: 120,
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#001932 !important',
});

const CallContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxShadow: 'lg',
  justifyContent: "space-between",
});

const ContactDetailsContent = styled('div')({
  flex: 6,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ContactDetails = styled('div')({
  marginTop: 12,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const CallActions = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 30,
});

const ContactName = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Account = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > svg': {
    color: '#FFFFFF',
    marginRight: 8,
  },
});

export default { CallForwardInfo, PanelContainer, CallPanel, CallContent, ContactDetailsContent, ContactDetails,
  CallActions, ContactName, Account
 };
