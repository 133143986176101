import React from 'react';

export interface MenuItemProps {
  id: string;
  children: React.ReactNode;
  className?: React.CSSProperties;
  value?: any;
  style?: React.CSSProperties;
  leftIcon?: React.ReactNode;
  leftIconClassName?: React.CSSProperties;
  leftIconStyle?: React.CSSProperties;
  theme?: Theme;
  callbackValue?: any;
  onClick?: (event: any, callbackValue: any) => any;
  fullWidthText?: boolean;
  selected?: boolean;
  disabled?: boolean;
};

export enum Theme {
  normal = 'normal',
  danger = 'danger',
  dark = 'dark',
};
