import React, { forwardRef, useCallback, memo } from 'react';
// import classnames from 'classnames';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import { styled } from '@mui/material/styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark, faGripDots, faPause, faPhone, faPhoneHangup } from '@fortawesome/pro-solid-svg-icons';

import CallForwardCard from '../CallForwardCard';
import { CallState } from '../../Enums/Call.types';
import Typography, { TypographyVariant } from '../Typography';

import Buttons, { CallButtonTheme, CallIconButtonSize } from './private/Buttons';
import NumberDropdown from './private/NumberDropdown';
import Dialpad from './private/Dialpad';
import ContactView from './private/ContactView';
import Numberpad from './private/NumberPad';
import {
  NavigationDiallerProps,
  CallButtonVisibleMap,
  CallControlButtonsVisibleMap,
  NumberDropDownDisabledMap,
  ContactViewVisibleMap,
  ContactNameViewVisibleMap,
} from './NavigationDialler.types';
import Themer from './NavigationDialler.theme';

// const ThemedCard = styled(Card)(NavigationDiallerThemer.classes);
// const ThemedCardContent = styled(CardContent)(NavigationDiallerThemer.cardContentClasses);

const NavigationDiallerComponent : React.FC<NavigationDiallerProps<any, any>> = memo(
  forwardRef<HTMLDivElement, NavigationDiallerProps<any, any>>(({
    id,
    className,
    menuZIndex,
    style,
    callState,
    isCallRecording,
    contactNumbers,
    onMenuOpen, onMenuClose,
    moreNumbers,
    showMoreNumber,
    moreNumberOpened,
    onDropDownSelect,
    showBackSpace,
    number,
    callButtonDisabled,
    dialpadButtonDisabled,
    recordingButtonDisabled,
    onDialpadNumberPressed,
    onNumberChanged,
    numberCallbackValue,
    onBackspaceClick,
    showDialpad,
    isMicMute,
    isOnHold,
    contactName,
    contactCount,
    callTime,
    onCallClicked,
    onHangUp,
    onCallRecord,
    onAddBookmark,
    onDialpadClicked,
    onCallHold,
    onCallMute,
    onCallForwardButtonClicked,
    onClose,
    getMoreNumberValue, getMoreNumberIcon, isMoreNumberSelected,
    onMoreNumberPanelExpand,
    onMoreNumberItemClick,
    showCallForwardCard,
    hideNumberPad,
    title,
    proceedButtonLabel,
    proceedButtonDisabled,
    onCallForwardCardClose,
    inputValue,
    onInputChange,
    dataLoading,
    onProceed,
    onSelect,
    data,
    dataTotalCounts,
    getId,
    getAvatarUrl,
    getAvatarFallbackLabel,
    getContactName,
    getContactStatus,
    isSelected,
    isActive,
    dialpadOpen,
    externalNumber,
    dialpadProceedButtonDisabled,
    onUseDialpadClicked,
    onCallForwardNumberChange,
    onCallForwardNumberPressed,
    onCallForwardBackSpaceClicked,
    onCallForwardBackButtonClicked,
    onDialpadProceed,
    onCursorPositionChange,
  }, ref) => {

  const dropdownDisabled = NumberDropDownDisabledMap[callState];
  const showContactView = ContactViewVisibleMap[callState] && !showDialpad;
  const showCallButton = CallButtonVisibleMap[callState];
  const showCallControls = CallControlButtonsVisibleMap[callState];
  const showContactName = ContactNameViewVisibleMap[callState];
  const callControlButtonsDisabled = callState === CallState.ended;
  const actionButtonDisable = callState !== CallState.answered;

  const extendedClassnames = contactName && showContactName;
  // // const numberPadClassnames = classnames(classes.numberPad, extendedClassnames && classes.numberPadExtended);
  // // const bodyContainerClassnames = classnames(classes.bodyContainer, extendedClassnames && classes.bodyContainerExtended);
  const BodyContainerClass = (Themer.BodyContainer, extendedClassnames && Themer.BodyContainerExtended);
  const NumberPadClass = (Themer.NumberPadDiv, extendedClassnames && Themer.NumberPadDivExtended);
  const Styles = Themer.Styles;

  const handleBackSpaceClick = useCallback(
    (value: any, e: any) => {
      onBackspaceClick?.(value, e);
    },
    [onBackspaceClick]
  );

  const handleMoreNumberPanelClick = useCallback(() => {
    onMoreNumberPanelExpand?.(moreNumberOpened);
  }, [moreNumberOpened, onMoreNumberPanelExpand]);

  const handleNestedNumberClick = useCallback((number: any) => {
    onMoreNumberItemClick?.(number)
  }, [onMoreNumberItemClick])

  return (
    <Themer.MainContainer
      //  className={classes.mainContainer as any}
    >
      <Themer.DiallerContainer
      //  className={classes.diallerContainer as any}
       >
        <Themer.ThemedCard id={id} ref={ref}
        //  className={className}
          style={style}>
          <Themer.ThemedCardContent>
            {/* <Themer.DraggablePanel id="draggable-dialog-panel"></Themer.DraggablePanel> */}
            <Themer.HeaderContainer
            //  className={classes.headerContainer as any}
             >
              <NumberDropdown
                id={`${id}-Number-Dropdown`}
                menuItems={contactNumbers}
                dropdownDisabled={dropdownDisabled}
                onDropDownSelect={onDropDownSelect}
                menuZIndex={menuZIndex}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
              />
            </Themer.HeaderContainer>
            {!showCallForwardCard && (
              <>
                <Themer.BodyContainer
                // //  className={bodyContainerClassnames}
                 >
                  {!hideNumberPad && (
                    <Themer.NumberAndContactContainer
                    //  className={classes.numberAndContactContainer as any}
                     >
                      <Themer.NumberInputContainer
                      // // className={classes.numberInputContainer as any}
                      >
                        <Numberpad
                          id={`${id}-Numberpad`}
                          style={Styles.numberPadContainer as any}
                          number={number}
                          callbackValue={numberCallbackValue}
                          showBackSpace={showBackSpace}
                          onBackspaceClick={handleBackSpaceClick}
                          onNumberChange={onNumberChanged}
                          onCursorPositionChange={onCursorPositionChange}
                          disableFocus={!showDialpad}
                          onEscape={onClose}
                          showContactName={showContactName && !callButtonDisabled}
                          contactName={contactName}
                          contactCount={contactCount}
                        />
                      </Themer.NumberInputContainer>
                    </Themer.NumberAndContactContainer>
                  )}
                  {showDialpad && (
                    <Dialpad id={`${id}-Dialpad`} numberCallbackValue={numberCallbackValue} onNumberChange={onDialpadNumberPressed} dialpadButtonDisabled={dialpadButtonDisabled} />
                  )}
                  {showContactView && (
                    <ContactView
                      id={`${id}-Contact-View`}
                      callState={callState}
                      isCallRecording={isCallRecording}
                      actionButtonDisable={actionButtonDisable}
                      isMicMute={isMicMute}
                      isOnHold={isOnHold}
                      callTime={callTime}
                      contactCount={contactCount}
                      onCallHold={onCallHold}
                      onCallMute={onCallMute}
                      onCallForwardButtonClicked={onCallForwardButtonClicked}
                    />
                  )}
                </Themer.BodyContainer>
                <Themer.ActionsContainer>
                  {showCallButton && (
                    <Themer.CallButtonContainer>
                      <Buttons.DiallerCallActionButton
                        id={`${id}-Call-Button`}
                        className={Styles.callButton}
                        theme={CallButtonTheme.secondary}
                        fullWidth
                        onClick={onCallClicked}
                        disabled={callButtonDisabled}
                      >
                        <FontAwesomeIcon icon={faPhone as IconProp} style={{fontSize: 30}} />
                      </Buttons.DiallerCallActionButton>
                    </Themer.CallButtonContainer>
                  )}
                  {showCallControls && (
                    <Themer.CallControlsContainer>
                      <Themer.CallButtonsWrapper>
                        <Buttons.CallControlButton
                          id={`${id}-Call-Control-Button`}
                          className={Styles.callControlButton}
                          theme={CallButtonTheme.danger}
                          size={CallIconButtonSize.large}
                          onClick={onHangUp}
                          disabled={callControlButtonsDisabled}
                        >
                          <FontAwesomeIcon icon={faPhoneHangup as IconProp} style={{color: "#ffffff", fontSize: 18}} />
                        </Buttons.CallControlButton>
                        <Buttons.CallControlButton
                          id={`${id}-Call-Record-Button`}
                          className={Styles.callControlButton}
                          theme={CallButtonTheme.danger}
                          size={CallIconButtonSize.large}
                          onClick={onCallRecord}
                          disabled={recordingButtonDisabled || callControlButtonsDisabled || actionButtonDisable || isOnHold}
                        >
                          {!isCallRecording ? (
                            <Typography.H6 variant={TypographyVariant.light} style={{ fontSize: '16px' }}>REC</Typography.H6>
                          ) : (
                            <FontAwesomeIcon icon={faPause as IconProp} style={{color: "#ffffff", fontSize: 18}}/>
                          )}
                        </Buttons.CallControlButton>
                        <Buttons.CallControlButton
                          id={`${id}-Call-Bookmark-Button`}
                          className={Styles.callControlButton}
                          theme={CallButtonTheme.secondaryAlternative}
                          size={CallIconButtonSize.large}
                          onClick={onAddBookmark}
                          // disabled={callControlButtonsDisabled || actionButtonDisable || isOnHold}
                          disabled
                        >
                          <FontAwesomeIcon icon={faBookmark as IconProp} style={{color: "#ffffff", fontSize: 14}} />
                        </Buttons.CallControlButton>
                        <Buttons.CallControlButton
                          id={`${id}-Dialpad-Button`}
                          className={Styles.callControlButton}
                          theme={CallButtonTheme.default}
                          size={CallIconButtonSize.large}
                          onClick={onDialpadClicked}
                          disabled={callControlButtonsDisabled}
                        >
                          <FontAwesomeIcon icon={faGripDots as IconProp} style={{color: "#ffffff", fontSize: 24}}/>
                        </Buttons.CallControlButton>
                      </Themer.CallButtonsWrapper>
                    </Themer.CallControlsContainer>
                  )}
                </Themer.ActionsContainer>
              </>
            )}
            {showCallForwardCard && (
              <Themer.CallForwardCardContainer
                id={`${id}-CallForward-Card`}
                className={Styles.cardContainer as any}
                hideHeader={true}
                title={title}
                proceedButtonLabel={proceedButtonLabel}
                proceedButtonDisabled={proceedButtonDisabled}
                inputValue={inputValue}
                onInputChange={onInputChange}
                dataLoading={dataLoading}
                miniSkeleton={true}
                onClose={onCallForwardCardClose}
                onSelect={onSelect}
                onProceed={onProceed}
                data={data}
                dataTotalCounts={dataTotalCounts}
                getId={getId}
                getAvatarUrl={getAvatarUrl}
                getAvatarFallbackLabel={getAvatarFallbackLabel}
                getContactName={getContactName}
                getContactStatus={getContactStatus}
                isSelected={isSelected}
                isActive={isActive}
                dialpadOpen={dialpadOpen}
                externalNumber={externalNumber}
                dialpadProceedButtonDisabled={dialpadProceedButtonDisabled}
                callForwardDialpadClassName={Styles.callForwardDialpad as any}
                contentClassName={Styles.content as any}
                inputWrapperClassName={Styles.inputWrapper as any}
                dialpadButtonContainerClassName={Styles.dialpadButtonContainer as any}
                contactRowListClassName={Styles.contactRowList as any}
                showBackSpace={!!externalNumber}
                onDialpadClicked={onUseDialpadClicked}
                onNumberChange={onCallForwardNumberChange}
                onDialpadNumberPressed={onCallForwardNumberPressed}
                onBackSpaceClicked={onCallForwardBackSpaceClicked}
                onBackButtonClicked={onCallForwardBackButtonClicked}
                onDialpadProceed={onDialpadProceed}
              />
            )}
          </Themer.ThemedCardContent>
        </Themer.ThemedCard>
      </Themer.DiallerContainer>
    </Themer.MainContainer>
  );
}));

NavigationDiallerComponent.defaultProps = {
  className: undefined,
  style: undefined,
  contactNumbers: undefined,
  moreNumbers: undefined,
  showMoreNumber: false,
  moreNumberOpened: false,
  onDropDownSelect: () => {},
  showBackSpace: false,
  callButtonDisabled: false,
  dialpadButtonDisabled: false,
  recordingButtonDisabled: false,
  number: '',
  showDialpad: false,
  isMicMute: false,
  isOnHold: false,
  callState: CallState.dialing,
  isCallRecording: false,
  callTime: 'Not Connected',
  contactName: '',
  contactCount: 0,
  onCallClicked: () => {},
  onHangUp: () => {},
  onCallRecord: () => {},
  onAddBookmark: () => {},
  onDialpadClicked: () => {},
  onCallHold: () => {},
  onCallMute: () => {},
  onClose: () => {},
  menuZIndex: 1300 as any,
  hideNumberPad: false,
  proceedButtonDisabled: false,
  inputValue: '',
  onInputChange: () => {},
  onCallForwardCardClose: () => {},
  onProceed: () => {},
  dataLoading: false,
  onSelect: () => {},
  data: [],
  dataTotalCounts: undefined,
  dialpadProceedButtonDisabled: false,
  onCallForwardNumberChange: () => {},
};

export { CallState as NavigationDiallerCallState } from '../../Enums/Call.types';
export default NavigationDiallerComponent;
